/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { t, Trans } from '@lingui/macro'
import { Space } from 'antd'

import { LoadSection } from '../../../core/components/LoadScreen'
import { ExportButton } from '../../../shared/components/export-button/ExportButton'
import { useAxios } from '../../../shared/hooks/use-axios'
import { dateRenderer, percentageRenderer } from '../../helpers/renderers'
import { Table } from '../Table'
import { UserPathsModuleStepsReport } from '../UserPathModuleStepsReport'

export const UserPathsModuleReport = ({
  path_id,
  user_id,
  branch_id,
  section_id,
}: {
  path_id: string
  user_id: string
  branch_id: string | undefined
  section_id: string | null | undefined
}) => {
  const [{ data }] = useAxios({
    url: `/api/activity/paths/${path_id}/user/${user_id}`,
    params: {
      branch_id: branch_id ?? undefined,
      section_id: section_id ?? undefined,
    },
  })

  if (!data) return <LoadSection />
  return (
    <>
      <Table
        expandable={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          expandedRowRender: (record: any) => (
            <UserPathsModuleStepsReport
              path_id={path_id}
              user_id={user_id}
              branch_id={branch_id}
              section_id={section_id}
              module_id={record.module_id}
            />
          ),
          expandRowByClick: true,
          rowExpandable: () => true,
        }}
        columns={[
          {
            title: t({
              id: 'reports.learning_path.module_activity_report.table.name',
              message: 'Module',
            }),
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: t({
              id: 'reports.learning_path.module_activity_report.table.progress',
              message: 'Vooruitgang',
            }),
            dataIndex: 'completion_percentage',
            key: 'completion_percentage',
            render: percentageRenderer,
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.learning_path.module_activity_report.table.last_activity',
              message: 'Laatst actief op',
            }),
            dataIndex: 'last_activity',
            key: 'last_activity',
            render: dateRenderer,
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.learning_path.module_activity_report.table.completed',
              message: 'Voltooid op',
            }),
            dataIndex: 'completed',
            key: 'completed',
            render: dateRenderer,
            align: 'center' as const,
          },
        ]}
        bordered
        size="small"
        dataSource={data}
        rowKey="module_id"
        pagination={false}
        footer={() => {
          return (
            <div style={{ textAlign: 'right' }}>
              <Space>
                <span>
                  <Trans id="reports.learning_paths_report.action.export">
                    Exporteer naar:
                  </Trans>{' '}
                </span>
                {['xlsx', 'csv', 'json'].map((type, index) => (
                  <ExportButton
                    key={index}
                    url={`/api/export/learning-path/${user_id}`}
                    params={{
                      path_id,
                      section_id,
                    }}
                    type={type}
                    name="learning-path"
                    dates={[
                      'completed_at',
                      'last_activity',
                      'started_at',
                      'path_last_activity',
                      'step_started_at',
                      'step_completed_at',
                    ]}
                    total={data?.summary}
                  />
                ))}
              </Space>
            </div>
          )
        }}
      />
    </>
  )
}
