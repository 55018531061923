import { useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { Select, SelectProps } from 'antd'

import {
  BranchUsersKeyValuesQuery,
  UserProfile,
  UsersKeysValuesQuery,
} from 'apps/lms-front/src/generated/graphql'

import BRANCH_USERS_KEYS_VALUES from './../../../branch/queries/branch-users-key-values.graphql'
import USERS_KEYS_VALUES from './../../../settings/queries/users-keys-values.graphql'

type UserSelectProps = {
  loading?: boolean
  renderOption?: (user: UserProfile) => React.ReactNode
  type?: 'branch' | 'global'
} & Omit<SelectProps, 'options' | 'optionFilterProp'>

export const UserSelect = ({
  loading,
  type = 'global',
  renderOption = (user) => `${user.firstName} ${user.lastName} (${user.email})`,
  ...props
}: UserSelectProps) => {
  /**
   * Fetch users
   */
  const { data: users, loading: usersLoading } = useQuery<UsersKeysValuesQuery>(
    USERS_KEYS_VALUES,
    {
      fetchPolicy: 'network-only',
      skip: type !== 'global',
    }
  )
  /**
   * Fetch branch users
   */
  const { data: branchUsers, loading: branchUsersLoading } =
    useQuery<BranchUsersKeyValuesQuery>(BRANCH_USERS_KEYS_VALUES, {
      fetchPolicy: 'network-only',
      skip: type !== 'branch',
    })

  const dataLoading = usersLoading || branchUsersLoading || loading

  return (
    <>
      <Select
        optionFilterProp="label"
        disabled={dataLoading}
        loading={dataLoading}
        options={(
          users?.fetchUsers || branchUsers?.fetchBranchUsers
        )?.results.map((user) => ({
          label: renderOption(user as UserProfile),
          value: user._id,
        }))}
        {...props}
        {...(dataLoading
          ? {
              placeholder: t({
                id: 'user-select.form.placeholder.loading',
                message: 'Gebruikers aan het laden...',
              }),
            }
          : {})}
      />
    </>
  )
}
