import { useMutation } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { Button, Result, Space, Spin } from 'antd'
import { ObjectId } from 'bson'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  AttendLiveEventMutation,
  SetEventActivityCertificationTypeMutation,
} from 'apps/lms-front/src/generated/graphql'

import { useAuth } from '../../../auth/hooks/use-auth'
import ATTEND_LIVE_EVENT from '../../mutations/attend-live-event.graphql'
import SET_EVENT_CERTIFICATION_TYPE_MUTATION from '../../mutations/set-event-certification-type.graphql'

export const AttendEvent = () => {
  const { user } = useAuth()
  const { token } = useParams<{ token: string }>()
  const [selectedCertification, setSelectedCertification] = useState<
    string | null
  >(null)
  const [certificationOptions, setCertificationOptions] = useState<
    { id: string; name: string }[]
  >([])

  const [attendLiveEvent, { loading, error, data }] =
    useMutation<AttendLiveEventMutation>(ATTEND_LIVE_EVENT, {
      onCompleted: (response) => {
        if (response.attendLiveEvent) {
          const certificationTypes =
            response.attendLiveEvent.event.certificationType?.filter(
              (c) =>
                user?.certificationType?.find((ct) =>
                  new ObjectId(ct._id).equals(c._id)
                )
            ) || []

          setCertificationOptions(
            certificationTypes.map((cert) => ({
              id: cert._id,
              name: cert.name,
            }))
          )

          if (certificationTypes.length > 0) {
            const firstCertification = certificationTypes[0]._id
            setSelectedCertification(firstCertification)

            // Automatically register with the first available certification
            setEventCertificationType({
              variables: {
                eventId: response.attendLiveEvent.event._id,
                certificationId: firstCertification,
              },
            })
          }
        }
      },
    })

  const [setEventCertificationType] =
    useMutation<SetEventActivityCertificationTypeMutation>(
      SET_EVENT_CERTIFICATION_TYPE_MUTATION
    )

  useEffect(() => {
    if (token && token.length > 0) {
      attendLiveEvent({ variables: { token } })
    }
  }, [token, attendLiveEvent])

  const handleCertificationChange = (certificationId: string) => {
    setSelectedCertification(certificationId)
    setEventCertificationType({
      variables: {
        eventId: data?.attendLiveEvent.event._id,
        certificationId,
      },
    })
  }

  if (loading)
    return (
      <Space align="center" direction="vertical" style={{ width: '100%' }}>
        <Spin size="large" />
        <h2>
          <Trans id="events.attendance.registering">
            Aanmelding bevestigen...
          </Trans>
        </h2>
        <p>
          <Trans id="events.attendance.subtitle">Even geduld...</Trans>
        </p>
      </Space>
    )

  if (error)
    return (
      <Result
        status={'error'}
        title={<Trans id="events.attendance.failed">Aanmelden mislukt</Trans>}
        subTitle={error.message}
      />
    )

  if (data?.attendLiveEvent) {
    return (
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Result
          status={'success'}
          title={<Trans id="events.attendance.success">Aanmelden gelukt</Trans>}
          subTitle={
            <Trans id="events.attendance.success.subtitle">
              Je aanmelding is succesvol geregistreerd
            </Trans>
          }
        />
        {certificationOptions.length > 1 && (
          <>
            <p style={{ maxWidth: '40rem', textAlign: 'center' }}>
              <Trans id="events.attendance.success.multiple.certifications">
                Er zijn meerdere certificeringstypes beschikbaar. We hebben je
                automatisch geregistreerd voor het eerste beschikbare type. Wil
                je gebruik maken van een ander type? Pas het dan hieronder aan.
              </Trans>
            </p>
            <Space>
              {certificationOptions.map((cert) => (
                <Button
                  key={cert.id}
                  type={
                    cert.id === selectedCertification ? 'primary' : 'default'
                  }
                  onClick={() => handleCertificationChange(cert.id)}
                >
                  {cert.name}
                </Button>
              ))}
            </Space>
          </>
        )}
      </Space>
    )
  }

  return null
}
