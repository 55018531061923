import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Modal,
  Form,
  Input,
  Switch,
  Popconfirm,
  Button,
  Tabs,
  Row,
  Col,
  Radio,
  Card,
  Tooltip,
  notification,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect } from 'react'

import {
  EventLocationType,
  EventMode,
} from '@lms-shared-patterns/enums/event.enums'

import {
  EventStep,
  CreatePathEventStepInput,
  StepCategory,
  StepReferenceType,
  CreateLiveEventMutation,
  UpdateLiveEventMutation,
} from '../../../generated/graphql'
import CREATE_LIVE_EVENT_MUTATION from '../../events/mutations/create-live-event.graphql'
import UPDATE_LIVE_EVENT_MUTATION from '../../events/mutations/update-live-event.graphql'
import { EditSurvey } from '../../events/pages/edit-live-event/EditSurvey'
import { RichEditor } from '../../shared/components/rich-editor/RichEditor'
import {
  errorNotifierFn,
  validationErrorNotifierFn,
} from '../../shared/helpers/error-notifier'
import { LinkedEventPicker } from '../components/LinkedEventPicker'
import { StepEditEvent } from '../components/StepEditEvent'
import DELETE_PATH_STEP from '../mutations/delete-path-step.graphql'
import UPDATE_PATH_EVENT_STEP from '../mutations/update-path-event-step.graphql'

export const EditEventStepModal = ({
  open,
  onClose,
  onSubmit,
  step,
  path_id,
}: {
  open: boolean
  onClose: () => void
  onSubmit?: (values: CreatePathEventStepInput) => void
  step?: EventStep
  moduleId?: string
  path_id: string
}) => {
  const [form] = Form.useForm()
  const [liveEventForm] = useForm()
  const reference_type = Form.useWatch('reference_type', form)
  const custom_live_event = Form.useWatch('custom_live_event', form)
  const eventLocationType = Form.useWatch('location_type', liveEventForm)
  const isSurveyAvailable = Form.useWatch('is_survey_available', liveEventForm)

  const [createLiveEvent] = useMutation<CreateLiveEventMutation>(
    CREATE_LIVE_EVENT_MUTATION,
    {
      onCompleted: (done) => {
        form.setFieldValue(['custom_live_event'], done.createLiveEvent._id)
      },
    }
  )

  const [updateLiveEvent] = useMutation<UpdateLiveEventMutation>(
    UPDATE_LIVE_EVENT_MUTATION,
    {
      variables: {
        id: custom_live_event,
      },
    }
  )

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        optional: false,
        category: StepCategory.EventStep,
        ...step,
      })
    } else {
      form.resetFields()
      liveEventForm.resetFields()
    }
  }, [open, form, step, liveEventForm])

  const [updateEventStep] = useMutation(UPDATE_PATH_EVENT_STEP, {
    refetchQueries: ['path'],
  })
  const [deleteStep] = useMutation(DELETE_PATH_STEP, {
    refetchQueries: ['path'],
  })

  const handleSubmit = async () => {
    let event_location_type
    let name

    if (reference_type === StepReferenceType.CustomEvent) {
      const event = await liveEventForm
        ?.validateFields()
        .catch(validationErrorNotifierFn)

      if (!event) {
        return
      }

      event_location_type = event.location_type
      name = event.title

      const input = {
        start: event.start,
        end: event.end,
        location_type: event.location_type,
        location: event.location,
        room: event.room,
        catering: event.catering,
        title: event.title,
        description: event.description,
        lecturer: event.lecturer,
        certificationType: event.certificationType,
        mode: EventMode.Closed,
        language: event.language,
        stream_details: {
          host_ids: event.stream_details?.host_ids || [],
          attention_check: event.stream_details?.attention_check ?? false,
        },
        is_survey_available: event.is_survey_available,
        survey: event.survey,
        post_event_quiz_available: event.post_event_quiz_available,
        post_event_quiz: event.post_event_quiz,
        attested_duration: event.attested_duration || undefined,
      }

      await (custom_live_event
        ? updateLiveEvent({
            variables: {
              input,
            },
          }).catch(errorNotifierFn)
        : createLiveEvent({
            variables: {
              input: { ...input, path_id },
            },
          }).catch(errorNotifierFn))
    }

    const values = await form.validateFields().catch(validationErrorNotifierFn)

    if (!values) {
      return
    }

    if (name) {
      values.name = name
    }

    if (values.linked_event_name) {
      values.name = values.linked_event_name
      delete values.linked_event_name
    }

    const input = {
      ...values,
      category: StepCategory.EventStep,
      ...(event_location_type && {
        event_location_type: event_location_type,
      }),
    }

    if (step?._id) {
      await updateEventStep({
        variables: { step_id: step._id, input },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'step.event.edit.success',
              message: 'Groepssessie succesvol bijgewerkt',
            }),
          })
          onClose()
        })
        .catch(errorNotifierFn)
    } else if (onSubmit) {
      onSubmit(input)
    }
  }

  const handleDelete = async () => {
    if (step?._id) {
      await deleteStep({
        variables: { step_id: step._id },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'step.event.delete.success',
              message: 'Groepssessie succesvol verwijderd',
            }),
          })
          onClose()
        })
        .catch(errorNotifierFn)
    }
  }

  const shouldShowSurvey =
    reference_type === StepReferenceType.CustomEvent &&
    eventLocationType === EventLocationType.Online &&
    isSurveyAvailable

  return (
    <Modal
      title={
        step ? (
          <Trans id="step.event.edit.title">Stap bewerken</Trans>
        ) : (
          <Trans id="step.event.add.title">Groepssessie toevoegen</Trans>
        )
      }
      bodyStyle={{ paddingTop: 16 }}
      maskClosable={false}
      destroyOnClose={true}
      width={800}
      open={open}
      onCancel={() => {
        onClose()
      }}
      onOk={handleSubmit}
      footer={[
        step && (
          <Popconfirm
            key="delete"
            title={
              <Trans id="step.event.delete.confirm">
                Weet je zeker dat je deze stap wilt verwijderen?
              </Trans>
            }
            okText={<Trans id="action.yes">Ja</Trans>}
            cancelText={<Trans id="action.no">Nee</Trans>}
            onConfirm={handleDelete}
          >
            <Button danger>
              <Trans id="action.delete">Verwijderen</Trans>
            </Button>
          </Popconfirm>
        ),
        !step && (
          <Button key="cancel" onClick={onClose}>
            <Trans id="action.cancel">Annuleren</Trans>
          </Button>
        ),
        <Button key="submit" type="primary" onClick={handleSubmit}>
          <Trans id="action.save">Opslaan</Trans>
        </Button>,
      ].filter(Boolean)}
    >
      <Tabs
        type="card"
        destroyInactiveTabPane={false}
        items={[
          {
            key: 'details',
            label: <Trans id="step.event.details.title">Details</Trans>,
            forceRender: true,
            children: (
              <Form form={form} layout="vertical">
                <Row gutter={16}>
                  <Col span={20}>
                    <Form.Item
                      name="name"
                      label={<Trans id="step.event.field.name">Naam</Trans>}
                      extra={
                        <Trans id="step.event.field.name.help">
                          Dit veld wordt automatisch ingevuld op basis van de
                          gekoppelde groepssessie.
                        </Trans>
                      }
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="optional"
                      valuePropName="checked"
                      label={
                        <Trans id="step.event.field.optional">Optioneel</Trans>
                      }
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  hidden
                  name="description"
                  label={
                    <Trans id="step.event.field.description">
                      Beschrijving
                    </Trans>
                  }
                >
                  <RichEditor disableTextStyles />
                </Form.Item>
              </Form>
            ),
          },
          {
            key: 'content',
            label: <Trans id="step.event.content.title">Inhoud</Trans>,
            forceRender: true,
            children: (
              <>
                <Form form={form} layout="vertical">
                  <Form.Item
                    hidden={reference_type === StepReferenceType.CustomEvent}
                    name="reference_type"
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'step.event.field.validation.reference_type',
                          message: 'Selecteer een type',
                        }),
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        form.setFieldValue('reference_type', e.target.value)
                        form.setFieldValue('linked_event_id', undefined)
                      }}
                    >
                      <Radio value={StepReferenceType.LinkedEvent}>
                        <Trans id="step.event.reference_type.linked">
                          Bestaande groepssessie koppelen
                        </Trans>
                      </Radio>
                      <Radio value={StepReferenceType.CustomEvent}>
                        <Trans id="step.event.reference_type.custom">
                          Nieuwe groepssessie
                        </Trans>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  {reference_type === StepReferenceType.LinkedEvent && (
                    <LinkedEventPicker form={form} step={step as EventStep} />
                  )}

                  <Form.Item hidden name="custom_live_event">
                    <Input hidden />
                  </Form.Item>
                </Form>
                {reference_type === StepReferenceType.CustomEvent && (
                  <>
                    <Card
                      title={t({
                        id: 'step.event.unit_kind.event',
                        message: 'Groepssessie',
                      })}
                      size="small"
                      extra={
                        <Tooltip
                          placement="left"
                          title={t({
                            id: 'action.delete',
                            message: 'Verwijderen',
                          })}
                        >
                          <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            shape="circle"
                            onClick={() => {
                              form.setFieldsValue({
                                reference_type: undefined,
                                custom_live_event: undefined,
                              })
                              liveEventForm.resetFields()
                            }}
                          />
                        </Tooltip>
                      }
                    >
                      <StepEditEvent
                        form={form}
                        liveEventForm={liveEventForm}
                        step={step as EventStep}
                      />
                    </Card>
                    {shouldShowSurvey && (
                      <Card
                        style={{ marginTop: 16 }}
                        title="Enquête"
                        size="small"
                      >
                        <EditSurvey noOffset embedded form={liveEventForm} />
                      </Card>
                    )}
                  </>
                )}
              </>
            ),
          },
        ]}
      />
    </Modal>
  )
}
