/* eslint-disable eslint-comments/disable-enable-pair, unicorn/filename-case */
import { UploadOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Button, Form, Input, Space, Upload, notification } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { RcFile } from 'antd/lib/upload'
import { useState } from 'react'

export const StepEditPDFUnit = ({ form }: { form: FormInstance }) => {
  const [uploading, setUploading] = useState(false)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(
    form.getFieldValue(['custom_pdf_unit', 'originalName']) || null
  )

  const handleUpload = async (file: RcFile, form: FormInstance) => {
    setUploading(true)
    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await fetch(
        `${import.meta.env.NX_BACKEND_URL}/api/files/uploadPDFDocument`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
            'x-academy-host': window.location.hostname,
          },
        }
      )

      if (!response.ok) {
        throw new Error('Upload failed')
      }

      const result = await response.json()
      setUploadedFileName(file.name)
      form.setFieldValue(['custom_pdf_unit', 'file_id'], result._id)
      notification.success({
        message: t({
          id: 'course.unit.form.upload.pdf.success',
          message: 'PDF succesvol geüpload',
        }),
      })
    } catch (error) {
      console.error(error)
      notification.error({
        message: t({
          id: 'course.unit.form.upload.pdf.failed',
          message: 'Er ging iets fout. Probeer later opnieuw.',
        }),
      })
    } finally {
      setUploading(false)
    }
  }

  return (
    <>
      <Form.Item
        hidden
        name={['custom_pdf_unit', 'file_id']}
        rules={[
          {
            required: true,
            message: t({
              id: 'course.unit.form.validation.pdf',
              message: 'Gelieve een geldig PDF-bestand te selecteren.',
            }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Upload
          accept="application/pdf"
          maxCount={1}
          beforeUpload={(file) => {
            handleUpload(file, form)
            return false // Prevent default upload behavior
          }}
          showUploadList={false}
        >
          <Button loading={uploading}>
            <Space>
              <UploadOutlined />
              {uploadedFileName ?? (
                <Trans id="course.unit.form.upload.pdf">
                  Bestand selecteren
                </Trans>
              )}
            </Space>
          </Button>
        </Upload>
      </Form.Item>
      {uploadedFileName && (
        <div>
          {t({
            id: 'course.unit.form.upload.pdf.selected',
            message: 'Geselecteerd bestand:',
          })}{' '}
          {uploadedFileName}
        </div>
      )}
    </>
  )
}
