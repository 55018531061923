import { t, Trans } from '@lingui/macro'
import {
  LiveKitRoom,
  RoomAudioRenderer,
  StartAudio,
  usePersistentUserChoices,
} from '@livekit/components-react'
import { Button, Col, Layout, Row, Spin } from 'antd'
import { RoomOptions, VideoPresets } from 'livekit-client'
import { posthog } from 'posthog-js'
import { useCallback, useState } from 'react'
import styled from 'styled-components'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { useStream } from '../../contexts/StreamContext'

import { VideoConference } from './components/video/VideoConference'
import { StreamError } from './StreamError'

export function JoinStreamContent() {
  const { isMeetingHost, token, tokenError, getNewToken } = useStream()

  if (tokenError && tokenError === 403) {
    return (
      <StreamError
        title={t({
          id: 'stream.join.failed',
          message: 'Aanmelden mislukt',
        })}
        subTitle={t({
          id: 'stream.join.not_allowed',
          message:
            'Je hebt niet de juiste rechten om deel te nemen aan deze meeting.',
        })}
      />
    )
  } else if (tokenError) {
    return (
      <StreamError
        title={t({
          id: 'stream.join.error.title',
          message: 'Connectiefout',
        })}
        subTitle={t({
          id: 'stream.join.error.subtitle',
          message:
            'Er is een fout opgetreden bij het inloggen op deze meeting. Probeer later opnieuw.',
        })}
      >
        <Button onClick={getNewToken}>
          <Trans id="stream.join.error.try_again">Probeer opnieuw</Trans>
        </Button>
      </StreamError>
    )
  }

  if (!isMeetingHost && token === null) return <WaitingMeetingContent />
  if (!token) return <LoadScreen />
  return <ActiveMeetingContent />
}

function ActiveMeetingContent() {
  const { token } = useStream()

  const { userChoices } = usePersistentUserChoices()
  const [connected, setConnected] = useState(false)

  const roomOptions: RoomOptions = {
    adaptiveStream: true,
    disconnectOnPageLeave: true,
    dynacast: true,
    publishDefaults: {
      simulcast: true,
    },
    videoCaptureDefaults: {
      deviceId: userChoices.videoDeviceId,
      resolution: VideoPresets.h720.resolution,
    },
    audioCaptureDefaults: {
      deviceId: userChoices.audioDeviceId,
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: true,
    },
  }

  const handleError = useCallback((error: Error) => {
    posthog.captureException(error)
  }, [])

  const handleConnected = useCallback(() => {
    setConnected(true)
  }, [])

  const handleDisconnected = useCallback(() => {
    setConnected(false)
  }, [])

  return (
    <LiveKitRoom
      token={token!}
      serverUrl={import.meta.env.NX_LIVEKIT_URL}
      connect={true}
      options={roomOptions}
      audio={false}
      video={false}
      onConnected={handleConnected}
      onDisconnected={handleDisconnected}
      onError={handleError}
    >
      {connected && (
        <>
          <Layout>
            <Row
              justify="center"
              style={{ marginBottom: '20px', padding: '20px' }}
            >
              <Col span={24}>
                <div className="livekit-container">
                  <VideoConference />
                </div>
              </Col>
            </Row>
          </Layout>
          <RoomAudioRenderer />
          <StartAudioWrapper>
            <StartAudio
              label={t({
                id: 'stream.audio.start',
                message: 'Klik om het afspelen van audio toe te staan',
              })}
            />
          </StartAudioWrapper>
        </>
      )}
      {!connected && <LoadScreen />}
    </LiveKitRoom>
  )
}

function WaitingMeetingContent() {
  return (
    <Row justify="center" style={{ marginTop: '50px' }}>
      <Col sm={12} lg={8}>
        <div
          style={{
            textAlign: 'center',
            background: '#fff',
            padding: '24px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Spin spinning />
          <p style={{ margin: 0, marginTop: '1.5rem' }}>
            <Trans id="stream.join.not_started">
              Deze meeting is nog niet gestart.
            </Trans>
          </p>
          <p style={{ margin: 0, marginTop: '1rem' }}>
            <Trans id="stream.join.auto_login">
              Je zal automatisch ingelogd worden wanneer de meeting begint.
            </Trans>
          </p>
        </div>
      </Col>
    </Row>
  )
}

const StartAudioWrapper = styled.div`
  position: fixed;
  top: 140px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  .lk-start-audio-button {
    background-color: var(--ant-error-color);
    font-weight: bold;
    border-radius: 5px;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: var(--ant-error-color-hover);
      border-color: var(--ant-error-color-hover);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    &:active {
      background-color: var(--ant-error-color-active);
      border-color: var(--ant-error-color-active);
    }
  }
`
