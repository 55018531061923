import { MutedOutlined, SoundOutlined, UserOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import {
  LayoutContextProvider,
  ParticipantTile,
  TrackReference,
  TrackReferenceOrPlaceholder,
  VideoTrack,
  useLocalParticipant,
  useLocalParticipantPermissions,
  useParticipants,
  useTracks,
} from '@livekit/components-react'
import '@livekit/components-styles'
import { useLocalStorage } from '@uidotdev/usehooks'
import { Avatar, Button, Empty, Space, Tooltip } from 'antd'
import { ObjectId } from 'bson'
import {
  LocalTrack,
  Participant,
  RemoteTrackPublication,
  Track,
} from 'livekit-client'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useMediaQuery } from 'apps/lms-front/src/modules/shared/hooks/use-media-query'

import { useStream } from '../../../../contexts/StreamContext'
import { useRoomMetadata } from '../../../../hooks/use-room-metadata'
import { useBackgroundProcessor } from '../../backgrounds/BackgroundProcessor'
import { AttentionCheckModal } from '../attention-modal/AttentionModal'
import { Chat } from '../chat/Chat'
import { ControlPanel } from '../control-panel/ControlPanel'
import { ParticipantList } from '../participants-list/ParticipantList'
import { SurveyModal } from '../survey-modal/SurveyModal'

import {
  ControlPanelWrapper,
  ControlsOverlay,
  DotBorder,
  LayoutContainer,
  MainScreen,
  PlaceholderContainer,
  RecordingContent,
  RecordingDot,
  RecordingIndicator,
  SideBar,
  SideBarTile,
  SideBarTiles,
} from './VideoConference.style'

export const VideoConference = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const { localParticipant, cameraTrack } = useLocalParticipant()
  const permissions = useLocalParticipantPermissions()
  const participants = useParticipants()
  const metadata = useRoomMetadata()
  const mainParticipantId = metadata?.mainParticipantId
  const { backgroundProcessor } = useBackgroundProcessor()
  const {
    persistentUserChoices: { userChoices },
    call_id,
    emit,
    connected,
    isSurveyModalVisible,
    isMeetingHost,
    isAttentionCheckVisible,
    handleAttentionConfirm,
  } = useStream()

  /**
   * Sets the processor for the camera track based on the virtualBackground state.
   */
  useEffect(() => {
    const track = cameraTrack?.track as LocalTrack<Track.Kind.Video>
    const currentProcessor = track?.getProcessor()
    if (!backgroundProcessor?.name) {
      track?.stopProcessor()
      return
    }
    if (currentProcessor?.name !== backgroundProcessor?.name) {
      track?.setProcessor(backgroundProcessor)
    }
  }, [cameraTrack, backgroundProcessor])

  /**
   * Sets the camera and microphone enabled state for the local participant based on the isMeetingHost state.
   */
  useEffect(() => {
    async function setupLocalParticipant() {
      if (!localParticipant) return
      if (isMeetingHost) {
        await localParticipant.setMicrophoneEnabled(userChoices.audioEnabled)
        await localParticipant.setCameraEnabled(userChoices.videoEnabled, {
          processor: backgroundProcessor ?? undefined,
        })
      } else if (!permissions?.canPublish) {
        await localParticipant.setCameraEnabled(false)
        await localParticipant.setMicrophoneEnabled(false)
      }
    }
    setupLocalParticipant()
  }, [
    localParticipant,
    isMeetingHost,
    backgroundProcessor,
    userChoices.audioEnabled,
    userChoices.videoEnabled,
    permissions,
  ])

  /**
   * State
   */
  const [isChatVisible, setIsChatVisible] = useState(false)
  const [unreadChatCount, setUnreadChatCount] = useState(0)

  const [virtualBackground, setVirtualBackground] = useLocalStorage(
    'aa_virtual_bg',
    'none'
  )

  const [isScreenSharing, setIsScreenSharing] = useState(false)
  const tracks = useTracks([
    { source: Track.Source.Camera, withPlaceholder: true },
    { source: Track.Source.Microphone, withPlaceholder: true },
    { source: Track.Source.ScreenShare, withPlaceholder: false },
  ])

  const handleSetMainParticipant = useCallback(
    (participant: Participant) => {
      if (
        participant.identity !== mainParticipantId &&
        isMeetingHost &&
        connected
      ) {
        emit('setMainParticipant', {
          call_id,
          participantId: participant.identity,
        })
      }
    },
    [mainParticipantId, isMeetingHost, connected, call_id, emit]
  )

  const relevantTrackInformation = useMemo(() => {
    return tracks.map((t) => ({
      id: t.participant.identity,
      source: t.source,
      enabled:
        t.participant?.isCameraEnabled && t.participant.isMicrophoneEnabled,
      desired:
        t.publication instanceof RemoteTrackPublication
          ? t.publication.isDesired
          : isMeetingHost,
    }))
  }, [tracks, isMeetingHost])

  const sidebarTracks = useMemo(() => {
    const map = new Map<Participant, TrackReference>()
    tracks.forEach((track) => {
      const isMain =
        mainParticipantId &&
        track.participant.identity &&
        new ObjectId(track.participant.identity).equals(mainParticipantId)

      if (
        (!isMain &&
          track.source === Track.Source.Camera &&
          !map.has(track.participant)) ||
        (isScreenSharing && track.source === Track.Source.Camera)
      ) {
        map.set(track.participant, track as TrackReference)
      }
    })
    return [...map.values()].sort((a, b) => {
      /**
       * Sorting rules:
       * - Main participant is always on top
       * - Next, the enabled participants
       * - Next, the desired participants
       * - Finally, the other participants
       */
      if (!a.publication && !b.publication) return 0
      if (!a.publication) return 1
      if (!b.publication) return -1

      const a_enabled =
        a.participant.isCameraEnabled && a.participant.isMicrophoneEnabled
      const b_enabled =
        b.participant.isCameraEnabled && b.participant.isMicrophoneEnabled

      const a_desired =
        a.publication instanceof RemoteTrackPublication
          ? a.publication.isDesired
          : isMeetingHost
      const b_desired =
        a.publication instanceof RemoteTrackPublication
          ? a.publication.isDesired
          : isMeetingHost

      if (a_desired && !b_desired) return -1
      if (!a_desired && b_desired) return 1
      if (a_enabled && !b_enabled) return -1
      if (!a_enabled && b_enabled) return 1
      return 0
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevantTrackInformation, tracks, mainParticipantId, isMeetingHost])

  const numberOfVideoSharingParticipants = useMemo(() => {
    return tracks.filter(
      (t) => t.participant?.isCameraEnabled && t.participant.isMicrophoneEnabled
    ).length
  }, [tracks])

  useEffect(() => {
    setIsScreenSharing(
      !!tracks.some((track) => track.source === Track.Source.ScreenShare)
    )
  }, [tracks])

  const renderMainContent = () => {
    const screenShareTrack = tracks.find(
      (track) => track.source === Track.Source.ScreenShare
    )
    if (screenShareTrack) {
      return (
        <ParticipantTile
          key={`screenshare-${screenShareTrack.participant.name}`}
          disableSpeakingIndicator
          style={{ width: '100%', height: '100%' }}
          trackRef={screenShareTrack}
        />
      )
    }
    if (mainParticipantId && !isScreenSharing) {
      const videoTrack = tracks.find(
        (track) =>
          track.source === Track.Source.Camera &&
          track.participant.identity &&
          new ObjectId(track.participant.identity).equals(mainParticipantId)
      )
      if (!videoTrack) return <PlaceholderContainer />
      return (
        <ParticipantTile
          key={`main-${videoTrack.participant.identity}`}
          style={{ width: 'fit-content', height: '100%' }}
          disableSpeakingIndicator={false}
          trackRef={videoTrack}
        />
      )
    }
    return null
  }

  const renderVideoOrPlaceholder = (trackRef: TrackReferenceOrPlaceholder) => {
    return trackRef.publication?.isSubscribed &&
      !trackRef.publication.isMuted ? (
      <VideoTrack trackRef={trackRef} />
    ) : (
      <PlaceholderContainer>
        <Space direction="vertical">
          <Avatar size={64} icon={<UserOutlined />} />
          <div>{trackRef.participant.name}</div>
        </Space>
      </PlaceholderContainer>
    )
  }

  const togglePermission = (participant: Participant) => {
    emit('toggleParticipantPermission', {
      participantId: participant.identity,
      call_id,
    })
  }

  const renderParticipantControls = (participant: Participant) => {
    if (participant === localParticipant) return null
    const metadata = participant?.metadata
      ? JSON.parse(participant.metadata)
      : null

    if (metadata?.isHost) return null
    return (
      <ControlsOverlay onClick={(e) => e.stopPropagation()}>
        <Tooltip
          title={
            participant?.permissions?.canPublish
              ? t({
                  id: 'stream.control_panel.action.retrieve_controls',
                  message: 'Video/audio uitschakelen voor deze deelnemer',
                })
              : t({
                  id: 'stream.control_panel.action.enable_controls',
                  message: 'Video/audio inschakelen voor deze deelnemer',
                })
          }
        >
          <Button
            type="link"
            shape={'circle'}
            style={{ fontSize: '1rem', color: 'white' }}
            onClick={() => togglePermission(participant)}
            icon={
              participant?.permissions?.canPublish ? (
                <SoundOutlined />
              ) : (
                <MutedOutlined />
              )
            }
          />
        </Tooltip>
      </ControlsOverlay>
    )
  }
  const getGridTemplateColumns = useCallback(() => {
    return isChatVisible ? '3fr 1fr 300px' : '3fr 1fr'
  }, [isChatVisible])

  return (
    <LayoutContextProvider>
      <LayoutContainer
        style={{
          gridTemplateColumns: getGridTemplateColumns(),
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <MainScreen>
            {metadata?.isRecording && (
              <RecordingIndicator>
                <RecordingContent>
                  <DotBorder>
                    <RecordingDot />
                  </DotBorder>
                  {t({
                    id: 'video_conference.is_recording',
                    message: 'Rec',
                  })}
                </RecordingContent>
              </RecordingIndicator>
            )}
            {renderMainContent()}
          </MainScreen>
          <ControlPanelWrapper>
            <ControlPanel
              onToggleChat={() => setIsChatVisible((prev) => !prev)}
              isChatVisible={isChatVisible}
              unreadChatCount={unreadChatCount}
              virtualBackground={virtualBackground}
              onVirtualBackgroundChange={setVirtualBackground}
            />
          </ControlPanelWrapper>
        </div>
        {!isMobile && (
          <SideBar>
            <SideBarTiles>
              {sidebarTracks
                .slice(0, Math.max(4, numberOfVideoSharingParticipants))
                .map((track) => {
                  const participantIsPublishing =
                    (track.participant.isCameraEnabled &&
                      track.participant.isMicrophoneEnabled) ||
                    (track?.participant?.metadata &&
                      JSON.parse(track?.participant?.metadata as string).isHost)
                  return (
                    <SideBarTile key={track.participant.identity}>
                      <ParticipantTile
                        disableSpeakingIndicator={false}
                        onClick={
                          isMeetingHost && participantIsPublishing
                            ? () => handleSetMainParticipant(track.participant)
                            : undefined
                        }
                        trackRef={track}
                        style={{
                          cursor:
                            isMeetingHost && participantIsPublishing
                              ? 'pointer'
                              : 'default',
                        }}
                      >
                        {renderVideoOrPlaceholder(track)}
                        {isMeetingHost &&
                          renderParticipantControls(track.participant)}
                      </ParticipantTile>
                    </SideBarTile>
                  )
                })}
              {participants.length < 2 && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t({
                    id: 'video_conference.no_participants',
                    message: 'Voorlopig ben je hier helemaal alleen.',
                  })}
                />
              )}
            </SideBarTiles>
            {isMeetingHost && participants.length > 1 && (
              <ParticipantList
                participants={participants.filter(
                  (p) =>
                    !(
                      p.identity &&
                      new ObjectId(p.identity).equals(localParticipant.identity)
                    )
                )}
                togglePermission={togglePermission}
              />
            )}
          </SideBar>
        )}
        {!isMobile && (
          <Chat
            isVisible={isChatVisible}
            onUnreadCountChange={setUnreadChatCount}
          />
        )}

        {!isMeetingHost && <SurveyModal isModalOpened={isSurveyModalVisible} />}
        <AttentionCheckModal
          isVisible={isAttentionCheckVisible}
          onConfirm={handleAttentionConfirm}
        />
      </LayoutContainer>
    </LayoutContextProvider>
  )
}
