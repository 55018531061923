/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import { useLazyQuery, useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Affix,
  Badge,
  Button,
  Dropdown,
  Form,
  Input,
  notification,
  PageHeader,
  Space,
  Tree,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useCallback, useContext, useEffect, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  CategoryQuery,
  CreateCategoryMutation,
  DeleteCategoryMutation,
  SettingCategoriesQuery,
  UpdateCategoryMutation,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import {
  CategoryTreeNode,
  useCategoriesTree,
} from '../../../branch/hooks/use-category-tree'
import REORDER_CATEGORIES_MUTATION from '../../../settings/mutations/reorder-categories.graphql'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { useLanguages } from '../../../shared/hooks/use-languages'

import CREATE_CATEGORY_MUTATION from './../../mutations/create-category.graphql'
import DELETE_CATEGORY_MUTATION from './../../mutations/delete-category.graphql'
import UPDATE_CATEGORY_MUTATION from './../../mutations/update-category.graphql'
import CATEGORY_QUERY from './../../queries/category.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface CategoryNode extends CategoryTreeNode {
  hierarchy?: string
}

export const Categories = () => {
  const ability = useContext(AbilityContext)
  const [categoryModalVisible, setCategoryModalVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<Partial<SettingCategoriesQuery['fetchCategories'][0]>>()
  const [form] = useForm()
  const [treeData, setTreeData] = useState<CategoryTreeNode[]>([])
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const [movingMode, setMovingMode] = useState(false)
  const [deleteSubject, setDeleteSubject] = useState<string | null>(null)
  const { languages } = useLanguages()

  const { data, loading: loadingCategories, refetch } = useCategoriesTree()

  useEffect(() => {
    if (categoryModalVisible) {
      form.setFieldsValue({
        name: updateSubject?.name || '',
      })
    } else {
      form.resetFields()
    }
  }, [categoryModalVisible, updateSubject, form])

  useEffect(() => {
    if (data) {
      setTreeData(data)

      const getAllKeys = (nodes: CategoryTreeNode[]): string[] => {
        let keys: string[] = []
        nodes.forEach((node) => {
          keys.push(node.key)
          if (node.children && node.children.length > 0) {
            keys = [...keys, ...getAllKeys(node.children)]
          }
        })
        return keys
      }

      setExpandedKeys(getAllKeys(data))
    }
  }, [data])

  const [fetchCategory] = useLazyQuery<CategoryQuery>(CATEGORY_QUERY)

  const [createCategory, { loading: creating }] =
    useMutation<CreateCategoryMutation>(CREATE_CATEGORY_MUTATION)

  const [updateCategory, { loading: updating }] =
    useMutation<UpdateCategoryMutation>(UPDATE_CATEGORY_MUTATION)

  const [deleteCategory] = useMutation<DeleteCategoryMutation>(
    DELETE_CATEGORY_MUTATION
  )

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()

      await (updateSubject?._id
        ? updateCategory({
            variables: {
              id: updateSubject._id,
              name: values.name,
              hierarchy: updateSubject.hierarchy,
            },
          })
            .then(() => {
              notification.success({
                message: t({
                  id: 'settings.categories.action.update.success',
                  message: 'Categorie succesvol gewijzigd',
                }),
              })
              setCategoryModalVisible(false)
              refetch()
            })
            .catch(errorNotifierFn)
        : createCategory({
            variables: {
              name: values.name,
              hierarchy: updateSubject?.hierarchy || undefined,
            },
          })
            .then(() => {
              notification.success({
                message: t({
                  id: 'settings.categories.action.create.success',
                  message: 'Categorie succesvol aangemaakt',
                }),
              })
              setCategoryModalVisible(false)
              refetch()
            })
            .catch(errorNotifierFn))
    } catch (error) {
      console.error('Failed to submit:', error)
    }
  }

  const onDrop = useCallback(
    (info) => {
      if (!treeData) return

      const dropKey = info.node.key as string
      const dragKey = info.dragNode.key as string
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos.at(-1))

      const loop = (
        data: Array<CategoryTreeNode>,
        key: string,
        callback: (
          item: CategoryTreeNode,
          index: number,
          arr: Array<CategoryTreeNode>
        ) => void
      ) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].key === key) {
            return callback(data[i], i, data)
          }
          if (data[i].children) {
            loop(data[i].children, key, callback)
          }
        }
      }

      const data: Array<CategoryTreeNode> = [...treeData]
      let dragObj: CategoryTreeNode | null = null

      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1)
        dragObj = item
      })

      if (!dragObj) return

      if (info.dropToGap) {
        let ar: Array<CategoryTreeNode> = []
        let i: number | undefined
        loop(data, dropKey, (_, index, arr) => {
          ar = arr
          i = index
        })
        if (dropPosition === -1) {
          ar.splice(i!, 0, dragObj)
        } else {
          ar.splice(i! + 1, 0, dragObj)
        }
      } else {
        loop(data, dropKey, (item) => {
          item.children = item.children || []
          if (item.children.length === 0 || dropPosition <= 0) {
            item.children.unshift(dragObj as CategoryTreeNode)
          } else {
            item.children.push(dragObj as CategoryTreeNode)
          }
        })
      }

      setTreeData(data)
      setMovingMode(true)
    },
    [treeData]
  )

  const extractHierarchy = useCallback(
    (items?: CategoryTreeNode[]): { _id: string; hierarchy: string }[] => {
      if (!items) return []

      const result: { _id: string; hierarchy: string }[] = []
      const processItem = (item: CategoryTreeNode, parentHierarchy = ',') => {
        const currentHierarchy = `${parentHierarchy}${item.key},`
        result.push({ _id: item.key, hierarchy: currentHierarchy })

        if (item.children) {
          item.children.forEach((child) => processItem(child, currentHierarchy))
        }
      }

      items.forEach((item) => processItem(item))
      return result
    },
    []
  )

  const [reorderCategories] = useMutation(REORDER_CATEGORIES_MUTATION, {
    refetchQueries: ['branchCategories'],
  })

  const handleSaveReorder = async () => {
    try {
      await reorderCategories({
        variables: {
          categories: extractHierarchy(treeData),
        },
      })
      notification.success({
        message: t({
          id: 'branch.categories.reorder.success',
          message: 'Volgorde succesvol opgeslagen.',
        }),
      })
      setMovingMode(false)
    } catch {
      notification.error({
        message: t({
          id: 'branch.categories.reorder.error',
          message: 'Volgorde opslaan mislukt.',
        }),
      })
    }
  }

  const handleCancelReorder = () => {
    setTreeData(data)
    setMovingMode(false)
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.categories.title',
          message: 'Categorieën',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={
          <Space>
            <Button
              hidden={
                !ability.can(
                  PermissionAction.CREATE,
                  PermissionObjectType.COURSE_CATEGORY
                ) || movingMode
              }
              type="primary"
              onClick={() => {
                setUpdateSubject(undefined)
                setCategoryModalVisible(true)
              }}
              loading={loadingCategories}
            >
              <Trans id="settings.categories.action.create">
                Nieuwe categorie
              </Trans>
            </Button>
            <Affix offsetTop={50}>
              <Space hidden={!movingMode}>
                <Button onClick={handleCancelReorder}>
                  <Trans id="action.cancel">Annuleren</Trans>
                </Button>
                <Button type="primary" onClick={handleSaveReorder}>
                  <Trans id="action.save">Opslaan</Trans>
                </Button>
              </Space>
            </Affix>
          </Space>
        }
      />
      {treeData && (
        <Tree
          blockNode
          showLine
          virtual
          style={{ paddingLeft: 18, paddingBottom: 24 }}
          draggable={{
            icon: false,
            nodeDraggable: () =>
              !loadingCategories &&
              ability.can(
                PermissionAction.UPDATE,
                PermissionObjectType.COURSE_CATEGORY
              ),
          }}
          onDragStart={(info) => {
            info.event.dataTransfer.effectAllowed = 'move'
            const img = new Image()
            img.src =
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
            info.event.dataTransfer.setDragImage(img, 0, 0)
          }}
          onDragOver={(info) => {
            info.event.dataTransfer.dropEffect = 'move'
          }}
          onDrop={onDrop}
          selectable={false}
          onExpand={(key) => setExpandedKeys(key.map(String))}
          expandedKeys={expandedKeys}
          treeData={treeData}
          titleRender={(node) => {
            return (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'add',
                      label: t({
                        id: 'category.hierarchy.action.add',
                        message: 'Nieuwe categorie toevoegen',
                      }),
                      onClick: () => {
                        const nodeData = node as CategoryNode
                        setUpdateSubject({
                          name: '',
                          hierarchy: nodeData.hierarchy,
                        })
                        setCategoryModalVisible(true)
                      },
                    },
                    {
                      key: 'edit',
                      label: t({
                        id: 'category.hierarchy.action.edit',
                        message: 'Categorie bewerken',
                      }),
                      onClick: async () => {
                        const cat = await fetchCategory({
                          variables: { id: node.key },
                        }).then((result) => result.data?.fetchCategory)
                        setUpdateSubject(cat)
                        setCategoryModalVisible(true)
                      },
                    },
                    ...(ability.can(
                      PermissionAction.TRANSLATE,
                      PermissionObjectType.COURSE_CATEGORY
                    )
                      ? [
                          {
                            type: 'divider' as const,
                          },
                          {
                            key: 'translate',
                            label: t({
                              id: 'settings.categories.action.translate',
                              message: 'Vertalen',
                            }),
                            children: languages
                              .filter((lang) => lang.code !== node.language)
                              .map((lang) => ({
                                key: `translate-${lang.code}`,
                                label: (
                                  <Space>
                                    {lang.name}
                                    {node.translations?.some(
                                      (t: { language: string }) =>
                                        t.language === lang.code
                                    ) && <Badge status="success" />}
                                  </Space>
                                ),
                                onClick: () => {
                                  window.location.href = `/settings/categories/translate/${node.key}/${lang.code}`
                                },
                              })),
                          },
                        ]
                      : []),
                    ...(node?.children?.length === 0
                      ? [
                          {
                            type: 'divider' as const,
                          },
                          {
                            key: 'delete',
                            label: t({
                              id: 'action.delete',
                              message: 'Verwijderen',
                            }),
                            onClick: () => setDeleteSubject(node.key as string),
                            danger: true,
                          },
                        ]
                      : []),
                  ],
                }}
                trigger={['contextMenu']}
              >
                <span>{node.title}</span>
              </Dropdown>
            )
          }}
        />
      )}
      <Modal
        forceRender
        destroyOnClose
        title={
          updateSubject?._id
            ? t({
                id: 'settings.categories.action.update.title',
                message: 'Categorie wijzigen',
              })
            : t({
                id: 'settings.categories.action.create.title',
                message: 'Categorie aanmaken',
              })
        }
        open={categoryModalVisible}
        onOk={handleSubmit}
        confirmLoading={creating || updating}
        onCancel={() => {
          setCategoryModalVisible(false)
        }}
        afterClose={() => setUpdateSubject(undefined)}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'action.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'action.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Form
          key={updateSubject?._id}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleSubmit}
          initialValues={updateSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.categories.form.label.name',
              message: 'Naam',
            })}
            name="name"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.categories.form.validation.name',
                  message: 'Gelieve een naam in te vullen.',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="action.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t({
          id: 'settings.categories.action.delete.title',
          message: 'Verwijderen',
        })}
        open={!!deleteSubject}
        onCancel={() => setDeleteSubject(null)}
        onOk={() =>
          deleteCategory({ variables: { id: deleteSubject } })
            .then(() => {
              refetch()
              setDeleteSubject(null)
            })
            .then(() => {
              notification.success({
                message: t({
                  id: 'settings.categories.action.delete.success',
                  message: 'Categorie succesvol verwijderd',
                }),
              })
            })
        }
        okText={t({
          id: 'action.delete',
          message: 'Verwijderen',
        })}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
      >
        <Trans id="branch.categories.delete.title">
          Ben je zeker dat je deze categorie wil verwijderen?
        </Trans>
      </Modal>
    </>
  )
}
