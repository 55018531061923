/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { t } from '@lingui/macro'
import { Modal } from 'antd'
import { usePostHog } from 'posthog-js/react'
import { useContext, useEffect, useState } from 'react'

import { ModalContext } from '../auth/context/searchModal.context'
import { CommandBar } from '../command-bar/CommandBar'

export const SearchModal = () => {
  const posthog = usePostHog()
  const [searchEnabled, setSearchEnabled] = useState(false)
  const { isOpen: isModalOpened, setIsOpen: setModalStatus } =
    useContext(ModalContext)

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setModalStatus((prevState) => !prevState)
      }
    }

    if (searchEnabled) {
      document.addEventListener('keydown', down)
      return () => document.removeEventListener('keydown', down)
    }
    return undefined
  }, [searchEnabled, setModalStatus])

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled('Search')) {
        setSearchEnabled(true)
      }
    })
  }, [posthog])

  return (
    <Modal
      className="search-modal"
      bodyStyle={{ padding: 0, width: '100%' }}
      cancelButtonProps={{ hidden: true }}
      centered={false}
      closable={false}
      okButtonProps={{ hidden: true }}
      onCancel={() => {
        setModalStatus(false)
      }}
      open={isModalOpened}
      footer={null}
      // @ts-ignore
      transitionName={null}
    >
      <CommandBar
        placeholderText={t({
          id: 'search.placeholder',
          message: 'Zoek of stel een vraag',
        })}
        closeParentModal={() => setModalStatus(false)}
      />
    </Modal>
  )
}
