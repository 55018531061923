import {
  MutedOutlined,
  SearchOutlined,
  SoundOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Plural, t } from '@lingui/macro'
import { Avatar, Button, Input, List, Modal, Space, Tooltip } from 'antd'
import Fuse from 'fuse.js'
import { Participant } from 'livekit-client'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { useStream } from '../../../../contexts/StreamContext'

const ScrollableList = styled(List)`
  max-height: 400px;
  overflow-y: auto;
`

interface ParticipantListProps {
  participants: Participant[]
  togglePermission: (participant: Participant) => void
}

export const ParticipantList: React.FC<ParticipantListProps> = ({
  participants,
  togglePermission,
}) => {
  const { isMeetingHost } = useStream()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const fuse = useMemo(() => {
    return new Fuse(
      participants.map((p) => ({ ...p, pname: `${p.name} ${p.name}` })),
      {
        keys: ['pname'],
        threshold: 0.3,
        ignoreLocation: true,
      }
    )
  }, [participants])

  const filteredParticipants = useMemo(() => {
    if (!searchTerm) return participants
    return fuse.search(searchTerm).map((result) => result.item)
  }, [participants, searchTerm, fuse])

  return (
    <>
      <Button
        type="primary"
        onClick={() => setIsModalVisible(true)}
        style={{ marginTop: '1rem' }}
      >
        <Space>
          <UserOutlined />
          <Plural
            id="stream.label.participant-count"
            zero="# deelnemer"
            one="# deelnemer"
            other="# deelnemers"
            value={participants.length}
          />
        </Space>
      </Button>
      <Modal
        title={t({
          id: 'stream.label.participants',
          message: 'Deelnemers',
        })}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={600}
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder={t({
            id: 'stream.label.search-participants',
            message: 'Zoek een deelnemer',
          })}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <ScrollableList
          dataSource={filteredParticipants}
          renderItem={(item) => {
            const participant = item as Participant
            const metadata = participant?.metadata
              ? JSON.parse(participant.metadata)
              : null
            return (
              <List.Item
                actions={[
                  isMeetingHost && !metadata?.isHost && (
                    <Tooltip
                      title={
                        participant?.permissions?.canPublish
                          ? t({
                              id: 'stream.control_panel.action.retrieve_controls',
                              message:
                                'Video/audio uitschakelen voor deze deelnemer',
                            })
                          : t({
                              id: 'stream.control_panel.action.enable_controls',
                              message:
                                'Video/audio inschakelen voor deze deelnemer',
                            })
                      }
                    >
                      <Button
                        type="text"
                        onClick={() => togglePermission(participant)}
                        icon={
                          participant?.permissions?.canPublish ? (
                            <SoundOutlined style={{ color: 'green' }} />
                          ) : (
                            <MutedOutlined />
                          )
                        }
                      />
                    </Tooltip>
                  ),
                ].filter(Boolean)}
              >
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} />}
                  title={participant.name}
                />
              </List.Item>
            )
          }}
        />
      </Modal>
    </>
  )
}
