import { SendOutlined, UserOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useRoomContext } from '@livekit/components-react'
import '@livekit/components-styles'
import { Avatar, Button, List } from 'antd'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import { LocalParticipant, RemoteParticipant, RoomEvent } from 'livekit-client'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useStream } from '../../../../contexts/StreamContext'

import {
  ChatWrapper,
  DrawerWrapper,
  InputArea,
  MessageContent,
  MessageItem,
  MessageList,
  SenderName,
  StyledInput,
  Time,
} from './Chat.style'

export interface ChatMessage {
  timestamp: Date
  sender?: RemoteParticipant | LocalParticipant
  message: string
}

export const Drawer = ({
  open,
  children,
}: {
  open: boolean
  children: React.ReactNode
}) => {
  if (!open) return
  return <DrawerWrapper>{children}</DrawerWrapper>
}

export const Chat = ({
  isVisible = false,
  onUnreadCountChange,
}: {
  isVisible: boolean
  onUnreadCountChange: (count: number) => void
}) => {
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [inputMessage, setInputMessage] = useState('')
  const room = useRoomContext()
  const [, setUnreadCount] = useState(0)
  const { participants: chatParticipants, notificationSound } = useStream()

  const chatRef = useRef<HTMLDivElement>(null)
  const messageListRef = useRef<HTMLDivElement>(null)
  const [isChatFocused, setIsChatFocused] = useState(false)
  const [lastSoundTime, setLastSoundTime] = useState(0)

  const playNotificationSound = useCallback(() => {
    const currentTime = Date.now()
    if (currentTime - lastSoundTime >= 5000) {
      notificationSound.play()
      setLastSoundTime(currentTime)
    }
  }, [lastSoundTime, notificationSound])

  const scrollToBottom = useCallback(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight
    }
  }, [])

  // Blur chat when it's not visible
  useEffect(() => {
    if (!isVisible) setIsChatFocused(false)
  }, [isVisible])

  // Scroll to bottom when new messages are added
  useEffect(() => {
    scrollToBottom()
  }, [messages, scrollToBottom])

  const handleContainerFocus = useCallback(() => {
    setIsChatFocused(true)
    setUnreadCount(0)
    onUnreadCountChange(0)
  }, [onUnreadCountChange])

  const handleContainerBlur = useCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      if (chatRef.current && !chatRef.current.contains(e.relatedTarget as Node))
        setIsChatFocused(false)
    },
    []
  )

  // When chat becomes visible, focus the container and scroll to bottom.
  useEffect(() => {
    if (isVisible) {
      handleContainerFocus()
      requestAnimationFrame(() => {
        scrollToBottom()
      })
    }
  }, [isVisible, scrollToBottom, handleContainerFocus])

  const handleDataReceived = useCallback(
    (payload: Uint8Array, participant?: RemoteParticipant) => {
      const decodedMessage = new TextDecoder().decode(payload)
      setMessages((prev) => [
        ...prev,
        { sender: participant, message: decodedMessage, timestamp: new Date() },
      ])
      if (!isChatFocused) {
        setUnreadCount((prev) => {
          const newCount = prev + 1
          onUnreadCountChange(newCount)
          return newCount
        })
        playNotificationSound()
      }
    },
    [isChatFocused, onUnreadCountChange, playNotificationSound]
  )

  const renderAvatar = (sender: RemoteParticipant | LocalParticipant) => {
    const profilePicture = chatParticipants?.find((p) =>
      new ObjectId(p._id).equals(sender.identity)
    )?.picture?.url
    return profilePicture ? (
      <Avatar src={profilePicture} />
    ) : (
      <Avatar icon={<UserOutlined />} />
    )
  }

  useEffect(() => {
    room.on(RoomEvent.DataReceived, handleDataReceived)

    return () => {
      room.off(RoomEvent.DataReceived, handleDataReceived)
    }
  }, [room, handleDataReceived])

  const sendMessage = useCallback(() => {
    if (inputMessage.trim()) {
      room.localParticipant.publishData(
        new TextEncoder().encode(inputMessage),
        { reliable: true }
      )
      setMessages((prev) => [
        ...prev,
        {
          sender: room.localParticipant,
          message: inputMessage,
          timestamp: new Date(),
        },
      ])
      setInputMessage('')
      setTimeout(scrollToBottom, 0)
    }
  }, [inputMessage, room.localParticipant, scrollToBottom])

  return (
    <Drawer open={isVisible}>
      <ChatWrapper
        ref={chatRef}
        tabIndex={0}
        onFocusCapture={handleContainerFocus}
        onBlurCapture={handleContainerBlur}
      >
        <MessageList
          ref={messageListRef}
          dataSource={messages}
          locale={{
            emptyText: t({
              id: 'stream.chat.no_messages',
              message: 'Geen berichten',
            }),
          }}
          renderItem={(item) => (
            <MessageItem>
              <List.Item.Meta
                avatar={item.sender ? renderAvatar(item.sender) : undefined}
                title={
                  <>
                    <SenderName>
                      {new ObjectId(item.sender?.identity).equals(
                        room.localParticipant?.identity
                      )
                        ? t({
                            id: 'stream.chat.you',
                            message: 'Jij',
                          })
                        : item.sender?.name}
                    </SenderName>
                    <Time>{dayjs(item.timestamp).format('HH:mm')}</Time>
                  </>
                }
                description={<MessageContent>{item.message}</MessageContent>}
              />
            </MessageItem>
          )}
        />
        <InputArea>
          <StyledInput
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onPressEnter={sendMessage}
            placeholder={t({
              id: 'stream.chat.input_placeholder',
              message: 'Typ een bericht...',
            })}
          />
          <Button
            onClick={sendMessage}
            type="primary"
            icon={<SendOutlined />}
          />
        </InputArea>
      </ChatWrapper>
    </Drawer>
  )
}
