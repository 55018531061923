import { t, Trans } from '@lingui/macro'
import { Col, Row, Space } from 'antd'

import { LoadSection } from '../../../core/components/LoadScreen'
import { ExportButton } from '../../../shared/components/export-button/ExportButton'
import { useAxios } from '../../../shared/hooks/use-axios'
import { dateRenderer, percentageRenderer } from '../../helpers/renderers'
import { Table } from '../Table'
import { TableHeader } from '../TableHeader/TableHeader.style'
import { UserPathsModuleReport } from '../UserPathModulesReport'

export const UserPathsReport = ({
  user_id,
  branch_id,
  section_id,
  header,
  start,
  end,
  exportable,
}: {
  user_id: string
  branch_id?: string
  section_id: string | null | undefined
  header?: string
  start?: string
  end?: string
  exportable?: string
}) => {
  const [{ data }] = useAxios({
    url: `/api/activity/paths/user/${user_id}`,
    params: {
      section_id: section_id ?? undefined,
      branch_id: branch_id ?? undefined,
      ...(start && end ? { start, end } : {}),
    },
  })

  if (!data)
    return (
      <>
        <div className="ant-table-wrapper">
          <div className="ant-table ant-table-small ant-table-bordered">
            <div className="ant-table-title">
              {header && <TableHeader>{header}</TableHeader>}
            </div>
            <div className="ant-table-footer">
              <LoadSection />
            </div>
          </div>
        </div>
      </>
    )

  return (
    <Table
      {...(header && {
        title: () => <TableHeader>{header}</TableHeader>,
      })}
      expandable={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        expandedRowRender: (record: any) => (
          <UserPathsModuleReport
            path_id={record._id}
            user_id={user_id}
            branch_id={record.branch_id}
            section_id={section_id}
          />
        ),
        expandRowByClick: true,
        rowExpandable: () => true,
      }}
      columns={[
        {
          title: t({
            id: 'reports.learning_paths.table.learning_path',
            message: 'Leerpaden',
          }),
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: t({
            id: 'reports.users_report.table.progress',
            message: 'Vooruitgang',
          }),
          dataIndex: 'progress',
          key: 'progress',
          render: percentageRenderer,
          align: 'center' as const,
        },
        {
          title: t({
            id: 'reports.users_report.table.last_activity',
            message: 'Laatst actief op',
          }),
          dataIndex: 'last_activity',
          key: 'last_activity',
          render: dateRenderer,
          align: 'center' as const,
        },
        {
          title: t({
            id: 'reports.users_report.table.completed',
            message: 'Voltooid op',
          }),
          dataIndex: 'completed',
          key: 'completed',
          render: dateRenderer,
          align: 'center' as const,
        },
      ]}
      bordered
      size="small"
      dataSource={data}
      rowKey="_id"
      pagination={false}
      footer={() => {
        if (!exportable) {
          return (
            <Row align={'middle'}>
              <Col xs={24} sm={8} offset={16}>
                <div style={{ textAlign: 'right' }}>
                  <Space>
                    <span>
                      <Trans id="reports.courses_report.action.export">
                        Exporteer naar:
                      </Trans>{' '}
                    </span>
                    {['xlsx', 'csv', 'json'].map((type, index) => (
                      <ExportButton
                        key={index}
                        url={`/api/export/user/${user_id}/paths`}
                        params={
                          start && end
                            ? {
                                branch_id,
                                section_id: section_id ?? undefined,
                                start,
                                end,
                              }
                            : {
                                branch_id,
                                section_id: section_id ?? undefined,
                              }
                        }
                        type={type}
                        name={
                          data?.user?.name.replaceAll(/\s/g, '_') ||
                          `user_${user_id}`
                        }
                        dates={['last_activity', 'completed']}
                      />
                    ))}
                  </Space>
                </div>
              </Col>
            </Row>
          )
        }
        return (
          <div style={{ textAlign: 'right' }}>
            <Space>
              <span>
                <Trans id="reports.courses_report.action.export">
                  Exporteer naar:
                </Trans>{' '}
              </span>
              {['xlsx', 'csv', 'json'].map((type, index) => (
                <ExportButton
                  key={index}
                  url={exportable || '/api/export/users'}
                  params={
                    start && end
                      ? {
                          start,
                          end,
                          branch_id: branch_id ?? undefined,
                          section_id:
                            section_id ??
                            sessionStorage.getItem('aa_report_filter'),
                        }
                      : {
                          branch_id: branch_id ?? undefined,
                          section_id:
                            section_id ??
                            sessionStorage.getItem('aa_report_filter'),
                        }
                  }
                  type={type}
                  name="personal"
                  dates={['last_activity', 'completed']}
                />
              ))}
            </Space>
          </div>
        )
      }}
    />
  )
}
