import { MinusOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Button, Form, Input, Space, notification } from 'antd'
import { OTPInput, SlotProps } from 'input-otp'
import { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { RequestMagicCodeMutation } from 'apps/lms-front/src/generated/graphql'
import REQUEST_MAGIC_CODE_MUTATION from 'apps/lms-front/src/modules/auth/mutations/request-magic-code.graphql'

import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { useAuth } from '../../hooks/use-auth'
import { AlignVertically } from '../login'

export const ExternalLogin = () => {
  const [codeSent, setCodeSent] = useState<boolean>(false)

  const [email] = useState<string | undefined | null>(
    localStorage.getItem('aa_email')
  )

  const [requestMagicCode] = useMutation<RequestMagicCodeMutation>(
    REQUEST_MAGIC_CODE_MUTATION
  )

  const { pathname } = window.location
  const isAdminLogin = pathname === '/adminlogin'

  const [loading, setLoading] = useState<boolean>(false)
  const { verifyMagicCode } = useAuth()

  const [otpForm] = Form.useForm()
  const otpEmail = Form.useWatch('email', otpForm)

  return (
    <AlignVertically>
      <>
        <center>
          <h1>
            <Trans id="login.title.email_code">
              Inloggen via éénmalige code
            </Trans>
          </h1>
          <Form hidden={codeSent} form={otpForm} validateTrigger="submit">
            <Space direction="vertical" style={{ width: '50%' }}>
              <p>
                <Trans id="login.subtitle.email_code">
                  Vul je e-mailadres in om verder te gaan.
                </Trans>
              </p>
              <Form.Item
                labelAlign="left"
                name="email"
                normalize={(value) => value.trim()}
                rules={[
                  {
                    type: 'email',
                    message: t({
                      id: 'login.form.validation.required.email',
                      message: 'Voer een geldig e-mailadres in',
                    }),
                  },
                  {
                    required: email && !isAdminLogin ? false : true,
                    message: t({
                      id: 'login.form.validation.email',
                      message: 'Gelieve je e-mailadres in te geven',
                    }),
                  },
                ]}
              >
                <Input
                  disabled={loading}
                  size="large"
                  placeholder={t({
                    id: 'login.form.label.email',
                    message: 'E-mailadres',
                  })}
                />
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                onClick={() => {
                  setLoading(true)
                  requestMagicCode({ variables: { email: otpEmail } })
                    .then((data) => {
                      data.data?.requestMagicCodeEmail
                        ? setCodeSent(true)
                        : setCodeSent(false)
                      setLoading(false)
                    })
                    .catch((error) => {
                      setLoading(false)
                      errorNotifierFn(error)
                    })
                }}
              >
                <Trans id="login.form.action.send_email_code">
                  Verstuur code
                </Trans>
              </Button>
            </Space>
          </Form>
          {codeSent && (
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <p>
                We hebben een code verzonden naar &quot;{otpEmail}&quot;. Vul de
                code hieronder in om in te loggen.
              </p>
              <OTPInput
                textAlign="center"
                maxLength={6}
                onComplete={(code: string) => {
                  verifyMagicCode(
                    otpEmail || '',
                    code,
                    () => ({}),
                    () => {
                      notification.error({
                        message: t({
                          id: 'login.form.validation.otp_code',
                          message:
                            'Deze code is verlopen of onjuist. Probeer opnieuw.',
                        }),
                      })
                    }
                  )
                }}
                render={({ slots }) => (
                  <Space>
                    <Space
                      style={{ overflow: 'visible', verticalAlign: 'middle' }}
                    >
                      {slots.slice(0, 3).map((slot, idx) => (
                        <Slot key={idx} {...slot} />
                      ))}
                    </Space>
                    <MinusOutlined />
                    <Space
                      style={{ overflow: 'visible', verticalAlign: 'middle' }}
                    >
                      {slots.slice(3).map((slot, idx) => (
                        <Slot key={idx} {...slot} />
                      ))}
                    </Space>
                  </Space>
                )}
              ></OTPInput>
              <Button type="text" onClick={() => setCodeSent(false)}>
                Ander e-mailadres?
              </Button>
            </Space>
          )}
        </center>
      </>
    </AlignVertically>
  )
}

const SlotContainer = styled.div<{ isActive: boolean }>`
  background-color: #fff;
  position: relative;
  width: 2.5rem;
  height: 3.5rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  border-style: solid;
  border-width: 1px 1px 1px 0; // top, right, bottom, left
  border-color: var(--border-color); // Assume --border-color is defined in CSS
  border-radius: 0;
  outline: 0 solid var(--ant-primary-color);

  // First child specific styles
  &:first-of-type {
    border-left-width: 1px;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  // Last child specific styles
  &:last-of-type {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  // Active state styles
  ${(props) =>
    props.isActive &&
    css`
      outline: 4px solid var(--ant-primary-color) !important;
    `}
`

const Slot: React.FC<SlotProps> = ({
  isActive,
  char,
  hasFakeCaret,
}: SlotProps) => {
  return (
    <SlotContainer isActive={isActive}>
      {char !== null && <div>{char}</div>}
      {hasFakeCaret && <FakeCaret />}
    </SlotContainer>
  )
}

const blink = keyframes`
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`

// Styled component for the outer container
const CaretContainer = styled.div`
  position: absolute;
  pointer-events: none;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${blink} 1s step-start infinite;
`

// Styled component for the caret itself
const Caret = styled.div`
  width: 1px;
  height: 2rem; // converted 8 to rem for better scaling
  background-color: var(--text-color);
`

function FakeCaret() {
  return (
    <CaretContainer>
      <Caret />
    </CaretContainer>
  )
}
