/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  PageHeader,
  Row,
  Space,
  Switch,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import { cloneDeep } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  CreateQuizUnitMutation,
  UpdateQuizUnitMutation,
  QuizUnitInput,
  CreateQuizUnitMutationVariables,
  QuizUnit,
} from 'apps/lms-front/src/generated/graphql'
import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { PageProps } from 'apps/lms-front/src/modules/shared/interfaces/page.interface'
import { Content } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

import { purgeProperties } from '../../../helpers/purge-properties'

import CREATE_QUIZ_UNIT_MUTATION from './../../../mutations/create-quiz-unit.graphql'
import UPDATE_QUIZ_UNIT_MUTATION from './../../../mutations/update-quiz-unit.graphql'

interface Props extends PageProps {
  className?: string
  unit?: QuizUnit
}

export const CourseEditQuizUnit = ({ route, unit }: Props) => {
  const navigate = useNavigate()

  const [createUnit, { loading: creating }] =
    useMutation<CreateQuizUnitMutation>(CREATE_QUIZ_UNIT_MUTATION)
  const [updateUnit, { loading: updating }] =
    useMutation<UpdateQuizUnitMutation>(UPDATE_QUIZ_UNIT_MUTATION, {
      variables: {
        id: unit?._id,
      },
    })

  const { id, section } = useParams()

  const [form] = useForm()
  const [formDirty, setFormDirty] = useState<boolean>(false)
  const [showExplanationField, setShowExplanationField] = useState<number[]>([])

  const cleanedUnit = useMemo(() => {
    if (unit) {
      const clone = cloneDeep(unit)
      purgeProperties(clone, ['__typename'])
      return clone as Store
    }
    return undefined
  }, [unit])

  const MAX_CHARACTERS = {
    NAME: 100,
  }

  const SaveButton = () => (
    <Button
      disabled={!formDirty || updating || creating}
      type="primary"
      htmlType="button"
      onClick={() => {
        if (unit?.translations && unit.translations.length > 0) {
          Modal.confirm({
            title: t({
              id: 'course.unit.edit.translations.warning.title',
              message: 'Controleer vertalingen',
            }),
            content: t({
              id: 'course.unit.edit.translations.warning.content',
              message:
                'Dit onderdeel bevat vertalingen. Controleer of deze nog up-to-date zijn na je wijzigingen.',
            }),
            okText: t({
              id: 'action.continue',
              message: 'Doorgaan',
            }),
            cancelText: t({
              id: 'action.cancel',
              message: 'Annuleren',
            }),
            onOk: () => form.submit(),
          })
        } else {
          form.submit()
        }
      }}
    >
      <Trans id="action.save">Opslaan</Trans>
    </Button>
  )

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
        extra={[
          unit ? (
            <Button
              key="3"
              onClick={() =>
                navigate(`/courses/${unit.course_id}/edit-content`)
              }
            >
              <Trans id="course.edit_contents.back_to_overview">
                Terug naar overzicht
              </Trans>
            </Button>
          ) : (
            <Button onClick={() => navigate(-1)} key="2">
              <Trans id="action.cancel">Annuleren</Trans>
            </Button>
          ),
          <SaveButton key="1" />,
        ]}
      />
      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={cleanedUnit}
              onFinishFailed={(error) => {
                error.errorFields.forEach((field) => {
                  field.errors.forEach((error) => {
                    notification.error({
                      message: error,
                    })
                  })
                })
              }}
              onFinish={(variables: QuizUnitInput) => {
                if (unit) {
                  updateUnit({
                    variables: {
                      ...variables,
                      _id: unit._id,
                    },
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'course.unit.edit.success',
                          message: 'Onderdeel succesvol opgeslagen',
                        }),
                      })
                      setFormDirty(false)
                    })
                    .catch(errorNotifierFn)
                } else {
                  createUnit({
                    variables: {
                      name: variables.name,
                      questions: variables.questions,
                      courseId: id,
                      sectionId: section,
                    } as CreateQuizUnitMutationVariables,
                  })
                    .then((result) => {
                      notification.success({
                        message: t({
                          id: 'course.unit.create.success',
                          message: 'Onderdeel succesvol opgeslagen',
                        }),
                      })
                      const id = result.data?.addQuizUnit.course_id
                      navigate(`/courses/${id}/edit-content`, { replace: true })
                    })
                    .catch(errorNotifierFn)
                }
              }}
              onFieldsChange={() => setFormDirty(true)}
              autoComplete="off"
            >
              <Form.Item
                label={t({
                  id: 'course.unit.form.label.name',
                  message: 'Naam',
                })}
                name="name"
                rules={[
                  {
                    required: !!unit,
                    message: t({
                      id: 'course.unit.form.validation.name',
                      message:
                        'Gelieve een naam voor dit onderdeel in te vullen',
                    }),
                  },
                  {
                    max: MAX_CHARACTERS.NAME,
                    message: t({
                      id: 'course.unit.form.validation.max_characters',
                      message: `Gelieve onder de ${MAX_CHARACTERS.NAME} tekens te blijven`,
                    }),
                  },
                ]}
              >
                <Input tabIndex={0} />
              </Form.Item>
              <Form.List
                name="questions"
                rules={[
                  {
                    validator: async (_, questions) => {
                      if (!questions || questions.length === 0) return true
                      const error = questions.some(
                        (question: { answers: { correct: boolean }[] }) =>
                          (question.answers && question.answers.length < 2) ||
                          !question.answers
                      )

                      if (error)
                        throw new Error(
                          t({
                            id: 'course.unit.form.validation.quiz.min_answers',
                            message:
                              'Gelieve minstens twee antwoorden op te geven',
                          })
                        )
                      else return true
                    },
                    message: t({
                      id: 'course.unit.form.validation.quiz.min_answers',
                      message: 'Gelieve minstens twee antwoorden op te geven',
                    }),
                  },
                  {
                    validator: async (_, questions) => {
                      if (!questions || questions.length === 0) return true
                      const error = questions.some(
                        (question: { answers: { is_correct: boolean }[] }) =>
                          !question.answers.some((answer) => answer.is_correct)
                      )
                      if (error)
                        throw new Error(
                          t({
                            id: 'course.unit.form.validation.quiz.correct_answer',
                            message:
                              'Gelieve minstens één correct antwoord op te geven',
                          })
                        )
                      else return true
                    },
                    message: t({
                      id: 'course.unit.form.validation.quiz.correct_answer',
                      message:
                        'Gelieve minstens één correct antwoord op te geven',
                    }),
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <>
                    <Row>
                      <Col span={16} offset={8}>
                        <Collapse ghost={fields.length === 0}>
                          {fields.map(({ key, name, ...restField }, i, arr) => (
                            <Collapse.Panel
                              key={key}
                              forceRender={true}
                              header={
                                <div
                                  style={{
                                    flex: 1,
                                    display: 'flex',
                                  }}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  onKeyUp={(e) => e.stopPropagation()}
                                  onKeyPress={(e) => e.stopPropagation()}
                                  onClick={(e) => e.stopPropagation()}
                                  role={'menuitem'}
                                  tabIndex={-1}
                                >
                                  <Form.Item
                                    {...restField}
                                    style={{
                                      flex: 1,
                                      marginTop: -4,
                                      marginBottom: -4,
                                    }}
                                    wrapperCol={{ span: 24 }}
                                    name={[name, 'question']}
                                    rules={[
                                      {
                                        required: true,
                                        message: t({
                                          id: 'course.unit.form.validation.quiz.question',
                                          message:
                                            'Gelieve een vraag in te vullen',
                                        }),
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={t({
                                        id: 'course.unit.form.placeholder.quiz.question',
                                        message: 'Vul hier de vraag in',
                                      })}
                                    />
                                  </Form.Item>
                                </div>
                              }
                              style={{
                                marginBottom: i === arr.length - 1 ? 24 : 0,
                              }}
                              extra={
                                <MinusCircleOutlined
                                  style={{
                                    padding: 12,
                                    margin: -12,
                                    marginLeft: 0,
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    remove(name)
                                  }}
                                />
                              }
                            >
                              <Form.List name={[name, 'answers']}>
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(
                                      ({ key, name, ...restField }) => (
                                        <div
                                          key={key}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            marginBottom: -8,
                                            paddingLeft: 24,
                                          }}
                                        >
                                          <MinusCircleOutlined
                                            style={{
                                              margin: -8,
                                              padding: 8,
                                              marginRight: 4,
                                            }}
                                            onClick={() => remove(name)}
                                          />
                                          <Form.Item
                                            {...restField}
                                            style={{ flex: 1 }}
                                            wrapperCol={{ span: 24 }}
                                            name={[name, 'answer']}
                                            rules={[
                                              {
                                                required: true,
                                                message: t({
                                                  id: 'course.unit.form.validation.quiz.answer',
                                                  message:
                                                    'Vul een antwoord in',
                                                }),
                                              },
                                            ]}
                                          >
                                            <Input />
                                          </Form.Item>
                                          <Form.Item
                                            {...restField}
                                            style={{ marginLeft: 24 }}
                                            name={[name, 'is_correct']}
                                            valuePropName="checked"
                                          >
                                            <Checkbox>
                                              <Trans id="course.unit.form.label.quiz.correct">
                                                Correct
                                              </Trans>
                                            </Checkbox>
                                          </Form.Item>
                                        </div>
                                      )
                                    )}
                                    <Form.Item
                                      wrapperCol={{ span: 24 }}
                                      style={{ paddingLeft: 48 }}
                                    >
                                      <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                      >
                                        <Space
                                          style={{
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <PlusOutlined />
                                          <Trans id="course.unit.form.action.quiz.add_answer">
                                            Antwoord toevoegen
                                          </Trans>
                                        </Space>
                                      </Button>
                                    </Form.Item>
                                    <Divider />
                                  </>
                                )}
                              </Form.List>
                              {!showExplanationField.includes(key) &&
                              !form.getFieldValue('questions')?.[key]
                                ?.explanation ? (
                                <Button
                                  type="text"
                                  onClick={() => {
                                    const updated = [
                                      ...showExplanationField,
                                      key,
                                    ]
                                    setShowExplanationField(updated)
                                  }}
                                  block
                                >
                                  <Space style={{ justifyContent: 'center' }}>
                                    <PlusOutlined />
                                    <Trans id="course.unit.form.action.quiz.add_explanation">
                                      Voeg uitleg bij antwoord toe
                                    </Trans>
                                  </Space>
                                </Button>
                              ) : (
                                <div
                                  key={key}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    marginBottom: -8,
                                    paddingLeft: 24,
                                  }}
                                >
                                  <Form.Item
                                    {...restField}
                                    style={{
                                      flex: 1,
                                      marginTop: -4,
                                    }}
                                    wrapperCol={{ span: 24 }}
                                    name={[name, 'explanation']}
                                  >
                                    <Input
                                      placeholder={t({
                                        id: 'course.unit.form.placeholder.quiz.explanation',
                                        message: 'Vul hier de uitleg in',
                                      })}
                                    />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    style={{
                                      marginLeft: 8,
                                    }}
                                    onClick={() => {
                                      // update the array of ids where the field should be shown
                                      const updatedItems =
                                        showExplanationField.filter(
                                          (i) => i !== key
                                        )
                                      setShowExplanationField(updatedItems)

                                      // clear value of field
                                      const updatedQuestions =
                                        form.getFieldValue('questions')

                                      updatedQuestions[name] = {
                                        ...updatedQuestions[name],
                                        explanation: undefined,
                                      }

                                      form.setFieldValue(
                                        'questions',
                                        updatedQuestions
                                      )

                                      // enable save button
                                      setFormDirty(true)
                                    }}
                                  />
                                </div>
                              )}
                            </Collapse.Panel>
                          ))}
                        </Collapse>

                        <Form.Item wrapperCol={{ span: 24 }}>
                          <Button type="dashed" onClick={() => add()} block>
                            <Space style={{ justifyContent: 'center' }}>
                              <PlusOutlined />
                              <Trans id="course.unit.form.action.quiz.add_question">
                                Vraag toevoegen
                              </Trans>
                            </Space>
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
              <Form.Item
                name="optional"
                label={t({
                  id: 'course.unit.form.label.optional',
                  message: 'Optioneel',
                })}
                tooltip={t({
                  id: 'course.unit.form.help.optional',
                  message:
                    'Dit lesonderdeel is niet verplicht om het attest van deze opleiding te behalen.',
                })}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                <SaveButton />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </>
  )
}
