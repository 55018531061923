import { t } from '@lingui/macro'
import { usePersistentUserChoices } from '@livekit/components-react'
import { Button, notification } from 'antd'
import { ObjectId } from 'bson'
import { Room } from 'livekit-client'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { LiveEventByCallIdQuery } from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useSocket } from 'apps/lms-front/src/modules/shared/hooks/use-socket.hook'

import { useBranch } from '../../auth/hooks/use-branch'

interface StreamContextType {
  isMeetingHost: boolean
  participants: Participant[]
  notificationSound: HTMLAudioElement
  emit: (event: string, data: unknown) => void
  leaveRoom: (navigateTo?: string) => void
  surveyCompletionArr: string[]
  surveyActiveTimer: number
  survey: LiveEventByCallIdQuery['fetchLiveEventByCallId']['survey']
  setSurveyModalVisibility: React.Dispatch<React.SetStateAction<boolean>>
  call_id: string
  connected: boolean
  isSurveyModalVisible: boolean
  duration: number
  attentionCheckEnabled: boolean
  isAllParticipantsControlAllowed: boolean
  token: string | null | undefined
  tokenError?: number
  getNewToken: () => void
  persistentUserChoices: ReturnType<typeof usePersistentUserChoices>
  isAttentionCheckVisible: boolean
  handleAttentionConfirm: () => void
}
export type Participant = {
  _id: string
  firstName: string
  lastName: string
  picture: {
    url: string
    width: number
    height: number
  }
}
const StreamContext = createContext<StreamContextType | undefined>(undefined)

export const StreamProvider: React.FC<{
  call_id: string
  event_id: string
  isMeetingHost: boolean
  children: React.ReactNode
  participants: Participant[]
  survey: LiveEventByCallIdQuery['fetchLiveEventByCallId']['survey']
  duration: number
  attentionCheckEnabled: boolean
}> = ({
  children,
  call_id,
  event_id,
  isMeetingHost,
  participants,
  survey,
  duration,
  attentionCheckEnabled,
}) => {
  const persistentUserChoices = usePersistentUserChoices()
  const branch = useBranch()
  const navigate = useNavigate()
  const [token, setToken] = useState<string | undefined | null>()
  const [tokenError, setTokenError] = useState<number>()

  const [isSurveyModalVisible, setSurveyModalVisibility] = useState(false)
  const [isAttentionCheckVisible, setIsAttentionCheckVisible] = useState(false)

  const getToken = useCallback(
    async (call_id: string) => {
      const response = await fetch(
        `${import.meta.env.NX_BACKEND_URL}/api/stream/generate-token`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
          },
          body: JSON.stringify({
            call_id,
          }),
        }
      )

      if (response.ok) {
        setTokenError(undefined)
      } else {
        const error = await response.json()
        setTokenError(error.statusCode)
        return
      }

      const data = await response.json()
      if (data?.token) return data.token
    },
    [setTokenError]
  )

  const getNewToken = () => {
    getToken(call_id)
      .then((token) => setToken(token ?? null))
      .catch((error) => {
        console.error('Failed to get new token:', error)
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getNewToken, [setToken, call_id, isMeetingHost])

  const { user, token: userToken } = useAuth()
  const [surveyCompletionArr] = useState<string[]>([])
  const [isAllParticipantsControlAllowed, setIsAllParticipantsControlAllowed] =
    useState(false)
  const surveyActiveTimer = 60000 // 1 minute

  const findParticipantById = useCallback(
    (id: string): Participant | null => {
      return (
        participants.find((participant) =>
          new ObjectId(participant._id).equals(id)
        ) ?? null
      )
    },
    [participants]
  )

  const notificationSound = useMemo(() => new Audio('/notification.mp3'), [])

  const showHandRaisedNotification = (id: string) => {
    const participant = findParticipantById(id)
    const key = `notification-${id}`

    notificationSound
      .play()
      .catch((error) => console.error('Audio Play Error:', error))

    const description = participant
      ? t({
          id: 'stream.control_panel.notification.hand_raised.description.participant',
          message: `Hand opgestoken door: ${`${participant.firstName} ${participant.lastName}`}`,
        })
      : t({
          id: 'stream.control_panel.notification.hand_raised.description',
          message: 'Een deelnemer heeft zijn hand opgestoken',
        })

    notification.open({
      key,
      message: t({
        id: 'stream.control_panel.notification.hand_raised',
        message: 'Hand raised',
      }),
      description,
      duration: 0,
      btn: (
        <Button
          type="primary"
          onClick={() => {
            emit('toggleParticipantPermission', {
              participantId: id,
              call_id,
              forceEnableControl: true,
            })
            notification.close(key)
            emit('raiseHand', {
              participantId: id,
              call_id,
              isHandRaised: false,
            })
          }}
        >
          {t({
            id: 'stream.control_panel.notification.hand_raised.button',
            message: 'Allow control',
          })}
        </Button>
      ),
      onClose: () => {
        emit('raiseHand', {
          participantId: id,
          call_id,
          isHandRaised: false,
        })
      },
    })
  }

  const showAttentionCheckNotification = (userId: string) => {
    if (!user || user._id !== userId) return
    setIsAttentionCheckVisible(true)
  }

  const { connected, emit, disconnect } = useSocket('/api/stream/ws', {
    token: userToken,
    query: {
      call_id,
      branch_id: branch!._id,
      event_id,
    },
    transports: ['polling', 'websocket'],
    events: {
      roomActivated: getNewToken,
      roomClosed: () => {
        leaveRoom(`/meet/${call_id}/join`)
      },
      surveyStatusChanged: ({ isVisible }) => {
        setSurveyModalVisibility(isVisible)
      },
      allParticipantsPermissionsChanged: ({ isControlAllowed }) => {
        setIsAllParticipantsControlAllowed(isControlAllowed)
      },
      participantPermissionsChanged: ({ participantId, permissions }) => {
        if (
          !isMeetingHost &&
          user?._id.toString() === participantId &&
          permissions.canPublish
        ) {
          notification.success({
            message: t({
              id: 'stream.control_panel.action.camera_mic_enabled',
              message: 'Je kan nu video en geluid delen',
            }),
            duration: 4,
          })
        }
      },
      handRaised: ({ participantId, isHandRaised }) => {
        if (!participantId) return

        if (isMeetingHost && isHandRaised) {
          showHandRaisedNotification(participantId)
        } else {
          notification.close(`notification-${participantId}`)
        }
      },
      attentionCheck: ({ userId }) => {
        showAttentionCheckNotification(userId)
      },
    },
  })

  const leaveRoom = useCallback(
    (navigateTo?: string, room?: Room) => {
      // First disconnect from socket
      if (connected) {
        disconnect()
      }

      // Then properly disconnect from LiveKit room
      if (room) {
        room.disconnect(true)
      }

      navigate(
        navigateTo ?? (event_id ? `/calendar?event=${event_id}` : '/calendar')
      )
    },
    [connected, disconnect, event_id, navigate]
  )

  const handleAttentionConfirm = useCallback(() => {
    if (isAttentionCheckVisible) {
      emit('attentionCheckPassed', { userId: user?._id, call_id })
      setIsAttentionCheckVisible(false)
    }
  }, [emit, isAttentionCheckVisible, user, call_id])

  const value = {
    isMeetingHost,
    call_id,
    emit,
    connected,
    leaveRoom,
    participants,
    isSurveyModalVisible,
    setSurveyModalVisibility,
    surveyCompletionArr,
    surveyActiveTimer,
    survey,
    isAllParticipantsControlAllowed,
    duration,
    notificationSound,
    attentionCheckEnabled,
    token,
    tokenError,
    getNewToken,
    persistentUserChoices,
    isAttentionCheckVisible,
    handleAttentionConfirm,
  }

  return (
    <StreamContext.Provider value={value}>{children}</StreamContext.Provider>
  )
}

export const useStream = () => {
  const context = useContext(StreamContext)
  if (context === undefined) {
    throw new Error('useStream must be used within a StreamProvider')
  }
  return context
}
