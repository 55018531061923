import { t } from '@lingui/macro'
import { Statistic, Row, Col } from 'antd'

import { UserEventsReport } from '../../../components/UserEventsReport'

interface EventData {
  events_attended?: number
  events_completed?: number
  events_total?: number
}

interface EventsTabProps {
  data: EventData
  loading: boolean
  user_id: string
  branch_id: string
  start?: string
  end?: string
}

export const EventsTab = ({
  data,
  loading,
  user_id,
  branch_id,
  start,
  end,
}: EventsTabProps) => (
  <>
    <Row gutter={16} style={{ textAlign: 'center', marginBottom: 16 }}>
      <Col span={6}>
        <Statistic
          title={t({
            id: 'reports.personal.events_attended',
            message: 'Aanwezig',
          })}
          value={data?.events_attended || 0}
          loading={loading}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title={t({
            id: 'reports.personal.events_completed',
            message: 'Voltooide evenementen',
          })}
          value={data?.events_completed || 0}
          loading={loading}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title={t({
            id: 'reports.personal.events_total',
            message: 'Totaal aantal evenementen',
          })}
          value={data?.events_total || 0}
          loading={loading}
        />
      </Col>
    </Row>
    {user_id && (
      <UserEventsReport
        user_id={user_id}
        branch_id={branch_id}
        section_id={null}
        start={start}
        end={end}
        exportable="/api/export/personal/live-events"
      />
    )}
  </>
)
