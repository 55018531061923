import { authenticatedAxiosClient } from '../hooks/use-axios'

export const logActivity = async (any: unknown) => {
  if (!any) return
  const axios = authenticatedAxiosClient()
  return axios.post(`/api/activity`, any)
}

export const logEventActivity = async (any: unknown) => {
  if (!any) return
  const axios = authenticatedAxiosClient()
  return axios.post(`/api/activity/event`, any)
}

export const logArticleActivity = async (any: unknown) => {
  if (!any) return
  const axios = authenticatedAxiosClient()
  return axios.post(
    `${import.meta.env.NX_BACKEND_URL}/api/activity/article`,
    any
  )
}
