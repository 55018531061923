import { Trans } from '@lingui/macro'
import { Button, Modal, Progress } from 'antd'
import { useEffect, useRef, useState } from 'react'

import { useStream } from '../../../../contexts/StreamContext'

import { SurveyViewer } from './SurveyViewer'

export type SurveyViewerRef = {
  submit: () => void
}

export const SurveyModal = ({ isModalOpened }: { isModalOpened: boolean }) => {
  const quizRef = useRef<SurveyViewerRef>(null)
  const [current, setCurrent] = useState(0)
  const { survey, surveyActiveTimer, setSurveyModalVisibility } = useStream()

  const [surveyTimeLeft, setSurveyTimeLeft] = useState(surveyActiveTimer)

  useEffect(() => {
    let smoothTimer: NodeJS.Timeout
    let isActive = true

    if (isModalOpened) {
      setSurveyTimeLeft(surveyActiveTimer)
      smoothTimer = setInterval(() => {
        setSurveyTimeLeft((prevTime) => {
          if (!isActive) return prevTime
          const newTime = prevTime - 100
          return newTime > 0 ? newTime : 0
        })
      }, 100) // Update every 100ms for smoother animation
    }

    return () => {
      isActive = false
      if (smoothTimer) {
        clearInterval(smoothTimer)
        setSurveyTimeLeft(surveyActiveTimer)
      }
    }
  }, [isModalOpened, surveyActiveTimer])

  useEffect(() => {
    if (surveyTimeLeft === 0) {
      setSurveyModalVisibility(false)
    }
  }, [surveyTimeLeft, setSurveyModalVisibility])

  return (
    <Modal
      open={isModalOpened}
      closable={false}
      title={
        <small>
          {survey && current + 1 <= survey?.length ? (
            <Trans id="unit.viewer.quiz.title">
              Vraag {current + 1} van {survey?.length}
            </Trans>
          ) : (
            ''
          )}
        </small>
      }
      maskClosable={false}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      footer={
        <Progress
          percent={(surveyTimeLeft / surveyActiveTimer) * 100}
          showInfo={false}
          strokeColor={{
            '0%': '#2b21ad',
            '100%': '#2b21ad',
          }}
        />
      }
      width={600}
      centered
      style={{ top: 100 }}
    >
      <SurveyViewer
        ref={quizRef}
        questionIndex={current}
        onQuestionIndexChange={setCurrent}
        finishButton={
          <Button type="primary" onClick={() => quizRef.current?.submit()}>
            <Trans id="unit.viewer.quiz.submit">Antwoord indienen</Trans>
          </Button>
        }
      />
    </Modal>
  )
}
