import { useQuery } from '@apollo/client'
import '@livekit/components-styles'
import dayjs from 'dayjs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  LiveEventByCallIdQuery,
  MeQuery,
  UserProfile,
} from 'apps/lms-front/src/generated/graphql'

import { useAuth } from '../../../auth/hooks/use-auth'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { Header } from '../../../units/components/header'
import { Participant, StreamProvider } from '../../contexts/StreamContext'
import MEETING_DETAILS_QUERY from '../../queries/live-event-by-call-id.graphql'

import { JoinStreamContent } from './JoinStreamContent'

const isMeetingHost = (
  data?: LiveEventByCallIdQuery,
  user?: UserProfile | MeQuery['me'] | null
): boolean => {
  if (!user) return false

  const lecturerIds =
    data?.fetchLiveEventByCallId?.lecturers?.map((l) => l.user_id) || []
  const hostIds = data?.fetchLiveEventByCallId?.stream_details?.host_ids || []
  return new Set([...lecturerIds, ...hostIds]).has(user._id)
}

export const JoinStream = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuth()
  const { call_id } = useParams()

  const { data, loading } = useQuery<LiveEventByCallIdQuery>(
    MEETING_DETAILS_QUERY,
    {
      variables: { call_id },
      onError: (error) => {
        console.error('Failed to fetch meeting details:', error)
      },
      onCompleted: async (data) => {
        const isHost = isMeetingHost(data, user)

        if (isHost && location.state?.referrer !== 'prejoin') {
          return navigate(`/meet/${call_id}/join/`)
        }
      },
    }
  )

  if (!call_id || !data || loading) return <LoadScreen />

  const {
    _id: event_id,
    title,
    participants,
    stream_details,
    is_survey_available,
    survey,
    start,
    end,
  } = data?.fetchLiveEventByCallId || {}

  const duration = dayjs(end).diff(dayjs(start), 'milliseconds')

  return (
    <StreamProvider
      participants={(participants as Participant[]) ?? []}
      event_id={event_id}
      call_id={call_id}
      isMeetingHost={isMeetingHost(data, user) ?? false}
      attentionCheckEnabled={stream_details?.attention_check ?? false}
      survey={is_survey_available ? survey : []}
      duration={duration}
    >
      <Header
        title={title}
        onBack={() => navigate(`/library/calendar?event=${event_id}`)}
      />
      {loading ? <LoadScreen /> : <JoinStreamContent />}
    </StreamProvider>
  )
}
