/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */
import { DownOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { AnyAbility } from '@casl/ability'
import { Trans, t } from '@lingui/macro'
import { Checkbox, Slider, Tree } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { ObjectId } from 'bson'
import { usePostHog } from 'posthog-js/react'
import React, { Key, useEffect, useMemo, useState } from 'react'

import {
  CoursesFiltersQuery,
  // DistinctCourseDatePropertyQuery,
  BranchCoursesFiltersQuery,
  MeQuery,
  CoursesQuery,
  LanguagesQuery,
} from 'apps/lms-front/src/generated/graphql'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { useAuth } from '../../../auth/hooks/use-auth'
import { LoadSection } from '../../../core/components/LoadScreen'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { reduceDuplicateLabels } from '../../../shared/helpers/reduce-duplicate-labels'
import { SOFTWARE_TYPE_ID, useSoftware } from '../../hooks/use-software'

import LANGUAGES_QUERY from './../../../settings/queries/languages.graphql'
import BRANCH_COURSES_FILTERS_QUERY from './../../queries/branch-courses-filters.graphql'
import COURSES_FILTERS_QUERY from './../../queries/courses-filters.graphql'
// import DISTINCT_LAST_REVISION from './../../queries/distinct-course-date-prop.graphql'
import {
  ResetButton,
  Section,
  SectionTitle,
  StyledCollapse,
  Wrapper,
} from './CoursesFilters.style'

interface Props {
  query: string
  type: (string | null)[] | undefined | null
  category: (string | null)[] | undefined | null
  certificationType?: (string | null)[] | undefined | null
  onQueryChanged: (val: string) => void
  onTypeChanged: (val: string[]) => void
  onCategoryChanged: (val: string[]) => void
  onBranchCategoryChanged: (val: string[]) => void
  onCertificationTypeChanged?: (val: string[]) => void
  onLanguageChanged: (val: string[]) => void
  onLevelChanged: (val: string[]) => void
  onStatusChanged: (val: boolean | undefined) => void
  onRevisionChanged: (val: number[]) => void
  onDurationChanged: (val: [number, number]) => void
  permissions: {
    canReadCourseType: boolean
    canReadCourseCategory: boolean
  }
  user?: MeQuery['me']
  meta?: CoursesQuery['fetchCourses']['meta']
  ability?: AnyAbility
}

export const Filter = ({
  heading,
  children,
  expand,
  loading,
}: {
  heading: React.ReactNode
  children?: React.ReactNode
  expand?: boolean
  loading?: boolean
}) => (
  <Section style={{ paddingTop: 0, paddingBottom: 0 }}>
    <StyledCollapse
      expandIcon={({ isActive }) => (
        <DownOutlined rotate={isActive ? 180 : 0} />
      )}
      defaultActiveKey={expand ? ['0'] : []}
      ghost
    >
      <CollapsePanel key={0} header={heading}>
        {loading ? <LoadSection /> : children}
      </CollapsePanel>
    </StyledCollapse>
  </Section>
)

export const CoursesFilters = ({
  onQueryChanged,
  onTypeChanged,
  onCategoryChanged,
  onBranchCategoryChanged,
  onCertificationTypeChanged,
  onLevelChanged,
  onStatusChanged,
  onDurationChanged,
  onLanguageChanged,
  // onRevisionChanged,
  query,
  type = [],
  category = [],
  certificationType = [],
  meta,
  permissions,
}: Props) => {
  const { user } = useAuth()
  const { data: languages } = useQuery<LanguagesQuery>(LANGUAGES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  })
  const branch = useBranch()
  const posthog = usePostHog()

  const userCertificationTypes = user?.certificationType || []
  const [durationFilterVisible, setDurationFilterVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>(query)
  const [checkedCategories, setCheckedCategories] = useState<
    CheckboxValueType[]
  >(category?.filter(Boolean) as string[])

  const [checkedCertificationTypes, setCheckedCertificationTypes] = useState<
    CheckboxValueType[]
  >(certificationType?.filter(Boolean) as string[])

  const [checkedBranchCategories, setCheckedBranchCategories] = useState<
    CheckboxValueType[]
  >(category?.filter(Boolean) as string[])

  const [checkedLevels, setCheckedLevels] = useState<CheckboxValueType[]>([])
  const [checkedTypes, setCheckedTypes] = useState<CheckboxValueType[]>(
    type?.filter(Boolean) as string[]
  )
  const [checkedLanguages, setCheckedLanguages] = useState<CheckboxValueType[]>(
    user?.lang ? [user?.lang] : []
  )
  const [checkedStatuses, setCheckedStatuses] = useState<CheckboxValueType[]>(
    []
  )
  const [filteredDuration, setFilteredDuration] = useState<
    [number, number] | undefined
  >()
  // const [checkedRevisionDate, setCheckedRevisionDate] = useState<
  //   CheckboxValueType[]
  // >([])

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled('DurationFilter')) {
        setDurationFilterVisible(true)
      }
    })
  }, [posthog])

  const { active: softwareNMoreActive, types: softwareTypes } =
    useSoftware(type)

  const onDurationFilterChange = (durationRange: [number, number]) => {
    onDurationChanged(durationRange)
  }

  const onTypeFilterChange = (list: (CheckboxValueType | Key)[]) => {
    setCheckedTypes(list as CheckboxValueType[])
    onTypeChanged(list.map((item) => item.toString()))
  }
  const onLevelFilterChange = (list: CheckboxValueType[]) => {
    setCheckedLevels(list)
    onLevelChanged(list.map((item) => item.toString()))
  }
  const onStatusFilterChange = (list: CheckboxValueType[]): void => {
    setCheckedStatuses(list)
    onStatusChanged(
      list.length > 1 || list.length === 0 ? undefined : (list[0] as boolean)
    )
  }
  const onCertificationTypeFilterChange = (list: CheckboxValueType[]): void => {
    /**
     * We actually want to filter on the certification type name, not the id, because some branches might have different ids for the same certification type.
     */
    const selectedLabels = new Set(
      userCertificationTypes
        .filter((l) => list.includes(String(l?._id)))
        .map((type) => type.name)
    )

    const selectedTypes = userCertificationTypes.filter((t) =>
      selectedLabels.has(t.name)
    )
    const selectedIds = selectedTypes.map((t) => t._id)
    setCheckedCertificationTypes(selectedIds)
    onCertificationTypeChanged?.(selectedIds)
  }

  const onLanguageFilterChange = (list: CheckboxValueType[]): void => {
    setCheckedLanguages(list)
    onLanguageChanged(list as string[])
  }

  // const onRevisionDateChange = (list: CheckboxValueType[]): void => {
  //   setCheckedRevisionDate(list)
  //   onRevisionChanged(list as number[])
  // }

  const { data: coursesFilters, refetch: refetchFilters } =
    useQuery<CoursesFiltersQuery>(COURSES_FILTERS_QUERY, {
      fetchPolicy: 'cache-and-network',
      skip: !(
        permissions.canReadCourseType && permissions.canReadCourseCategory
      ),
    })

  const { data: branchCoursesFilters } = useQuery<BranchCoursesFiltersQuery>(
    BRANCH_COURSES_FILTERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip: !permissions.canReadCourseCategory,
    }
  )

  useEffect(() => {
    if (user?.lang) {
      setCheckedLanguages([user.lang])
      onLanguageChanged([user.lang])
      refetchFilters()
    }
  }, [user, onLanguageChanged, refetchFilters])

  // const { data: courseRevisionDate } =
  //   useQuery<DistinctCourseDatePropertyQuery>(DISTINCT_LAST_REVISION, {
  //     variables: {
  //       prop: 'meta.revision_date',
  //       datePeriod: 'YEAR',
  //     },
  //     fetchPolicy: 'cache-and-network',
  //     skip: true,
  //   })

  const buildCategoryTree = (
    categories:
      | CoursesFiltersQuery['fetchCategories']
      | BranchCoursesFiltersQuery['fetchBranchCategories'],
    meta?: { categoryFilters?: { value: string; count: number }[] }
  ) => {
    if (!categories?.length) return []

    const categoryMap = new Map<string, any>()
    const hasResultsMap = new Map<string, boolean>()

    // Step 1: Create nodes and track result counts
    categories.forEach((cat) => {
      const count =
        meta?.categoryFilters?.find((c) => c?.value === cat._id)?.count || 0

      const node = {
        title: cat.translation?.name || cat.name,
        key: cat._id,
        value: cat._id,
        children: [],
        count,
        hierarchy: cat.hierarchy,
      }

      categoryMap.set(cat._id, node)

      if (count > 0 && cat.hierarchy) {
        const ids = cat.hierarchy.split(',').filter(Boolean)
        ids.forEach((id) => hasResultsMap.set(id, true))
      }
    })

    // Step 2: Link child to parent
    categories.forEach((cat) => {
      const path = cat.hierarchy?.split(',').filter(Boolean)
      const parentId = path?.at(-2)

      if (parentId && categoryMap.has(parentId)) {
        const parent = categoryMap.get(parentId)
        const child = categoryMap.get(cat._id)
        parent.children.push(child)
      }
    })

    // Step 3: Detect true root nodes
    const allChildIds = new Set(
      categories.flatMap(
        (cat) => cat.hierarchy?.split(',').filter(Boolean).slice(1) || []
      )
    )

    const rootNodes = categories
      .filter((cat) => !allChildIds.has(cat._id))
      .map((cat) => categoryMap.get(cat._id))
      .filter(Boolean)

    // ✅ Step 4: Recursive prune — but capture the returned node (or null)
    const pruneTree = (node): any => {
      const prunedChildren = node.children
        .map(pruneTree)
        .filter((child) => child !== null)

      const hasOwnResults = hasResultsMap.get(node.value) || false

      if (hasOwnResults || prunedChildren.length > 0) {
        return {
          ...node,
          children: prunedChildren,
        }
      }

      return null // Prune this node
    }

    return rootNodes
      .map(pruneTree)
      .filter((node) => node !== null)
      .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
  }

  const getAllChildrenKeys = (node: any): string[] => {
    const keys: string[] = [String(node.key)]
    if (node.children) {
      node.children.forEach((child: any) => {
        keys.push(...getAllChildrenKeys(child))
      })
    }
    return keys
  }

  const categoryOptions = useMemo(() => {
    return buildCategoryTree(
      coursesFilters?.fetchCategories || [],
      meta
        ? {
            categoryFilters: meta.categoryFilters
              .filter(
                (filter): filter is NonNullable<typeof filter> =>
                  filter !== null
              )
              .map(({ value, count }) => ({ value, count })),
          }
        : undefined
    )
  }, [coursesFilters, meta])

  const onCategoryFilterChange = (
    _: Key[],
    event: { node: unknown; checked: unknown }
  ) => {
    const { node, checked } = event // Get clicked node and check status
    const newCheckedKeys = new Set(checkedCategories.map(String))

    if (checked) {
      // If checked, add all children keys
      getAllChildrenKeys(node).forEach((childKey) =>
        newCheckedKeys.add(String(childKey))
      )
    } else {
      // If unchecked, remove all children keys
      getAllChildrenKeys(node).forEach((childKey) =>
        newCheckedKeys.delete(String(childKey))
      )
    }

    const selectedKeysArray = [...newCheckedKeys]
    setCheckedCategories(selectedKeysArray)
    onCategoryChanged(selectedKeysArray)
  }

  const branchCategoryOptions = useMemo(() => {
    return buildCategoryTree(
      branchCoursesFilters?.fetchBranchCategories || [],
      meta
        ? {
            categoryFilters: meta.categoryFilters
              .filter(
                (filter): filter is NonNullable<typeof filter> =>
                  filter !== null
              )
              .map(({ value, count }) => ({ value, count })),
          }
        : undefined
    )
  }, [branchCoursesFilters, meta])

  const onBranchCategoryFilterChange = (
    _: Key[],
    event: { node: unknown; checked: unknown }
  ) => {
    const { node, checked } = event
    const newCheckedKeys = new Set(checkedBranchCategories.map(String))

    if (checked) {
      getAllChildrenKeys(node).forEach((childKey) =>
        newCheckedKeys.add(String(childKey))
      )
    } else {
      getAllChildrenKeys(node).forEach((childKey) =>
        newCheckedKeys.delete(String(childKey))
      )
    }

    const selectedKeysArray = [...newCheckedKeys]
    setCheckedBranchCategories(selectedKeysArray)
    onBranchCategoryChanged(selectedKeysArray)
  }

  const typeOptions = useMemo(() => {
    const _types = meta?.typeFilters
      ? coursesFilters?.fetchCourseTypes
          .filter((type) => !type.parent_id)
          .map((type) => {
            const count =
              meta?.typeFilters
                .filter(Boolean)
                .find((t) => t?.value === type._id)?.count || 0
            return {
              label: `${type.translation.name}`,
              value: type._id,
              count,
            }
          })
          .filter((type) => type.count > 0) || []
      : coursesFilters?.fetchCourseTypes
          .filter((type) => !type.parent_id)
          .map((type) => ({
            label: type.name,
            value: type._id,
          })) || []
    return _types || []
  }, [coursesFilters, meta])

  const typeTreeFilterOptions = useMemo(() => {
    return typeOptions
      .filter((type) => {
        const hideSoftware =
          branch?.disabledAllSoftware ||
          !user?.channels?.some((c) =>
            new ObjectId('6694dc12e2c2cb44f79ac63a').equals(c._id)
          ) // Software & More
        return !(
          ObjectId.isValid(type.value) &&
          new ObjectId(type.value as string).equals(SOFTWARE_TYPE_ID) &&
          hideSoftware
        )
      })
      .map((type) => ({
        key: type.value as string,
        title: type.label,
        checkable: true,
        children: new ObjectId(type.value as string).equals(SOFTWARE_TYPE_ID)
          ? softwareTypes.map((type) => ({
              key: type.name,
              title: type.translation || type.name,
              count: 1,
            }))
          : coursesFilters?.fetchCourseTypes
              .filter(
                (t) =>
                  t.parent_id &&
                  new ObjectId(String(t.parent_id)).equals(type.value)
              )
              .map((t) => ({
                key: `${type.value}.${t._id}`,
                title: t.translation.name,
                count: 1,
              })),
      }))
  }, [
    typeOptions,
    coursesFilters?.fetchCourseTypes,
    softwareTypes,
    branch,
    user?.channels,
  ])

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys)
    setAutoExpandParent(false)
  }

  return (
    <Wrapper>
      <Section>
        <SectionTitle>
          <Trans id="courses.search.title">Zoeken in opleidingen</Trans>
        </SectionTitle>
        <InputSearch
          placeholder={t({
            id: 'courses.search.placeholder',
            message: 'Zoekterm',
          })}
          onSearch={(query) => {
            onQueryChanged(query)
          }}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            if (e.target.value === '') {
              onQueryChanged(e.target.value)
            }
          }}
          value={searchTerm}
          style={{ width: '100%' }}
        />
      </Section>
      {user?.lang &&
        user.lang !==
          languages?.fetchLanguages.find((l) => l.default)?.code && (
          <Filter
            heading={t({
              id: 'courses.filter.language',
              message: 'Taal',
            })}
          >
            <Checkbox.Group
              className="vertical"
              options={languages?.fetchLanguages.map((lang) => ({
                label: lang.name,
                value: lang.code,
              }))}
              value={checkedLanguages}
              onChange={(lang) => onLanguageFilterChange(lang)}
            />
          </Filter>
        )}
      {typeOptions.length > 0 && (
        <Filter
          heading={t({
            id: 'courses.filter.type',
            message: 'Type',
          })}
          expand
          loading={!meta}
        >
          <Tree
            style={{ marginLeft: '-1.5rem' }}
            checkable
            switcherIcon={<></>}
            checkStrictly
            selectable={false}
            expandedKeys={checkedTypes as string[]}
            onCheck={(checked) => {
              if (!Array.isArray(checked))
                onTypeFilterChange([...checked.checked, ...checked.halfChecked])
            }}
            checkedKeys={checkedTypes.map((type) => type.toString())}
            treeData={typeTreeFilterOptions}
          />
        </Filter>
      )}
      {!!branchCategoryOptions?.length && !softwareNMoreActive && (
        <Filter
          heading={branch?.name || ''}
          loading={!meta}
          expand={!!category && category.length > 0}
        >
          <Tree
            style={{ marginLeft: '-22px' }}
            checkable
            selectable={false}
            checkedKeys={checkedBranchCategories.map(String)}
            onCheck={(checked, event) => {
              const checkedKeys = Array.isArray(checked)
                ? checked
                : checked.checked
              setExpandedKeys((prevKeys) => [...prevKeys, ...checkedKeys])
              onBranchCategoryFilterChange(checkedKeys.map(String), event)
            }}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onExpand={onExpand}
            treeData={branchCategoryOptions}
            checkStrictly={true} // Keeps check state independent
          />
        </Filter>
      )}
      {!!categoryOptions?.length && !softwareNMoreActive && (
        <Filter
          heading={t({
            id: 'courses.filter.category',
            message: 'Categorie',
          })}
          loading={!meta}
          expand={!!category && category.length > 0}
        >
          <Tree
            style={{ marginLeft: '-22px' }}
            checkable
            selectable={false}
            checkedKeys={checkedCategories.map(String)}
            onCheck={(checked, event) => {
              const checkedKeys = Array.isArray(checked)
                ? checked
                : checked.checked
              setExpandedKeys((prevKeys) => [...prevKeys, ...checkedKeys])
              onCategoryFilterChange(checkedKeys.map(String), event)
            }}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onExpand={onExpand}
            treeData={categoryOptions}
            checkStrictly={true} // Keeps check state independent
          />
        </Filter>
      )}
      {userCertificationTypes.length > 0 && !softwareNMoreActive && (
        <Filter
          heading={t({
            id: 'courses.filter.certification_type',
            message: 'Attest',
          })}
          expand={
            userCertificationTypes.length > 1 ||
            (!!certificationType && certificationType.length > 0)
          }
          loading={!meta}
        >
          <Checkbox.Group
            className="vertical"
            options={userCertificationTypes
              .reduce<
                {
                  name: string
                  _id: string
                  translation: { name: string }
                }[]
              >(reduceDuplicateLabels, [])
              .map((type) => ({
                label: type.translation?.name || type.name,
                value: type._id,
              }))}
            value={checkedCertificationTypes}
            onChange={onCertificationTypeFilterChange}
          />
        </Filter>
      )}
      {durationFilterVisible && !softwareNMoreActive && (
        <Filter
          heading={t({
            id: 'courses.filter.duration',
            message: 'Tijdsduur',
          })}
          loading={!meta}
        >
          <Slider
            range={{ draggableTrack: true }}
            tooltip={{
              formatter: (value?: number | undefined) => {
                if (value === 0) return `< 1 min`
                if (value === 180) return `> 3 uur`
                if (!value) return null
                if (value < 60) {
                  return `${value} min`
                } else if (value % 60 === 0) {
                  return `${value / 60} uur`
                }
                return `${Math.floor(value / 60)} uur ${value % 60} min`
              },
            }}
            marks={{
              0: { label: '0 min', style: { transform: 'translateX(-20%)' } },
              60: (
                <span>
                  1 <Trans id="courses.filter.duration.hour">uur</Trans>
                </span>
              ),
              120: (
                <span>
                  2 <Trans id="courses.filter.duration.hour">uur</Trans>
                </span>
              ),
              180: {
                style: {
                  textAlign: 'right',
                  whiteSpace: 'nowrap',
                  transform: 'translateX(-85%)',
                },
                label: (
                  <span>
                    3+ <Trans id="courses.filter.duration.hour">uur</Trans>
                  </span>
                ),
              },
            }}
            defaultValue={[0, 180]}
            value={filteredDuration}
            min={0}
            max={180}
            step={5}
            onChange={(val) => setFilteredDuration(val)}
            onAfterChange={onDurationFilterChange}
          />
        </Filter>
      )}
      {user?.lang &&
        user.lang ===
          languages?.fetchLanguages.find((l) => l.default)?.code && (
          <Filter
            heading={t({
              id: 'courses.filter.language',
              message: 'Taal',
            })}
          >
            <Checkbox.Group
              className="vertical"
              options={languages?.fetchLanguages.map((lang) => ({
                label: lang.name,
                value: lang.code,
              }))}
              value={checkedLanguages}
              onChange={(lang) => onLanguageFilterChange(lang)}
            />
          </Filter>
        )}
      {/* <Filter heading={'Niveau'}>
        <Checkbox.Group
          className="vertical"
          options={[
            { label: 'Starter', value: 'beginner' },
            { label: 'Gevorderd', value: 'advanced' },
            { label: 'Expert', value: 'expert' },
          ]}
          value={checkedLevels}
          onChange={onLevelFilterChange}
        />
      </Filter>
      <Filter heading={'Laatste revisie'}>
        <Checkbox.Group
          className="vertical"
          options={courseRevisionDate?.distinctCourseDateProperties.map(
            (date: number) => ({ label: date, value: date })
          )}
          value={checkedRevisionDate}
          onChange={onRevisionDateChange}
        />
      </Filter>
      <Filter heading={'Status'}>
        <Checkbox.Group
          className="vertical"
          options={[
            { label: 'Gepubliceerd', value: true },
            { label: 'Concept', value: false },
          ]}
          value={checkedStatuses}
          onChange={onStatusFilterChange}
        />
      </Filter> */}
      <Section>
        <ResetButton
          type="primary"
          disabled={
            (!searchTerm || searchTerm.length === 0) &&
            checkedCategories.length === 0 &&
            checkedLevels.length === 0 &&
            checkedStatuses.length === 0 &&
            checkedTypes.length === 0 &&
            checkedBranchCategories?.length === 0 &&
            checkedCertificationTypes.length === 0 &&
            checkedLanguages.length === 1 &&
            checkedLanguages[0] === user?.lang &&
            filteredDuration?.[0] === 0 &&
            filteredDuration?.[1] === 180
          }
          onClick={() => {
            setSearchTerm('')
            onQueryChanged('')
            setCheckedCategories([])
            onCategoryChanged([])
            setCheckedBranchCategories([])
            onBranchCategoryChanged([])
            onBranchCategoryFilterChange([], { node: [], checked: [] })
            onLevelFilterChange([])
            onTypeFilterChange([])
            onStatusFilterChange([])
            setFilteredDuration([0, 180])
            onDurationFilterChange([0, 180])
            onCertificationTypeFilterChange([])
            onLanguageFilterChange(user?.lang ? [user?.lang] : [])
          }}
        >
          <Trans id="courses.filter.reset">Reset alle filters</Trans>
        </ResetButton>
      </Section>
    </Wrapper>
  )
}
