import { Row, Col, Result } from 'antd'

export const StreamError = ({
  title,
  subTitle,
  children,
}: {
  title: string
  subTitle: string
  children?: React.ReactNode
}) => (
  <Row justify="center" style={{ marginTop: '50px' }}>
    <Col sm={12} lg={8}>
      <div
        style={{
          textAlign: 'center',
          background: '#fff',
          padding: '24px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Result status="error" title={title} subTitle={subTitle}>
          {children}
        </Result>
      </div>
    </Col>
  </Row>
)
