import {
  CheckOutlined,
  EllipsisOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Button, Dropdown, notification, Space } from 'antd'
import { ColumnType } from 'antd/lib/table/index'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'

import {
  LogActionEnum,
  StreamActionEnum,
} from '@lms-shared-patterns/enums/event.enums'
import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models/permission.model'
import {
  AddUserEventLogMutation,
  DeleteUserEventLogMutation,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { LoadSection } from '../../../core/components/LoadScreen'
import { ExportButton } from '../../../shared/components/export-button/ExportButton'
import { useAxios } from '../../../shared/hooks/use-axios'
import { dateRenderer } from '../../helpers/renderers'
import ADD_USER_EVENT_LOG_MUTATION from '../../mutations/add-user-event-log.graphql'
import DELETE_USER_EVENT_LOG_MUTATION from '../../mutations/delete-user-event-log.graphql'
import { Table } from '../Table'
import { UserCourseActivityQuizReport } from '../UserCourseActivityQuizReport'
import { UserEventSurveyReport } from '../UserEventSurveyReport'

import { AddLogModal } from './AddLogModal'

export const renderLocationType = (type: string) => {
  if (type === 'Physical') return t({ id: 'reports.fysiek', message: 'Fysiek' })
  if (type === 'Online') return t({ id: 'reports.online', message: 'Online' })
  return type
}

interface EventUserRecord {
  log?: Array<any>
  _id: string
  user_id: string
  name: string
  location_type: string
  joined?: Date
  completed?: Date
  activity?: {
    user_id: string
    quiz_answers?: Array<any>
    survey_answers?: Array<any>
  }
}

export const renderLogType = (type: string) => {
  if (type === StreamActionEnum.JOIN)
    return t({
      id: 'reports.users_report.table.log.joined',
      message: 'Heeft zich bij het online evenement ingelogd',
    })
  if (type === StreamActionEnum.LEAVE)
    return t({
      id: 'reports.users_report.table.log.left',
      message: 'Heeft het online evenement verlaten',
    })
  if (type === StreamActionEnum.PASSED_ATTENTION_CHECK)
    return t({
      id: 'reports.users_report.table.log.attention_check_passed',
      message: 'Heeft de aandachtstest met succes doorstaan',
    })
  if (type === StreamActionEnum.FAILED_ATTENTION_CHECK)
    return t({
      id: 'reports.users_report.table.log.attention_check_failed',
      message: 'Heeft de aandachtstest niet doorstaan',
    })
  if (type === StreamActionEnum.ANSWERED_SURVEY)
    return t({
      id: 'reports.users_report.table.log.answered_survey',
      message: 'Heeft de vragenlijst beantwoord',
    })
  if (type === StreamActionEnum.COMPLETED)
    return t({
      id: 'reports.users_report.table.log.completed',
      message: 'Heeft het evenement succesvol voltooid',
    })
  if (type === StreamActionEnum.ADMIN_MARKED_ATTENDANCE)
    return t({
      id: 'reports.users_report.table.log.admin_marked_attendance',
      message: 'Een admin bevestigde de aanwezigheid',
    })
  if (type === StreamActionEnum.ADMIN_CLEARED_ATTENDANCE)
    return t({
      id: 'reports.users_report.table.log.admin_cleared_attendance',
      message: 'Een admin heeft de gemarkeerde aanwezigheid ingetrokken',
    })
  if (type === StreamActionEnum.WAITING)
    return t({
      id: 'reports.users_report.table.log.waiting',
      message: 'Werd in de wachtruimte geplaatst',
    })
  if (type === StreamActionEnum.SCANNED_ONSITE_QR)
    return t({
      id: 'reports.users_report.table.log.scanned_onsite_qr',
      message: 'Heeft de QR-code ter plekke gescand',
    })
  if (type === StreamActionEnum.POST_QUIZ_COMPLETED)
    return t({
      id: 'reports.users_report.table.log.post_quiz_completed',
      message: 'Heeft de afsluitende toets voltooid',
    })

  return type
}

export const LiveEventUsersReport = ({
  event_id,
  section_id,
}: {
  event_id: string
  section_id: string | null | undefined
}) => {
  const ability = useContext(AbilityContext)
  const [{ data }, refetch] = useAxios({
    url: `/api/activity/live-events/${event_id}`,
    params: {
      section_id: section_id ?? undefined,
    },
  })
  const [upsertEventLog] = useMutation<AddUserEventLogMutation>(
    ADD_USER_EVENT_LOG_MUTATION
  )
  const [deleteEventLog] = useMutation<DeleteUserEventLogMutation>(
    DELETE_USER_EVENT_LOG_MUTATION
  )

  const [addLogModalVisible, setAddLogModalVisible] = useState(false)
  const [selectedAction, setSelectedAction] = useState<{
    action: LogActionEnum.ADD_ABOVE | LogActionEnum.ADD_BELOW
    user_id: string
    logEntry: any
    index: number
  } | null>(null)

  const canAccessSettings = ability.can(
    PermissionAction.UPDATE,
    PermissionObjectType.ACTIVITY
  )

  const handleManageLog = async (
    action: LogActionEnum,
    user_id: string,
    logEntry: any,
    index: number
  ) => {
    if (!canAccessSettings) return

    if (action === LogActionEnum.DELETE) {
      await deleteEventLog({
        variables: {
          event_id,
          input: {
            user_id,
            index,
          },
        },
        onError: (error) => {
          notification.error({
            message: error.message,
          })
        },
        onCompleted: async () => {
          await refetch()
          notification.success({
            message: t({
              id: 'reports.users_report.log.delete_success',
              message: 'Log succesvol verwijderd',
            }),
          })
        },
      })
    } else {
      setSelectedAction({ action, user_id, logEntry, index })
      setAddLogModalVisible(true)
    }
  }

  const handleAddLog = async (values: {
    date: Date
    type: StreamActionEnum
  }) => {
    if (!selectedAction) return

    await upsertEventLog({
      variables: {
        event_id,
        input: {
          user_id: selectedAction.user_id,
          action: selectedAction.action,
          index: selectedAction.index,
          date: values.date,
          type: values.type,
        },
      },
      onCompleted: async () => {
        setAddLogModalVisible(false)
        setSelectedAction(null)
        await refetch()

        notification.success({
          message: t({
            id: 'reports.users_report.log.add_success',
            message: 'Log succesvol toegevoegd',
          }),
        })
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        })
      },
    })
  }

  if (!data) return <LoadSection />

  return (
    <>
      <Table
        expandable={{
          expandedRowRender: (record: any) => (
            <Table
              size="small"
              dataSource={record.log || []}
              pagination={false}
              rowKey={(logEntry: any) => `${record._id}-${logEntry.date}`}
              expandable={{
                expandedRowRender: (logEntry: any) => {
                  const isQuizCompleted =
                    logEntry.type === StreamActionEnum.POST_QUIZ_COMPLETED
                  const isSurveyAnswered =
                    logEntry.type === StreamActionEnum.ANSWERED_SURVEY

                  if (isQuizCompleted) {
                    return (
                      <UserCourseActivityQuizReport
                        questions={record.post_event_quiz || []}
                        answers={record.activity?.quiz_answers || []}
                      />
                    )
                  }

                  if (isSurveyAnswered) {
                    return (
                      <UserEventSurveyReport
                        questions={record.questions || []}
                        answers={record.activity?.survey_answers || []}
                      />
                    )
                  }

                  return null
                },
                expandRowByClick: true,
                rowExpandable: (logEntry: any) => {
                  const isQuizCompleted =
                    logEntry.type === StreamActionEnum.POST_QUIZ_COMPLETED
                  const isSurveyAnswered =
                    logEntry.type === StreamActionEnum.ANSWERED_SURVEY

                  return (
                    (isQuizCompleted &&
                      record.activity?.quiz_answers?.length > 0) ||
                    (isSurveyAnswered &&
                      record.activity?.survey_answers?.length > 0)
                  )
                },
              }}
              columns={[
                {
                  title: t({
                    id: 'reports.users_report.table.log.time',
                    message: 'Tijd',
                  }),
                  dataIndex: 'date',
                  key: 'date',
                  render: (date) => dayjs(date).format('DD/MM/YYYY - HH:mm:ss'),
                },
                {
                  title: t({
                    id: 'reports.users_report.table.log',
                    message: 'Log',
                  }),
                  dataIndex: 'type',
                  key: 'type',
                  render: (type) => renderLogType(type),
                },
                {
                  key: 'actions',
                  width: 50,
                  render: (_, logEntry, index) =>
                    canAccessSettings ? (
                      <Dropdown
                        placement="bottomRight"
                        menu={{
                          items: [
                            {
                              key: 'add-above',
                              label: t({
                                id: 'reports.users_report.table.log.add_above',
                                message: 'Rij toevoegen boven',
                              }),
                              onClick: () =>
                                handleManageLog(
                                  LogActionEnum.ADD_ABOVE,
                                  record.activity?.user_id || '',
                                  logEntry,
                                  index
                                ),
                            },
                            {
                              key: 'add-below',
                              label: t({
                                id: 'reports.users_report.table.log.add_below',
                                message: 'Rij toevoegen onder',
                              }),
                              onClick: () =>
                                handleManageLog(
                                  LogActionEnum.ADD_BELOW,
                                  record.activity?.user_id || '',
                                  logEntry,
                                  index
                                ),
                            },
                            {
                              type: 'divider',
                            },
                            {
                              key: 'delete',
                              label: t({
                                id: 'reports.users_report.table.log.delete',
                                message: 'Log verwijderen',
                              }),
                              danger: true,
                              onClick: () =>
                                handleManageLog(
                                  LogActionEnum.DELETE,
                                  record.activity?.user_id || '',
                                  logEntry,
                                  index
                                ),
                            },
                          ],
                        }}
                        trigger={['click']}
                      >
                        <Button
                          type="text"
                          icon={<EllipsisOutlined />}
                          size="small"
                        />
                      </Dropdown>
                    ) : null,
                },
              ]}
            />
          ),
          expandRowByClick: true,
          rowExpandable: (record: any) => record.log?.length > 0,
        }}
        rowKey="_id"
        columns={[
          {
            title: t({
              id: 'reports.users_report.table.user',
              message: 'Gebruiker',
            }),
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: t({
              id: 'reports.users_report.table.location_type',
              message: 'Type',
            }),
            dataIndex: 'location_type',
            key: 'location_type',
            render: (type) => renderLocationType(type),
          },
          {
            title: t({
              id: 'reports.users_report.table.attended',
              message: 'Aanwezig',
            }),
            dataIndex: 'joined',
            key: 'joined',
            render: (date) => (date ? <CheckOutlined /> : '-'),
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.users_report.table.completed',
              message: 'Voltooid op',
            }),
            dataIndex: 'completed',
            key: 'completed',
            render: dateRenderer,
            align: 'center' as const,
          },
          ...((canAccessSettings
            ? [
                {
                  title: t({
                    id: 'reports.users_report.table.actions',
                    message: 'Acties',
                  }),
                  align: 'center' as const,
                  key: 'actions',
                  width: 100,
                  render: (_: unknown, record: EventUserRecord) => {
                    return canAccessSettings && !record.log?.length ? (
                      <Button
                        type="text"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          setSelectedAction({
                            action: LogActionEnum.ADD_BELOW,
                            user_id: record.user_id,
                            logEntry: null,
                            index: 0,
                          })
                          setAddLogModalVisible(true)
                        }}
                      >
                        {t({
                          id: 'reports.users_report.table.add_log',
                          message: 'Log toevoegen',
                        })}
                      </Button>
                    ) : null
                  },
                },
              ]
            : []) as ColumnType<any>[]),
        ]}
        dataSource={data}
        pagination={false}
        footer={() => {
          return (
            <div style={{ textAlign: 'right' }}>
              <Space>
                <span>
                  <Trans id="reports.live_events_report.action.export">
                    Exporteer naar:
                  </Trans>{' '}
                </span>
                {['xlsx', 'csv', 'json'].map((type, index) => (
                  <ExportButton
                    key={index}
                    url={`/api/export/live-event/${event_id}`}
                    type={type}
                    params={{
                      section_id,
                    }}
                    name="live-event"
                    dates={['completed', 'event_date', 'joined_at']}
                  />
                ))}
              </Space>
            </div>
          )
        }}
      />
      {canAccessSettings && (
        <AddLogModal
          open={addLogModalVisible}
          initialDate={selectedAction?.logEntry?.date}
          onCancel={() => {
            setAddLogModalVisible(false)
            setSelectedAction(null)
          }}
          onSubmit={handleAddLog}
        />
      )}
    </>
  )
}
