import { DownOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Col, Empty, Row, Space } from 'antd'
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  Article,
  ArticlesQuery,
  Image,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { LoadScreen, LoadSection } from '../../../core/components/LoadScreen'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { ArticleCard } from '../../components/article-card/ArticleCard'
import ARTICLES_QUERY from '../../queries/articles.graphql'

const LIMIT = 9 // Number of articles to load per request

export const ArticleOverview = () => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(1)
  const [loadingPage, setLoadingPage] = useState<number | undefined>(1)
  const [articles, setArticles] = useState<Article[]>([])
  const [totalPages, setTotalPages] = useState(1)

  const { data, loading, fetchMore } = useQuery<ArticlesQuery>(ARTICLES_QUERY, {
    variables: { filter: { query: searchTerm, limit: LIMIT, page } },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setArticles((prev: Article[]) => [
        ...prev,
        ...(data.fetchArticles.results as Article[]),
      ])
      setTotalPages(Math.ceil(data.fetchArticles.count / LIMIT))
      setLoadingPage(undefined)
    },
  })

  const loadMoreArticles = useCallback(
    (page: number) => {
      setLoadingPage(page)
      fetchMore({
        variables: { filter: { page } },
      })
    },
    [setLoadingPage, fetchMore]
  )

  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement

    // Trigger load more when user is 300px or less from the bottom

    if (scrollTop + clientHeight >= scrollHeight - 200) {
      if (loadingPage === page || page >= totalPages) return
      setPage((prev) => prev + 1)
      loadMoreArticles(page + 1)
    }
  }, [loadMoreArticles, page, loadingPage, totalPages])

  useEffect(() => {
    const handleScrollDebounced = debounce(handleScroll, 100)
    window.addEventListener('scroll', handleScrollDebounced)
    return () => window.removeEventListener('scroll', handleScrollDebounced)
  }, [handleScroll])

  return (
    <>
      <Row>
        <div style={{ marginLeft: 'auto', marginBottom: 24 }}>
          <Space>
            <InputSearch
              key="1"
              placeholder={t({
                id: 'articles.search',
                message: 'Zoeken',
              })}
              onSearch={(value) => {
                setPage(1)
                setArticles([])
                setSearchTerm(value)
              }}
              style={{ width: 200 }}
            />
            <Can
              I={PermissionAction.CREATE}
              a={PermissionObjectType.ARTICLE}
              key="2"
            >
              <DropdownButton
                icon={<DownOutlined />}
                type="primary"
                menu={{
                  onClick: ({ key }) => navigate(key),
                  items: [
                    {
                      disabled: true,
                      key: 'import',
                      label: t({
                        id: 'articles.overview.action.import',
                        message: 'Berichten importeren',
                      }),
                    },
                  ],
                }}
              >
                <Link className={'btn-link'} to={'/articles/new'}>
                  <Trans id="articles.overview.action.create">
                    Bericht aanmaken
                  </Trans>
                </Link>
              </DropdownButton>
            </Can>
          </Space>
        </div>
      </Row>
      <Row align={'stretch'} style={{ margin: -8 }}>
        {!data && (
          <Col span={24}>
            <LoadScreen />
          </Col>
        )}
        {articles.length === 0 && (
          <Col span={24}>
            <Empty
              style={{ paddingTop: 24 }}
              description={t({
                id: 'articles.empty',
                message: 'Geen artikels gevonden.',
              })}
            />
          </Col>
        )}
        {articles.map((article) => (
          <Col
            key={article._id}
            span={24}
            sm={{ span: 12 }}
            lg={{ span: 8 }}
            style={{ padding: 8 }}
          >
            <ArticleCard
              title={article.translation.title || undefined}
              date={article.published}
              time={article.readingTime}
              user={
                article.author
                  ? {
                      name: `${article.author.firstName} ${article.author.lastName}`,
                      avatar: article.author.picture?.url,
                    }
                  : { name: article.externalAuthor?.name || '' }
              }
              image={article.thumbnail as Image}
              url={`/articles/${encodeURIComponent(article.slug)}`}
            >
              {article.translation.intro}
            </ArticleCard>
          </Col>
        ))}
      </Row>
      {loading && (
        <div style={{ marginTop: 24 }}>
          <LoadSection />
        </div>
      )}
    </>
  )
}
