import { t } from '@lingui/macro'
import { Statistic, Row, Col } from 'antd'
import React from 'react'

import { UserPathsReport } from '../../../components/UserPathsReport'

interface PathData {
  paths_in_progress?: number
  paths_completed?: number
  paths_total?: number
}

interface PathsTabProps {
  data: PathData
  loading: boolean
  user_id: string
  branch_id?: string
  hierarchyFilter: string | null | undefined
  start?: string
  end?: string
}

export const PathsTab: React.FC<PathsTabProps> = ({
  data,
  loading,
  user_id,
  branch_id,
  hierarchyFilter,
  start,
  end,
}) => (
  <>
    <Row gutter={16} style={{ textAlign: 'center', marginBottom: 16 }}>
      <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
        <Statistic
          title={t({
            id: 'reports.personal.paths_in_progress',
            message: 'Actieve leerpaden',
          })}
          value={data?.paths_in_progress || 0}
          loading={loading}
        />
      </Col>
      <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
        <Statistic
          title={t({
            id: 'reports.personal.paths_completed',
            message: 'Voltooide leerpaden',
          })}
          value={data?.paths_completed || 0}
          loading={loading}
        />
      </Col>
      <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
        <Statistic
          title={t({
            id: 'reports.personal.paths_total',
            message: 'Totaal aantal leerpaden',
          })}
          value={data?.paths_total || 0}
          loading={loading}
        />
      </Col>
    </Row>
    {user_id && (
      <UserPathsReport
        section_id={hierarchyFilter}
        user_id={user_id}
        branch_id={branch_id}
        start={start || undefined}
        end={end || undefined}
        exportable="/api/export/personal/paths"
      />
    )}
  </>
)
