import { EventLocationType } from '@lms-shared-patterns/enums/event.enums'
import { Participant } from 'apps/lms-front/src/generated/graphql'

export const sortParticipantList = (a: Participant, b: Participant) => {
  // Helper function to determine if a registration is pending
  const isPending = (participant: Participant) => {
    const reg = participant.registration
    return !reg.approved && !reg.denied && !reg.cancelled
  }

  // Helper function to determine if a registration is approved and active
  const isApprovedActive = (participant: Participant) => {
    const reg = participant.registration
    return reg.approved && !reg.cancelled && !reg.denied
  }

  // Get registration status priority (higher number = higher priority)
  const getStatusPriority = (participant: Participant): number => {
    const reg = participant.registration

    if (reg.lecturer) return 7 // Lecturers always have highest priority
    if (reg.host) return 6 // Hosts have second highest priority

    if (isPending(participant)) {
      // For pending registrations, prioritize physical over online
      if (reg.type === EventLocationType.Physical) return 6
      if (reg.type === EventLocationType.Online) return 5
      return 5 // Default for other pending types
    }

    if (isApprovedActive(participant)) {
      if (reg.type === EventLocationType.Physical) return 4
      if (reg.type === EventLocationType.Online) return 3
      return 3 // Default for other approved types
    }

    if (reg.denied) return 2
    if (reg.cancelled) return 1
    return 0
  }

  // Compare based on priority
  const priorityA = getStatusPriority(a)
  const priorityB = getStatusPriority(b)

  return priorityB - priorityA
}
