import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  BranchCategoriesQuery,
  CategoryTranslation,
  SettingCategoriesQuery,
} from 'apps/lms-front/src/generated/graphql'

import CATEGORIES_QUERY from '../../settings/queries/categories.graphql'
import BRANCH_CATEGORIES_QUERY from '../queries/branch-categories.graphql'

export type CategoryTreeNode = {
  id: string
  key: string
  pId?: string
  value: string
  title: string
  label: string
  language: string
  branch_id: string
  level: number
  disabled?: boolean
  isLeaf?: boolean
  children: CategoryTreeNode[]
  hierarchy?: string | null
  translations?: Array<CategoryTranslation> | null
}

export const useCategoriesTree = () => {
  const { data, loading, refetch } = useQuery<SettingCategoriesQuery>(
    CATEGORIES_QUERY,
    { fetchPolicy: 'cache-and-network' }
  )

  const treeData = useMemo(() => {
    if (!data?.fetchCategories) return []
    return convertCategoriesToTree(data.fetchCategories)
  }, [data])

  return { data: treeData, loading, refetch }
}

export const useBranchCategoriesTree = () => {
  const { data, loading, refetch } = useQuery<BranchCategoriesQuery>(
    BRANCH_CATEGORIES_QUERY,
    { fetchPolicy: 'cache-and-network' }
  )

  const treeData = useMemo(() => {
    if (!data?.fetchBranchCategories) return []
    return convertCategoriesToTree(data.fetchBranchCategories)
  }, [data])

  return { data: treeData, loading, refetch }
}

const convertCategoriesToTree = (
  categories:
    | BranchCategoriesQuery['fetchBranchCategories']
    | SettingCategoriesQuery['fetchCategories']
) => {
  const map = new Map<string, CategoryTreeNode>()

  categories.forEach((category) => {
    const hierarchyParts = category.hierarchy?.split(',').filter(Boolean)
    if (!hierarchyParts) return
    const parentId = hierarchyParts.at(-2) || null
    const level = hierarchyParts.length

    map.set(category._id, {
      id: category._id,
      key: category._id,
      value: category._id,
      title: category.name,
      language: category.language,
      label: category.name,
      branch_id: category.branch_id,
      pId: parentId || undefined,
      level,
      isLeaf: true,
      children: [],
      hierarchy: category.hierarchy,
      translations: category.translations,
    })
  })

  const tree: CategoryTreeNode[] = []
  map.forEach((node) => {
    if (node.pId && map.has(node.pId)) {
      map.get(node.pId)!.children!.push(node)
      map.get(node.pId)!.isLeaf = false
    } else {
      tree.push(node)
    }
  })

  return tree
}
