import { t, Trans } from '@lingui/macro'
import { Drawer, Select } from 'antd'

export function BackgroundController({
  virtualBackgroundDrawerOpened,
  setVirtualBackgroundDrawerOpened,
  virtualBackground,
  onVirtualBackgroundChange,
}: {
  virtualBackgroundDrawerOpened: boolean
  setVirtualBackgroundDrawerOpened: (value: boolean) => void
  virtualBackground: string
  onVirtualBackgroundChange: (value: string) => void
}) {
  return (
    <Drawer
      open={virtualBackgroundDrawerOpened}
      maskStyle={{
        opacity: 0,
      }}
      title={t({
        id: 'stream.background-fx',
        message: 'Achtergrondeffecten',
      })}
      onClose={() => setVirtualBackgroundDrawerOpened(false)}
    >
      <p>
        <Trans id="stream.background-fx.select-effect">
          Selecteer een achtergrond effect:
        </Trans>
      </p>
      <Select
        style={{ width: '100%' }}
        value={virtualBackground}
        onChange={(value) => {
          if (value === 'custom') {
            onVirtualBackgroundChange(prompt('Voer een URL in') || 'default')
          } else {
            onVirtualBackgroundChange(value)
          }
        }}
        options={[
          {
            label: <Trans id="stream.background-fx.default">Geen effect</Trans>,
            value: 'default',
          },
          {
            label: (
              <>
                <Trans id="stream.background-fx.blur">Wazig</Trans>
                &nbsp;(5%)
              </>
            ),
            value: 'blur-5',
          },
          {
            label: (
              <>
                <Trans id="stream.background-fx.blur">Wazig</Trans>
                &nbsp;(10%)
              </>
            ),
            value: 'blur-10',
          },
          {
            label: (
              <>
                <Trans id="stream.background-fx.blur">Wazig</Trans>
                &nbsp;(25%)
              </>
            ),
            value: 'blur-25',
          },
          {
            label: (
              <>
                <Trans id="stream.background-fx.blur">Wazig</Trans>
                &nbsp;(50%)
              </>
            ),
            value: 'blur-50',
          },
          {
            label: <Trans id="stream.background-fx.office">Kantoor</Trans>,
            value: 'office',
          },
          {
            label: (
              <Trans id="stream.background-fx.custom">
                Eigen afbeelding (via URL)
              </Trans>
            ),
            value: 'custom',
          },
        ]}
      />
    </Drawer>
  )
}
