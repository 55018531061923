import {
  CheckCircleOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Badge,
  Button,
  Col,
  Empty,
  Pagination,
  Row,
  Segmented,
  Select,
  Space,
} from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  FetchPendingPathApprovalsQuery,
  LanguagesQuery,
  PathsQuery,
  PathStatus,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext, Can } from '../../auth/components/Can'
import { useAuth } from '../../auth/hooks/use-auth'
import { LoadScreen } from '../../core/components/LoadScreen'
import { DropdownButton } from '../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../shared/components/input-search/InputSearch'
import { LearningPathCard } from '../components/LearningPathCard'
import { PendingPathsApprovalsModal } from '../components/PendingPathsApprovalsModal'
import { shortenLanguageCode } from '../helpers/shorten-lang-code'

import LANGUAGES_QUERY from './../../settings/queries/languages.graphql'
import PATHS_QUERY from './../queries/paths.graphql'
import PENDING_APPROVALS_QUERY from './../queries/pending-path-approvals.graphql'
import { Content, LPPaginationWrapper } from './LearningPathOverview.style'
interface PathsFilter {
  query?: string
  page: number
  limit: number
  status?: PathStatus
  languages?: Array<string>
}

export const LearningPathOverview = () => {
  const ability = useContext(AbilityContext)
  const navigate = useNavigate()
  const [query, setQuery] = useState<string>('')
  const { data: languages } = useQuery<LanguagesQuery>(LANGUAGES_QUERY)
  const { user } = useAuth()
  const [isApprovalModalVisible, setIsApprovalModalVisible] = useState(false)

  const { data: pendingPathApprovalsData, loading: pendingApprovalsLoading } =
    useQuery<FetchPendingPathApprovalsQuery>(PENDING_APPROVALS_QUERY, {
      skip: !ability.can(
        PermissionAction.UPDATE,
        PermissionObjectType.BRANCH_LIVE_EVENT_REGISTRATION
      ),
    })

  const totalPendingApprovals = useMemo(() => {
    if (!pendingPathApprovalsData?.fetchPendingPathApprovals) return 0

    return pendingPathApprovalsData.fetchPendingPathApprovals.reduce(
      (total, group) => total + group.participants.length,
      0
    )
  }, [pendingPathApprovalsData])

  const [filter, setFilter] = useState<PathsFilter>(() => {
    const storedFilter = localStorage.getItem('aa_learning_path_filter')
    if (storedFilter) {
      return JSON.parse(storedFilter)
    }
    return {
      query: '',
      page: 1,
      limit: 12,
      status: PathStatus.Active,
      languages: user?.lang ? [user.lang] : ['nl-BE'],
    }
  })

  useEffect(() => {
    localStorage.setItem('aa_learning_path_filter', JSON.stringify(filter))
  }, [filter])

  useEffect(() => {
    setQuery(filter.query || '')
  }, [filter.query])

  const { data, loading } = useQuery<PathsQuery>(PATHS_QUERY, {
    variables: {
      filter,
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleStatusChange = (status: PathStatus) => {
    setFilter((prev) => ({ ...prev, status }))
  }

  const handleLanguageChange = (values: Array<string>) => {
    setFilter((prev) => ({ ...prev, languages: values }))
  }

  const setPage = (page: number) => {
    setFilter((prev) => ({ ...prev, page: page }))
  }

  const setFilterQuery = (q: string) => {
    setFilter((prev) => ({ ...prev, query: q }))
  }

  return (
    <>
      <Content>
        <Row>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 12,
              width: '100%',
            }}
          >
            <Space wrap style={{ lineHeight: 1 }}>
              <Segmented
                options={[
                  {
                    label: (
                      <Trans id="learning_paths.filter.overview">
                        Overzicht
                      </Trans>
                    ),
                    value: PathStatus.Active,
                  },
                  {
                    label: (
                      <Trans id="learning_paths.filter.my_registered_learning_paths">
                        Mijn ingeschreven leerpaden
                      </Trans>
                    ),
                    value: PathStatus.Registered,
                  },
                  {
                    label: (
                      <Trans id="learning_paths.filter.archived">Archief</Trans>
                    ),
                    value: PathStatus.Archived,
                  },
                ]}
                onChange={(value) => handleStatusChange(value as PathStatus)}
                value={filter.status}
              />
              <Select
                placeholder={t({
                  id: 'learning_paths.filter.languages',
                  message: 'Taal',
                })}
                style={{ width: 180 }}
                value={filter.languages}
                onChange={handleLanguageChange}
                defaultValue={user?.lang ? [user.lang] : ['nl-BE']}
                mode="multiple"
              >
                {languages?.fetchLanguages.map((language) => (
                  <Select.Option key={language.code} value={language.code}>
                    {shortenLanguageCode(language.code)}
                  </Select.Option>
                ))}
              </Select>
            </Space>
            <Space>
              <InputSearch
                placeholder={t({
                  id: 'learning_paths.search.placeholder',
                  message: 'Zoekterm',
                })}
                onChange={(e) => {
                  setQuery(e.target.value)
                  if (e.target.value === '') {
                    setFilterQuery(e.target.value)
                  }
                }}
                onSearch={(query) => {
                  setFilterQuery(query)
                }}
                value={query}
                style={{ width: '100%' }}
              />
              <Can
                I={PermissionAction.CREATE}
                a={PermissionObjectType.LEARNING_PATH}
                key="2"
              >
                <DropdownButton
                  icon={<DownOutlined />}
                  type="primary"
                  menu={{
                    hidden: !(
                      ability.can(
                        PermissionAction.IMPORT,
                        PermissionObjectType.LEARNING_PATH
                      ) ||
                      ability.can(
                        PermissionAction.IMPORT,
                        PermissionObjectType.BRANCH_LEARNING_PATH
                      )
                    ),
                    onClick: ({ key }) => navigate(key),
                  }}
                >
                  <Link className={'btn-link'} to={'/paths/add'}>
                    <Space>
                      <PlusOutlined />
                      <Trans id="learning_paths.action.create">
                        Leerpad aanmaken
                      </Trans>
                    </Space>
                  </Link>
                </DropdownButton>
              </Can>
              <Can
                I={PermissionAction.UPDATE}
                a={PermissionObjectType.BRANCH_LEARNING_PATH_PARTICIPANTS}
                key="3"
              >
                <Badge
                  count={totalPendingApprovals}
                  offset={[-2, 2]}
                  size="small"
                >
                  <Button
                    type="default"
                    onClick={() => setIsApprovalModalVisible(true)}
                    icon={<CheckCircleOutlined />}
                  />
                </Badge>
              </Can>
            </Space>
          </div>
        </Row>

        <Row gutter={[16, 16]} align={'stretch'}>
          {loading ? (
            <Col span={24}>
              <LoadScreen />
            </Col>
          ) : data?.fetchLearningPaths.results?.length === 0 ? (
            <Col span={24}>
              <Empty
                imageStyle={{
                  height: 200,
                }}
                description={t({
                  id: 'learning_paths.empty',
                  message: 'Geen leerpaden gevonden.',
                })}
              />
            </Col>
          ) : (
            data?.fetchLearningPaths.results?.map((path) => (
              <LearningPathCard key={path._id} path={path} />
            ))
          )}
        </Row>
        <Row gutter={[16, 16]} align={'stretch'}>
          <Col span={24}>
            {data?.fetchLearningPaths &&
              data?.fetchLearningPaths.count >
                data.fetchLearningPaths?.results.length && (
                <LPPaginationWrapper>
                  <Pagination
                    current={filter.page || 1}
                    onChange={(page) => setPage(page)}
                    total={data?.fetchLearningPaths.count}
                  />
                </LPPaginationWrapper>
              )}
          </Col>
        </Row>
      </Content>
      <PendingPathsApprovalsModal
        approvalModalVisible={isApprovalModalVisible}
        setIsApprovalModalVisible={setIsApprovalModalVisible}
        data={pendingPathApprovalsData}
        loading={pendingApprovalsLoading}
      />
    </>
  )
}
