import { UploadOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Col,
  message,
  notification,
  PageHeader,
  Progress,
  Row,
  Steps,
  Table,
  Upload,
} from 'antd'
import { RcFile } from 'antd/lib/upload'
import { parse as papaparse } from 'papaparse'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CertificationTypesQuery,
  CreateUserMutation,
  UpdateUserMutation,
  UserRolesQuery,
} from 'apps/lms-front/src/generated/graphql'
import { PageProps } from 'apps/lms-front/src/modules/shared/interfaces/page.interface'

import { Content } from '../../../../shared/layout/Layout.style'
import { arraysEqual } from '../../../../shared/utils/arrays-equal'

import CERTIFICATIONS_QUERY from './../../../../settings/queries/certification-types.graphql'
import USER_ROLES_QUERY from './../../../../settings/queries/user-roles.graphql'
import CREATE_USER_MUTATION from './../../../mutations/create-user.graphql'
import UPDATE_USER_MUTATION from './../../../mutations/update-user.graphql'
import { StepContent } from './ImportBranchUsers.style'

const { Step } = Steps

interface BranchUserImport {
  firstName: string
  lastName: string
  email: string
  password: string
  certification: string
  itaaNo: string
}

export const ImportBranchUsers = ({ route }: PageProps) => {
  const navigate = useNavigate()
  const client = useApolloClient()

  const [fileList, setFileList] = useState<RcFile[]>()
  const [dataPreview, setDataPreview] = useState<[BranchUserImport]>()
  const [current, setCurrent] = useState(0)
  const [rows, setRows] = useState(0)
  const [processingRow, setProcessingRow] = useState(0)
  const [processing, setProcessing] = useState(false)

  const [createUser] = useMutation<CreateUserMutation>(CREATE_USER_MUTATION)
  const [updateUser] = useMutation<UpdateUserMutation>(UPDATE_USER_MUTATION)
  const { data: roles } = useQuery<UserRolesQuery>(USER_ROLES_QUERY)
  const { data: certifications } =
    useQuery<CertificationTypesQuery>(CERTIFICATIONS_QUERY)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const parse = (file: RcFile, limit?: number) =>
    new Promise((resolve, reject) => {
      papaparse(file, {
        complete: (results) => resolve(results.data),
        error: (error) => reject(error),
        header: limit === 1 ? false : true,
        preview: limit,
        skipEmptyLines: true,
      })
    })

  useEffect(() => {
    const parseCSV = async () => {
      if (fileList && fileList.length > 0) {
        const parsedDataPreview = await parse(fileList?.[0], 5)
        setDataPreview(parsedDataPreview as [BranchUserImport])
      }
    }
    parseCSV()
  }, [fileList])

  useEffect(() => {
    if (current === 2) {
      const reader = new FileReader()
      if (fileList && fileList.length > 0) {
        reader.readAsText(fileList?.[0])
        reader.addEventListener('load', (event) => {
          const csv = (event.target as FileReader).result
          const csvArray = (csv as string).split('\n')
          setRows(csvArray.length)
        })
      }
    }
  }, [current, fileList])

  useEffect(() => {
    const parseHeader = async () => {
      if (fileList && fileList.length > 0) {
        const header = await parse(fileList?.[0], 1)
        if (
          !arraysEqual((header as string[][])[0], [
            'firstName',
            'lastName',
            'email',
            'password',
            'certification',
            'itaaNo',
          ])
        ) {
          message.error('Dit bestand heeft een foute structuur')
          setFileList([])
        }
      }
    }
    parseHeader()
  }, [fileList])

  const columns = [
    {
      title: t({
        id: 'settings.users.import.table.first_name',
        message: 'Voornaam',
      }),
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: t({
        id: 'settings.users.import.table.last_name',
        message: 'Naam',
      }),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: t({
        id: 'settings.users.import.table.email',
        message: 'E-mailadres',
      }),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t({
        id: 'settings.users.import.table.password',
        message: 'Wachtwoord',
      }),
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: t({
        id: 'settings.users.import.table.certification',
        message: 'Certificeringstype',
      }),
      dataIndex: 'certification',
      key: 'certification',
    },
    {
      title: t({
        id: 'settings.users.import.table.itaa_no',
        message: 'ITAA-nr',
      }),
      dataIndex: 'itaaNo',
      key: 'itaaNo',
    },
  ]

  const steps = [
    {
      title: t({
        id: 'settings.users.import.step.upload',
        message: 'Uploaden',
      }),
      content: (
        <StepContent>
          <h3>
            <Trans id="settings.users.import.step.upload.title">
              Bestand uploaden
            </Trans>
          </h3>
          <p>
            <Trans id="settings.users.import.step.upload.description">
              Selecteer een passend CSV-bestand met dezelfde structuur als ons{' '}
              <a href="/examples/branch-user-import.csv" target="_blank">
                voorbeeld
              </a>
              .
            </Trans>
          </p>
          <Upload
            fileList={fileList}
            beforeUpload={(file) => setFileList([file])}
          >
            <Button icon={<UploadOutlined />}>
              <Trans id="settings.users.import.step.upload.button">
                Selecteer een bestand
              </Trans>
            </Button>
          </Upload>
        </StepContent>
      ),
    },
    {
      title: t({
        id: 'settings.users.import.step.validate',
        message: 'Valideren',
      }),
      content: (
        <StepContent>
          <h3>
            <Trans id="settings.users.import.step.validate.title">
              Voorbeeldtabel
            </Trans>
          </h3>
          <p>
            {' '}
            <Trans id="settings.users.import.step.validate.description">
              Kijk even na of deze structuur klopt.
            </Trans>
          </p>
          {dataPreview && (
            <Table
              dataSource={dataPreview}
              columns={columns}
              pagination={false}
              rowKey="email"
            />
          )}
        </StepContent>
      ),
    },
    {
      title: t({
        id: 'settings.users.import.step.import',
        message: 'Importeren',
      }),
      content: (
        <StepContent>
          <h3>
            {' '}
            <Trans id="settings.users.import.step.import.title">
              Importeren starten
            </Trans>
          </h3>
          <p>
            <Trans id="settings.users.import.step.import.description">
              De pagina verlaten zal het importeren stoppen.
            </Trans>
          </p>
          <Progress percent={(processingRow / rows) * 100} status="active" />
        </StepContent>
      ),
    },
  ]

  const handleImport = () => {
    setProcessing(true)
    let currentRow = 0
    if (fileList && fileList.length > 0) {
      papaparse(fileList[0], {
        step: (results, parser) => {
          parser.pause()
          currentRow++
          setProcessingRow(currentRow)

          const role = [...(roles?.fetchUserRoles || [])].sort(
            (a, b) => b.level - a.level
          )[0]

          createUser({
            variables: {
              firstName: (results.data as BranchUserImport).firstName,
              lastName: (results.data as BranchUserImport).lastName,
              email: (results.data as BranchUserImport).email,
              password: (results.data as BranchUserImport).password,
              role: role._id,
            },
          })
            .catch((error) => message.error(error.message))
            .then(async (data: { data: CreateUserMutation }) => {
              const certification =
                certifications?.fetchCertificationTypes.find(
                  (type) =>
                    type.name ===
                    (results.data as BranchUserImport).certification
                )

              return updateUser({
                variables: {
                  id: data.data.createUser._id,
                  firstName: (results.data as BranchUserImport).firstName,
                  lastName: (results.data as BranchUserImport).lastName,
                  email: (results.data as BranchUserImport).email,
                  certificationType: certification ? [certification._id] : [],
                  meta: {
                    itaa_no: (results.data as BranchUserImport).itaaNo,
                  },
                },
              })
            })
            .catch((error) => message.error(error.message))
            .then(() =>
              console.log(
                t({
                  id: 'settings.users.import.create.success',
                  message: `${
                    (results.data as BranchUserImport).email
                  } successvol toegevoegd`,
                })
              )
            )
            .finally(() => parser.resume())
        },
        complete: () => {
          notification.success({
            message: t({
              id: 'settings.users.import.success',
              message: 'Importeren voltooid',
            }),
          })
          setTimeout(function () {
            client.refetchQueries({ include: ['branchUsers'] })
          }, 100)
          navigate('/branch/users')
        },
        error: (error) => {
          message.error(error.message)
          setProcessing(false)
          setProcessingRow(0)
        },
        header: true,
        skipEmptyLines: true,
      })
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
      />

      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24} lg={12}>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current > 0 && (
                <Button
                  disabled={processing}
                  style={{ margin: '0 8px' }}
                  onClick={() => prev()}
                >
                  <Trans id="settings.users.import.button.previous">
                    Terug
                  </Trans>
                </Button>
              )}
              {fileList &&
                fileList.length > 0 &&
                current < steps.length - 1 && (
                  <Button
                    disabled={processing}
                    type="primary"
                    onClick={() => next()}
                  >
                    <Trans id="settings.users.import.button.next">
                      Volgende
                    </Trans>
                  </Button>
                )}
              {current === steps.length - 1 && (
                <Button
                  disabled={processing}
                  type="primary"
                  onClick={handleImport}
                >
                  <Trans id="settings.users.import.button.start">
                    Start het importeren
                  </Trans>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Content>
    </>
  )
}
