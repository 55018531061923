/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  notification,
  PageHeader,
  Row,
  Select,
  Space,
  Switch,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import { cloneDeep } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  CreateSurveyUnitMutation,
  UpdateSurveyUnitMutation,
  SurveyUnitInput,
  CreateSurveyUnitMutationVariables,
  SurveyUnit,
  SurveyQuestionType,
} from 'apps/lms-front/src/generated/graphql'
import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { PageProps } from 'apps/lms-front/src/modules/shared/interfaces/page.interface'
import { Content } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

import { purgeProperties } from '../../../helpers/purge-properties'

import CREATE_SURVEY_UNIT_MUTATION from './../../../mutations/create-survey-unit.graphql'
import UPDATE_SURVEY_UNIT_MUTATION from './../../../mutations/update-survey-unit.graphql'

interface Props extends PageProps {
  className?: string
  unit?: SurveyUnit
}

export const CourseEditSurveyUnit = ({ route, unit }: Props) => {
  const navigate = useNavigate()

  const [createUnit, { loading: creating }] =
    useMutation<CreateSurveyUnitMutation>(CREATE_SURVEY_UNIT_MUTATION)
  const [updateUnit, { loading: updating }] =
    useMutation<UpdateSurveyUnitMutation>(UPDATE_SURVEY_UNIT_MUTATION, {
      variables: {
        id: unit?._id,
      },
    })

  const { id, section } = useParams()

  const [form] = useForm()
  const [formValues, setFormValues] = useState<SurveyUnitInput>()
  const [formDirty, setFormDirty] = useState<boolean>(false)

  useEffect(() => {
    if (unit) setFormValues(unit)
  }, [unit, setFormValues])

  const cleanedUnit = useMemo(() => {
    if (unit) {
      const clone = cloneDeep(unit)
      purgeProperties(clone, ['__typename'])
      return clone as Store
    }
    return undefined
  }, [unit])

  const MAX_CHARACTERS = {
    NAME: 100,
  }

  const SaveButton = () => (
    <Button
      disabled={!formDirty || creating || updating}
      type="primary"
      htmlType="button"
      onClick={() => {
        if (unit?.translations && unit.translations.length > 0) {
          Modal.confirm({
            title: t({
              id: 'course.unit.edit.translations.warning.title',
              message: 'Controleer vertalingen',
            }),
            content: t({
              id: 'course.unit.edit.translations.warning.content',
              message:
                'Dit onderdeel bevat vertalingen. Controleer of deze nog up-to-date zijn na je wijzigingen.',
            }),
            okText: t({
              id: 'action.continue',
              message: 'Doorgaan',
            }),
            cancelText: t({
              id: 'action.cancel',
              message: 'Annuleren',
            }),
            onOk: () => form.submit(),
          })
        } else {
          form.submit()
        }
      }}
      key="1"
    >
      <Trans id="action.save">Opslaan</Trans>
    </Button>
  )

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
        extra={[
          unit ? (
            <Button
              key="3"
              onClick={() =>
                navigate(`/courses/${unit.course_id}/edit-content`)
              }
            >
              <Trans id="course.edit_contents.back_to_overview">
                Terug naar overzicht
              </Trans>
            </Button>
          ) : (
            <Button onClick={() => navigate(-1)} key="2">
              <Trans id="action.cancel">Annuleren</Trans>
            </Button>
          ),
          <SaveButton key="1" />,
        ]}
      />
      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={cleanedUnit}
              onValuesChange={(_, values) => setFormValues(values)}
              onFinish={(variables: SurveyUnitInput) => {
                if (unit) {
                  updateUnit({
                    variables: {
                      ...variables,
                      _id: unit._id,
                    },
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'course.unit.edit.success',
                          message: 'Onderdeel succesvol opgeslagen',
                        }),
                      })
                      setFormDirty(false)
                    })
                    .catch(errorNotifierFn)
                } else {
                  createUnit({
                    variables: {
                      name: variables.name,
                      questions: variables.questions,
                      courseId: id,
                      sectionId: section,
                    } as CreateSurveyUnitMutationVariables,
                  })
                    .then((result) => {
                      notification.success({
                        message: t({
                          id: 'course.unit.create.success',
                          message: 'Onderdeel succesvol opgeslagen',
                        }),
                      })
                      const id = result.data?.addSurveyUnit.course_id
                      navigate(`/courses/${id}/edit-content`, { replace: true })
                    })
                    .catch(errorNotifierFn)
                }
              }}
              onFieldsChange={() => setFormDirty(true)}
              autoComplete="off"
            >
              <Form.Item
                label={t({
                  id: 'course.unit.form.label.name',
                  message: 'Naam',
                })}
                name="name"
                rules={[
                  {
                    required: !!unit,
                    message: t({
                      id: 'course.unit.form.validation.name',
                      message:
                        'Gelieve een naam voor dit onderdeel in te vullen',
                    }),
                  },
                  {
                    max: MAX_CHARACTERS.NAME,
                    message: t({
                      id: 'course.unit.form.validation.max_characters',
                      message: `Gelieve onder de ${MAX_CHARACTERS.NAME} tekens te blijven`,
                    }),
                  },
                ]}
              >
                <Input tabIndex={0} />
              </Form.Item>
              <Form.List name="questions">
                {(fields, { add, remove }) => (
                  <Row>
                    <Col span={16} offset={8}>
                      <Collapse ghost={fields.length === 0}>
                        {fields.map(({ key, name, ...field }, i, arr) => (
                          <Collapse.Panel
                            key={key}
                            forceRender={true}
                            header={
                              <div
                                style={{ flex: 1, display: 'flex' }}
                                onKeyDown={(e) => e.stopPropagation()}
                                onKeyUp={(e) => e.stopPropagation()}
                                onKeyPress={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()}
                                role={'menuitem'}
                                tabIndex={-1}
                              >
                                <Form.Item
                                  {...field}
                                  style={{
                                    flex: 1,
                                    marginTop: -4,
                                    marginBottom: -4,
                                  }}
                                  wrapperCol={{ span: 24 }}
                                  name={[name, 'question']}
                                  rules={[
                                    {
                                      required: true,
                                      message: t({
                                        id: 'course.unit.form.validation.survey.question',
                                        message:
                                          'Gelieve een vraag in te vullen',
                                      }),
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={t({
                                      id: 'course.unit.form.placeholder.survey.question',
                                      message: 'Vul hier de vraag in',
                                    })}
                                  />
                                </Form.Item>
                              </div>
                            }
                            style={{
                              marginBottom: i === arr.length - 1 ? 24 : 0,
                            }}
                            extra={
                              <MinusCircleOutlined
                                style={{
                                  padding: 12,
                                  margin: -12,
                                  marginLeft: 0,
                                }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  remove(name)
                                }}
                              />
                            }
                          >
                            <Form.Item
                              name={[name, 'answer_type']}
                              required={true}
                              style={{ paddingLeft: 48 }}
                            >
                              <Select
                                placeholder={t({
                                  id: 'course.unit.form.placeholder.survey.answer_type',
                                  message:
                                    'Selecteer het type antwoord voor deze vraag',
                                })}
                              >
                                <Select.Option
                                  value={SurveyQuestionType.MultipleChoice}
                                >
                                  <Trans id="course.unit.form.survey.answer_type.multiple_choice">
                                    Meerkeuzevraag
                                  </Trans>
                                </Select.Option>
                                <Select.Option
                                  value={SurveyQuestionType.OpenEnded}
                                >
                                  <Trans id="course.unit.form.survey.answer_type.open_ended">
                                    Open antwoord
                                  </Trans>
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            {formValues?.questions[name]?.answer_type ===
                              SurveyQuestionType.MultipleChoice && (
                              <Form.List name={[name, 'answers']}>
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(
                                      ({ key, name, ...restField }) => (
                                        <div
                                          key={key}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            marginBottom: -8,
                                            paddingLeft: 24,
                                          }}
                                        >
                                          <MinusCircleOutlined
                                            style={{
                                              margin: -8,
                                              padding: 8,
                                              marginRight: 4,
                                            }}
                                            onClick={() => remove(name)}
                                          />
                                          <Form.Item
                                            {...restField}
                                            style={{ flex: 1 }}
                                            wrapperCol={{ span: 24 }}
                                            name={[name, 'answer']}
                                            rules={[
                                              {
                                                required: true,
                                                message: t({
                                                  id: 'course.unit.form.validation.survey.answer',
                                                  message:
                                                    'Vul een antwoord in',
                                                }),
                                              },
                                            ]}
                                          >
                                            <Input />
                                          </Form.Item>
                                        </div>
                                      )
                                    )}

                                    <Form.Item
                                      wrapperCol={{ span: 24 }}
                                      style={{ paddingLeft: 48 }}
                                    >
                                      <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                      >
                                        <Space
                                          style={{ justifyContent: 'center' }}
                                        >
                                          <PlusOutlined />
                                          <Trans id="course.unit.form.action.survey.add_answer">
                                            Antwoord toevoegen
                                          </Trans>
                                        </Space>
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            )}
                          </Collapse.Panel>
                        ))}
                      </Collapse>

                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="dashed" onClick={() => add()} block>
                          <Space style={{ justifyContent: 'center' }}>
                            <PlusOutlined />
                            <Trans id="course.unit.form.action.survey.add_question">
                              Vraag toevoegen
                            </Trans>
                          </Space>
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form.List>
              <Form.Item
                name="optional"
                label={t({
                  id: 'course.unit.form.label.optional',
                  message: 'Optioneel',
                })}
                tooltip={t({
                  id: 'course.unit.form.help.optional',
                  message:
                    'Dit lesonderdeel is niet verplicht om het attest van deze opleiding te behalen.',
                })}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                <SaveButton />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </>
  )
}
