/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */
import {
  CheckOutlined,
  HeartFilled,
  HeartOutlined,
  MenuOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Button, Drawer, Dropdown, Menu as AntMenu, Tooltip } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { usePostHog } from 'posthog-js/react'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  LanguagesQuery,
  UpdateProfileLanguageMutation,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { AbilityContext } from '../../../auth/components/Can'
import { ModalContext } from '../../../auth/context/searchModal.context'
import LANGUAGES_QUERY from '../../../settings/queries/languages.graphql'
import { useMediaQuery } from '../../hooks/use-media-query'
import { ExternalLinkIcon } from '../../icons/icons'
import { PageProps } from '../../interfaces/page.interface'
import { breakpoints } from '../../layout/breakpoints'
import {
  Avatar,
  AvatarLabel,
  AvatarWrapper,
  Header as AntHeader,
  HeaderContainer,
  Logo,
  NavMenu,
} from '../../layout/Layout.style'
import { StatusBar } from '../status-bar/StatusBar'

import UPDATE_PROFILE_LANG_MUTATION from './../../../user/mutations/update-profile-lang.graphql'
import { TextLogo } from './TextLogo'

export type NavigationItem = {
  order: number
  label: string | React.ReactNode
  icon?: React.ReactElement
  route?: string
  params?: Record<string, string>
  link?: string
  onMouseEnter?: () => void
  permission?:
    | { action: string; subject: string }
    | {
        or: { action: string; subject: string }[]
      }
  children?: NavigationItem[]
}

const navigationItemMapper = ({
  label,
  icon,
  route,
  link,
  onMouseEnter,
}: NavigationItem) => {
  return {
    key: route || (link ? `external:${link}` : ''),
    label,
    icon,
    onMouseEnter,
    onClick: link
      ? () => {
          window.open(link, '_blank')
        }
      : undefined,
  }
}

interface Props extends PageProps {
  navigation: {
    anon: NavigationItem[]
    auth: NavigationItem[]
  }
  loading: boolean
}

export const Header = ({ route, navigation, loading }: Props) => {
  const posthog = usePostHog()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const branch = useBranch()
  const { user, logout } = useAuth()
  const params = useParams()
  const [searchEnabled, setSearchEnabled] = useState(false)

  const [visible, setVisible] = useState(false)
  const toggleDrawer = () => {
    setVisible(!visible)
  }

  const desktop = useMediaQuery(breakpoints.xl)

  const { pathname: location } = useLocation()
  useEffect(() => {
    setVisible(false)
  }, [location])

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled('Search')) {
        setSearchEnabled(true)
      }
    })
  }, [posthog])

  const [language, setLanguage] = useState<string>(
    user?.lang || sessionStorage.getItem('aa_lms_lang') || 'nl-BE'
  )
  const { setIsOpen: setModalStatus } = useContext(ModalContext)

  const { data: languages } = useQuery<LanguagesQuery>(LANGUAGES_QUERY, {
    fetchPolicy: 'cache-first',
    skip: ability.cannot(
      PermissionAction.CONFIGURE,
      PermissionObjectType.LANGUAGE
    ),
  })

  const [updateProfileLang] = useMutation<UpdateProfileLanguageMutation>(
    UPDATE_PROFILE_LANG_MUTATION,
    {
      onCompleted: (user) => {
        if (user.updateProfileLanguage.lang) {
          setLanguage(user.updateProfileLanguage.lang)
          window.LMS.setLanguage(user.updateProfileLanguage.lang)
        }
      },
      refetchQueries: ['me'],
    }
  )

  const avatarMenu: NavigationItem[] = [
    {
      order: 5,
      label: t({
        id: 'header.navigation.profile',
        message: 'Mijn profiel',
      }),
      route: '/profile',
      permission: {
        action: PermissionAction.READ,
        subject: PermissionObjectType.OWN_USER,
      },
    },
    {
      order: 10,
      label: t({
        id: 'header.navigation.certificates',
        message: 'Mijn attesten',
      }),
      route: '/certificates',
      permission: {
        action: PermissionAction.READ,
        subject: PermissionObjectType.OWN_REPORT,
      },
    },
    {
      order: 12,
      label: t({
        id: 'header.navigation.events',
        message: 'Mijn evenementen',
      }),
      route: '/vod-events',
      permission: {
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.VOD_EVENT,
      },
    },
    {
      order: 15,
      label: t({
        id: 'header.navigation.assigned',
        message: 'Toegewezen opleidingen',
      }),
      route: '/assigned',
      permission: {
        action: PermissionAction.READ,
        subject: PermissionObjectType.ASSIGNMENT,
      },
    },
    {
      order: 80,
      label: t({
        id: 'header.navigation.settings',
        message: 'Instellingen',
      }),
      route: '/settings/general',
      permission: {
        action: PermissionAction.ACCESS,
        subject: PermissionObjectType.PLATFORM_SETTINGS,
      },
    },
  ]

  branch?.faqLink &&
    avatarMenu.push({
      order: 20,
      label: (
        <>
          <Trans id="header.navigation.faq">FAQ</Trans>&nbsp;
          <ExternalLinkIcon />
        </>
      ),
      link: branch.faqLink,
    })

  branch?.contactLink &&
    avatarMenu.push({
      order: 25,
      label: (
        <>
          <Trans id="header.navigation.contact">Contact</Trans>&nbsp;
          <ExternalLinkIcon />
        </>
      ),
      link: branch.contactLink,
    })

  return (
    <>
      <StatusBar />
      <AntHeader
        className="lms-main-header"
        style={{
          backgroundColor: branch?.theme.headerColor || undefined,
        }}
      >
        <HeaderContainer>
          {!loading && branch?.logo && (
            <Logo
              src={branch.logo.url}
              alt=""
              width={branch.logo.width}
              height={branch.logo.height}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
          )}
          {!branch?.logo && (
            <TextLogo onClick={() => navigate('/')}>{branch?.name}</TextLogo>
          )}
          <NavMenu
            className={'desktop-nav-menu'}
            onClick={({ key }) => {
              if (!key.startsWith('external:')) {
                const item = (
                  user ? navigation.auth : navigation.anon.reverse()
                ).find((item) => item.route === key)
                if (!item) return
                const params = new URLSearchParams(item?.params).toString()
                navigate(`${key}${params ? `?${params}` : ''}`)
              }
            }}
            theme="dark"
            mode="horizontal"
            selectedKeys={[
              route.path.startsWith('/x/')
                ? `/x/${params[route.path.split('/')[2].replace(':', '')]}#/`
                : '/' + route.path.split('/')[1],
              location,
            ]}
            style={{
              backgroundColor: branch?.theme.headerColor || undefined,
              flexDirection: user ? 'row' : 'row-reverse',
            }}
            items={
              loading
                ? []
                : (user ? navigation.auth : navigation.anon.reverse())
                    .filter(({ permission }) => {
                      if (!permission) return true
                      return 'or' in permission
                        ? permission.or.some((p) => {
                            return ability.can(p.action, p.subject)
                          })
                        : ability.can(permission.action, permission.subject)
                    })
                    .map(({ label, icon, route, link }) => {
                      return link
                        ? ({
                            label: (
                              <a
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  color: '#FFF',
                                  textDecoration: 'none',
                                }}
                              >
                                {label}
                              </a>
                            ),
                            key: 'external:' + link,
                            icon,
                          } as ItemType)
                        : ({
                            icon,
                            label,
                            key: route,
                            // onMouseEnter: () => {
                            //   if (route?.includes('/reports')) Reports.preload()
                            // },
                          } as ItemType)
                    })
            }
          />
          <Button
            className="menu-button"
            type="text"
            style={{ color: '#FFF' }}
            onClick={toggleDrawer}
          >
            <MenuOutlined />
          </Button>
          {user && (
            <NavMenu
              className={'desktop-nav-menu'}
              onClick={({ key }) => {
                !key.startsWith('external:') && navigate(key)
                if (key === 'external:search') {
                  setModalStatus((prevState) => !prevState)
                }
              }}
              theme="dark"
              mode="horizontal"
              selectedKeys={['/' + route.path.split('/')[1]]}
              style={{
                backgroundColor: branch?.theme.headerColor || undefined,
                flexDirection: 'row-reverse',
                flex: '0 0 9.5rem',
                marginRight: 24,
              }}
              items={[
                ...(ability.can(
                  PermissionAction.CONFIGURE,
                  PermissionObjectType.LANGUAGE
                )
                  ? [
                      {
                        label: (
                          <Dropdown
                            menu={{
                              items: languages?.fetchLanguages.map((lang) => ({
                                key: lang.code,
                                label: lang.name,
                                onClick: () =>
                                  updateProfileLang({
                                    variables: {
                                      lang: lang.code,
                                    },
                                  }),
                                itemIcon:
                                  language === lang.code ? (
                                    <CheckOutlined style={{ marginLeft: 12 }} />
                                  ) : undefined,
                              })),
                            }}
                            placement="bottomCenter"
                            mouseEnterDelay={0}
                            align={{
                              offset: [0, 12],
                            }}
                          >
                            <span style={{ fontSize: 16 }}>
                              {language?.split('-')[0].toUpperCase()}
                            </span>
                          </Dropdown>
                        ),
                        key: 'external:lang',
                      },
                    ]
                  : []),
                {
                  label: (
                    <Tooltip
                      placement="bottom"
                      mouseEnterDelay={0}
                      title={t({
                        id: 'header.navigation.search',
                        message: 'Zoeken',
                      })}
                    >
                      <SearchOutlined
                        style={{
                          fontSize: 20,
                          verticalAlign: 'sub',
                          marginRight: -8,
                          marginLeft: -8,
                        }}
                      />
                    </Tooltip>
                  ),
                  key: 'external:search',
                  hidden: !searchEnabled,
                },
                ...(ability.can(
                  PermissionAction.READ,
                  PermissionObjectType.LIKE
                )
                  ? [
                      {
                        key: '/likes',
                        label: (
                          <Tooltip
                            placement="bottom"
                            mouseEnterDelay={0}
                            title={t({ id: 'header.navigation.likes' })}
                          >
                            {route.path.split('/')[1] === 'likes' ? (
                              <HeartFilled
                                style={{
                                  color: 'var(--like-color)',
                                  fontSize: 20,
                                  verticalAlign: 'sub',
                                  marginRight: -8,
                                  marginLeft: -8,
                                }}
                              />
                            ) : (
                              <HeartOutlined
                                style={{
                                  color: 'var(--like-color)',
                                  fontSize: 20,
                                  verticalAlign: 'sub',
                                  marginRight: -8,
                                  marginLeft: -8,
                                }}
                              />
                            )}
                          </Tooltip>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          )}
          {user && (
            <Dropdown
              className="user-menu"
              menu={{
                onClick: ({ key }) =>
                  !key.startsWith('external:') && navigate(key),
                items: [
                  ...avatarMenu
                    .filter(({ permission }: NavigationItem) => {
                      if (!permission) return true
                      else if ('or' in permission) {
                        return permission.or.some(({ action, subject }) =>
                          ability.can(
                            action as PermissionAction,
                            subject as PermissionObjectType
                          )
                        )
                      } else {
                        return ability.can(
                          permission.action as PermissionAction,
                          permission.subject as PermissionObjectType
                        )
                      }
                    })
                    .sort((a, b) => a.order - b.order)
                    .map(navigationItemMapper)
                    .filter(Boolean),
                  {
                    type: 'divider',
                  },
                  {
                    key: 'external:logout',
                    label: t({
                      id: 'header.navigation.logout',
                      message: 'Uitloggen',
                    }),
                    onClick: () => logout(),
                  },
                ],
              }}
              placement="bottomRight"
              trigger={['click']}
            >
              <AvatarWrapper>
                <Avatar size={40} src={user.picture?.url}>
                  {user.firstName.charAt(0)}
                </Avatar>
                {desktop && (
                  <>
                    <AvatarLabel>
                      <Trans id="header.avatar.hello">
                        Hallo, <strong>{user.firstName}</strong>
                      </Trans>
                    </AvatarLabel>
                    <svg
                      style={{ color: '#FFF' }}
                      width="9"
                      height="7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M.833 1.625 4.5 5.292l3.667-3.667"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </>
                )}
              </AvatarWrapper>
            </Dropdown>
          )}
        </HeaderContainer>
      </AntHeader>
      <Drawer
        title={branch?.name}
        placement="right"
        closable={true}
        onClose={toggleDrawer}
        open={visible}
        style={{ zIndex: 99999 }}
        bodyStyle={{ padding: 0 }}
        footerStyle={{ padding: '6px 0' }}
        footer={
          user && (
            <AntMenu
              mode="inline"
              onClick={({ key }) =>
                !key.startsWith('external:') && navigate(key)
              }
              items={[
                {
                  key: 'avatarMenu',
                  className: 'avatar-menu',
                  label: (
                    <span style={{ marginLeft: 12 }}>
                      <Trans id="header.avatar.hello">
                        Hallo, <strong>{user.firstName}</strong>
                      </Trans>
                    </span>
                  ),
                  icon: (
                    <Avatar size={40} src={user.picture?.url}>
                      {user.firstName.charAt(0)}
                    </Avatar>
                  ),
                  style: {
                    paddingLeft: 0,
                  },
                  children: [
                    ...avatarMenu
                      .filter(({ permission }: NavigationItem) => {
                        if (!permission) return true
                        else if ('or' in permission) {
                          return permission.or.some(({ action, subject }) =>
                            ability.can(
                              action as PermissionAction,
                              subject as PermissionObjectType
                            )
                          )
                        } else {
                          return ability.can(
                            permission.action as PermissionAction,
                            permission.subject as PermissionObjectType
                          )
                        }
                      })
                      .sort((a, b) => a.order - b.order)
                      .map(navigationItemMapper)
                      .filter(Boolean),
                    {
                      type: 'divider',
                    },
                    {
                      key: '',
                      label: t({
                        id: 'header.navigation.logout',
                        message: 'Uitloggen',
                      }),
                      onClick: () => logout(),
                    },
                  ],
                },
              ]}
            />
          )
        }
      >
        <AntMenu
          onClick={({ key }) => navigate(key)}
          mode="inline"
          selectedKeys={['/' + route.path.split('/')[1]]}
          items={
            [
              ...(loading
                ? []
                : (user ? navigation.auth : [...navigation.anon].reverse()).map(
                    ({ label, icon, link, route, permission }) => {
                      const item = (
                        link
                          ? ({
                              key: 'external:' + link,
                              icon,
                              label: (
                                <a
                                  href={link}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    color: '#FFF',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {label}
                                </a>
                              ),
                            } as ItemType)
                          : {
                              label,
                              key: route,
                              icon,
                            }
                      ) as ItemType

                      if (!permission) return item

                      if ('or' in permission) {
                        return permission.or.some((p) => {
                          return ability.can(p.action, p.subject)
                        })
                          ? item
                          : undefined
                      } else {
                        return ability.can(
                          permission.action,
                          permission.subject
                        )
                          ? item
                          : undefined
                      }
                    }
                  )),
              user
                ? {
                    key: '/likes',
                    icon:
                      route.path.split('/')[1] === 'likes' ? (
                        <HeartFilled style={{ color: '#FF6B6C' }} />
                      ) : (
                        <HeartOutlined style={{ color: '#FF6B6C' }} />
                      ),
                    label: t({
                      id: 'header.navigation.likes',
                      message: 'Mijn lijst',
                    }),
                  }
                : undefined,
            ].filter(Boolean) as ItemType[]
          }
        />
      </Drawer>
    </>
  )
}
