/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { PageHeader, Space, Tag } from 'antd'
import { ColumnGroupType, ColumnType } from 'antd/lib/table'
import dayjs from 'dayjs'
import quarterPlugin from 'dayjs/plugin/quarterOfYear'
import { usePostHog } from 'posthog-js/react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ArrayParam, StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { formatDuration } from '@lms-shared-patterns/utils'
import { CertificationTypesQuery } from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { useHierarchyTree } from '../../../branch/hooks/use-hierarchy-tree'
import { LoadSection } from '../../../core/components/LoadScreen'
import { setSessionStorageItem } from '../../../core/utils/session-storage'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { ExportButton } from '../../../shared/components/export-button/ExportButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { TreeSelect } from '../../../shared/components/tree-select/TreeSelect'
import { useAxios } from '../../../shared/hooks/use-axios'
import { Content } from '../../../shared/layout/Layout.style'
import { Table } from '../../components/Table'
import { UserCoursesReport } from '../../components/UserCoursesReport'
import { UserEventsReport } from '../../components/UserEventsReport'
import { UserPathsReport } from '../../components/UserPathsReport'

import CERTIFICATION_TYPES_QUERY from './../../../settings/queries/certification-types.graphql'

dayjs.extend(quarterPlugin)

type UsersFromActivityReport = {
  _id: string
  active_courses: number
  completed_courses: number
  courses: number
  disabled: boolean
  email: string
  firstName: string
  lastActive: Date
  lastLogin: Date
  lastName: string
  name: string
  training_time: number
}

export const UsersReport = () => {
  const [userFilters, setUserFilters] = useQueryParam<
    (string | null)[] | null | undefined
  >('user', ArrayParam)
  const [start, setStart] = useQueryParam('start', StringParam)
  const [end, setEnd] = useQueryParam('end', StringParam)

  const [searchParams] = useSearchParams()
  const [query, setQuery] = useQueryParam('query', StringParam)

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const ability = useContext(AbilityContext)

  const client = usePostHog()
  const [calendarFeature, setCalendarFeature] = useState<boolean>(false)

  useEffect(() => {
    client.onFeatureFlags(function () {
      if (client.isFeatureEnabled('Calendar')) {
        setCalendarFeature(true)
      }
    })
  }, [client])

  const [hierarchyFilter, setHierarchyFilter] = useQueryParam(
    'section',
    StringParam
  )
  const { data: treeData, loading: treeLoading } = useHierarchyTree({
    filterByPermission: {
      action: PermissionAction.READ,
      object: PermissionObjectType.BRANCH_REPORT,
    },
  })

  const { data: certificationTypes } = useQuery<CertificationTypesQuery>(
    CERTIFICATION_TYPES_QUERY
  )

  const filterParams = useMemo(() => {
    if (!userFilters || userFilters.filter(Boolean).length === 0) return {}

    const disabled = userFilters.includes('disabled:true')
    const enabled = userFilters.includes('disabled:false')

    const certificationTypes = userFilters
      .filter(Boolean)
      .map((f) => f!.toString())
      .filter((f) => f.startsWith('cert:'))
      .map((f) => f.split(':')[1])

    return {
      disabled:
        disabled && !enabled ? true : enabled && !disabled ? false : undefined,
      certificationType:
        certificationTypes.length > 0 ? certificationTypes : undefined,
    }
  }, [userFilters])

  const params = {
    section_id: hierarchyFilter || sessionStorage.getItem('aa_report_filter'),
    query: query || '',
    page,
    pageSize,
    disabled: !query || query.length === 0 ? false : undefined,
    ...filterParams,
  }

  const [{ data, loading }] = useAxios<{
    data: UsersFromActivityReport[]
    total: number
  }>({
    url: `/api/activity/users`,
    params: start && end ? { ...params, start, end } : params,
  })

  useEffect(() => {
    if (
      !hierarchyFilter &&
      (ability.can(PermissionAction.READ, PermissionObjectType.REPORT) ||
        ability.can(PermissionAction.READ, PermissionObjectType.BRANCH_REPORT))
    )
      setHierarchyFilter(sessionStorage.getItem('aa_report_filter'))
  }, [])

  return (
    <Content style={{ backgroundColor: '#FFF' }}>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'reports.users.title',
          message: 'Gebruikers',
        })}
        extra={
          <Space>
            <DatePicker.RangePicker
              ranges={{
                [t({
                  id: 'datepicker.preset.last_7_days',
                  message: 'Laatste 7 dagen',
                })]: [dayjs().add(-7, 'd'), dayjs()],
                [t({
                  id: 'datepicker.preset.last_30_days',
                  message: 'Laatste 30 dagen',
                })]: [dayjs().add(-30, 'd'), dayjs()],
                [t({
                  id: 'datepicker.preset.this_week',
                  message: 'Deze week',
                })]: [dayjs().startOf('week'), dayjs().endOf('week')],
                [t({
                  id: 'datepicker.preset.this_month',
                  message: 'Deze maand',
                })]: [dayjs().startOf('month'), dayjs().endOf('month')],
                [t({
                  id: 'datepicker.preset.this_quarter',
                  message: 'Dit kwartaal',
                })]: [dayjs().startOf('quarter'), dayjs().endOf('quarter')],
                [t({
                  id: 'datepicker.preset.this_year',
                  message: 'Dit jaar',
                })]: [dayjs().startOf('year'), dayjs().endOf('year')],
              }}
              allowClear={true}
              allowEmpty={[false, false]}
              defaultValue={[
                start ? dayjs(start) : null,
                end ? dayjs(end) : null,
              ]}
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day')
              }}
              onChange={(dates) => {
                if (dates) {
                  setStart(dates[0]?.toISOString())
                  setEnd(dates[1]?.toISOString())
                } else {
                  setStart(undefined)
                  setEnd(undefined)
                }
              }}
              format="DD/MM/YYYY"
            />
            {treeData.length > 0 && (
              <TreeSelect
                placeholder={t({
                  id: 'reports.filter.hierarchy',
                  message: 'Filter op afdeling',
                })}
                treeDefaultExpandAll={true}
                treeLine={true}
                showSearch
                treeDataSimpleMode
                style={{ width: 250 }}
                dropdownMatchSelectWidth={false}
                filterTreeNode={(input, option) =>
                  (option.title as string)
                    ?.toLowerCase()
                    .includes(input.toLowerCase())
                }
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: 'auto',
                }}
                treeData={treeData}
                loading={treeLoading}
                allowClear={ability.cannot(
                  PermissionAction.READ,
                  PermissionObjectType.REPORT
                )}
                treeNodeLabelProp="label"
                value={hierarchyFilter}
                onChange={(value) => {
                  setSessionStorageItem('aa_report_filter', value)
                  setHierarchyFilter(value)
                  setPage(1)
                }}
              />
            )}
          </Space>
        }
      ></PageHeader>
      <Content>
        {!data && <LoadSection />}
        {data && (
          <Table
            expandable={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              expandedRowRender: (record: any) => (
                <>
                  <UserCoursesReport
                    header={t({
                      id: 'reports.users.table.courses',
                      message: 'Opleidingen',
                    })}
                    user_id={record._id}
                    branch_id={record.branch_id}
                    start={start || undefined}
                    end={end || undefined}
                  />
                  {calendarFeature && (
                    <>
                      <UserPathsReport
                        header={t({
                          id: 'reports.users.table.paths',
                          message: 'Leerpaden',
                        })}
                        user_id={record._id}
                        branch_id={record.branch_id}
                        section_id={hierarchyFilter}
                        start={start || undefined}
                        end={end || undefined}
                      />
                      <UserEventsReport
                        header={t({
                          id: 'reports.users.table.events',
                          message: 'Evenementen',
                        })}
                        user_id={record._id}
                        branch_id={record.branch_id}
                        section_id={hierarchyFilter}
                        start={start || undefined}
                        end={end || undefined}
                      />
                    </>
                  )}
                </>
              ),
              expandRowByClick: true,
              // @ts-ignore
              rowExpandable: ({
                courses,
                completed_events,
                active_paths,
                completed_paths,
              }: {
                courses: number
                completed_events: number
                active_paths: number
                completed_paths: number
              }) =>
                courses > 0 ||
                completed_events > 0 ||
                active_paths > 0 ||
                completed_paths > 0 ||
                ability.can(
                  PermissionAction.UPDATE,
                  PermissionObjectType.ACTIVITY
                ),
              defaultExpandedRowKeys: [
                searchParams.get('user_id') || undefined,
                searchParams.get('expand') || undefined,
              ].filter(Boolean) as string[],
            }}
            bordered
            size="small"
            loading={loading}
            dataSource={data?.data}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: data?.total,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page)
                setPageSize(pageSize)
              },
              onShowSizeChange: (_, size: number) => setPageSize(size),
            }}
            onChange={(_, filters) => {
              if (filters.user) setUserFilters(filters.user.map(String))
              else setUserFilters(undefined)
            }}
            rowKey="_id"
            columns={
              [
                {
                  title: t({
                    id: 'reports.users.table.user',
                    message: 'Gebruiker',
                  }),
                  dataIndex: 'user',
                  children: [
                    {
                      title: (
                        <InputSearch
                          placeholder={t({
                            id: 'reports.users.table.search',
                            message: 'Zoeken op naam of e-mailadres',
                          })}
                          defaultValue={query || ''}
                          onSearch={(query) => {
                            setQuery(query)
                            setPage(1)
                          }}
                          onChange={(e) => {
                            if (e.target.value === '') {
                              setQuery(e.target.value)
                            }
                          }}
                        />
                      ),
                      dataIndex: 'name',
                      key: 'name',
                      render: (val: string, record) => {
                        const user = record as UsersFromActivityReport
                        return (
                          <Space>
                            {val}
                            {user.disabled && (
                              <Tag color="red">
                                <Trans id="tag.inactive">Inactief</Trans>
                              </Tag>
                            )}
                          </Space>
                        )
                      },
                    },
                  ],
                  filterMode: 'menu',
                  filters: [
                    {
                      text: 'Erkenning',
                      value: 'certification',
                      children: certificationTypes?.fetchCertificationTypes.map(
                        (c) => ({
                          text: c.name,
                          value: `cert:${c._id}`,
                        })
                      ),
                    },
                    {
                      text: 'Status',
                      value: 'status',
                      children: [
                        {
                          text: 'Actief',
                          value: 'disabled:false',
                        },
                        {
                          text: 'Inactief',
                          value: 'disabled:true',
                        },
                      ],
                    },
                  ],
                },
                {
                  title: t({
                    id: 'reports.users.table.courses',
                    message: 'Opleidingen',
                  }),
                  children: [
                    {
                      title: t({
                        id: 'reports.users.table.courses.in_progress',
                        message: 'In uitvoering',
                      }),
                      dataIndex: 'active_courses',
                      key: 'active_courses',
                      align: 'center' as const,
                    },
                    {
                      title: t({
                        id: 'reports.users.table.courses.completed',
                        message: 'Voltooid',
                      }),
                      dataIndex: 'completed_courses',
                      key: 'completed_courses',
                      align: 'center' as const,
                    },
                    {
                      title: t({
                        id: 'reports.users.table.courses.total',
                        message: 'Totaal',
                      }),
                      dataIndex: 'courses',
                      key: 'courses',
                      align: 'center' as const,
                    },
                    {
                      title: t({
                        id: 'reports.users.table.total_training_duration',
                        message: 'Totale trainingstijd',
                      }),
                      dataIndex: 'training_time',
                      key: 'training_time',
                      align: 'center' as const,
                      render: (val: number) =>
                        val > 60 ? formatDuration(val) : '-',
                    },
                  ],
                },
                calendarFeature && {
                  title: t({
                    id: 'reports.users.table.paths',
                    message: 'Leerpaden',
                  }),
                  dataIndex: 'paths',
                  key: 'paths',
                  align: 'center' as const,
                  children: [
                    {
                      title: t({
                        id: 'reports.users.table.paths.in_progress',
                        message: 'In uitvoering',
                      }),
                      dataIndex: 'active_paths',
                      key: 'active_paths',
                      align: 'center' as const,
                    },
                    {
                      title: t({
                        id: 'reports.users.table.paths.completed',
                        message: 'Voltooid',
                      }),
                      dataIndex: 'completed_paths',
                      key: 'completed_paths',
                      align: 'center' as const,
                    },
                  ],
                },
                calendarFeature && {
                  title: t({
                    id: 'reports.users.table.events',
                    message: 'Evenementen',
                  }),
                  dataIndex: 'events',
                  key: 'events',
                  align: 'center' as const,
                  children: [
                    {
                      title: t({
                        id: 'reports.users.table.events.completed',
                        message: 'Voltooid',
                      }),
                      dataIndex: 'completed_events',
                      key: 'completed_events',
                      align: 'center' as const,
                    },
                  ],
                },
              ].filter(Boolean) as (
                | ColumnGroupType<object>
                | ColumnType<object>
              )[]
            }
            footer={() => (
              <div style={{ textAlign: 'right' }}>
                <Space>
                  <span>
                    <Trans id="reports.courses_report.action.export">
                      Exporteer naar:
                    </Trans>{' '}
                  </span>
                  {['xlsx', 'csv', 'json'].map((type, index) => (
                    <ExportButton
                      key={index}
                      url={`/api/export/users`}
                      params={
                        start && end
                          ? {
                              section_id: hierarchyFilter,
                              start,
                              end,
                              ...filterParams,
                            }
                          : { section_id: hierarchyFilter, ...filterParams }
                      }
                      type={type}
                      name="users"
                      dates={['lastLogin', 'lastActive']}
                    />
                  ))}
                </Space>
              </div>
            )}
            locale={{
              filterConfirm: t({
                id: 'table.filter.confirm',
                message: 'Filter',
              }),
            }}
          />
        )}
      </Content>
    </Content>
  )
}
