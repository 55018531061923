import {
  MessageOutlined,
  PoweroffOutlined,
  QuestionOutlined,
  BarChartOutlined,
  MoreOutlined,
  EyeOutlined,
  BulbOutlined,
} from '@ant-design/icons'
import { t } from '@lingui/macro'
import {
  CameraDisabledIcon,
  CameraIcon,
  MicDisabledIcon,
  MicIcon,
  ScreenShareIcon,
  ScreenShareStopIcon,
  useLocalParticipant,
  useLocalParticipantPermissions,
  useParticipants,
} from '@livekit/components-react'
import '@livekit/components-styles' // Add default styles for LiveKit components
import {
  Badge,
  Dropdown,
  Popconfirm,
  Statistic,
  Tooltip,
  notification,
} from 'antd'
import { Track } from 'livekit-client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { useMediaQuery } from 'apps/lms-front/src/modules/shared/hooks/use-media-query'

import { useStream } from '../../../../contexts/StreamContext'
import { useRoomMetadata } from '../../../../hooks/use-room-metadata'
import {
  isVirtualBackgroundSupported,
  useBackgroundProcessor,
} from '../../backgrounds/BackgroundProcessor'
import { BackgroundController } from '../background-controller/BackgroundController'
import {
  startRecordingApiCall,
  stopRecordingApiCall,
} from '../utils/recording-handler'

import {
  ControlButton,
  ControlPanelContainer,
  LightProgress,
} from './ControlPanel.style'

import type { ParticipantPermission } from '@livekit/protocol'

export function getSourceIcon(source: Track.Source, enabled: boolean) {
  switch (source) {
    case Track.Source.Microphone: {
      return enabled ? <MicIcon /> : <MicDisabledIcon />
    }
    case Track.Source.Camera: {
      return enabled ? <CameraIcon /> : <CameraDisabledIcon />
    }
    case Track.Source.ScreenShare: {
      return enabled ? <ScreenShareStopIcon /> : <ScreenShareIcon />
    }
    default: {
      return undefined
    }
  }
}

export const ControlPanel = ({
  onToggleChat,
  isChatVisible,
  unreadChatCount,
  virtualBackground,
  onVirtualBackgroundChange,
}: {
  onToggleChat: () => void
  isChatVisible: boolean
  unreadChatCount: number
  virtualBackground: string
  onVirtualBackgroundChange: (background: string) => void
}) => {
  const { localParticipant } = useLocalParticipant()
  const { call_id } = useParams()
  const { canPublish } =
    useLocalParticipantPermissions() as ParticipantPermission

  const { backgroundProcessor } = useBackgroundProcessor()

  const participants = useParticipants()
  const {
    leaveRoom,
    emit,
    surveyCompletionArr,
    surveyActiveTimer,
    survey,
    persistentUserChoices: { saveAudioInputEnabled, saveVideoInputEnabled },
  } = useStream()

  const isMobile = useMediaQuery('(max-width: 768px)')
  const metadata = useRoomMetadata()

  const { isHost } = useMemo(
    () => JSON.parse(localParticipant.metadata as string),
    [localParticipant.metadata]
  )

  const [surveyStartTime, setSurveyStartTime] = useState<number | null>(null)
  const [virtualBackgroundDrawerOpened, setVirtualBackgroundDrawerOpened] =
    useState(false)

  const [isStopRecordingPopoverOpened, setStopRecordingPopoverOpened] =
    useState<boolean>(false)

  const [optimisticHandRaised, setOptimisticHandRaised] = useState(false)

  const isHandRaised =
    optimisticHandRaised ||
    metadata.handRaisedParticipants?.includes(localParticipant.identity)

  // Reset the optimistic hand raised state when the hand raised participants change
  useEffect(() => {
    setOptimisticHandRaised(false)
  }, [metadata.handRaisedParticipants])

  // Close the stop recording popover if the recording is stopped
  useEffect(() => {
    if (!metadata.isRecording) setStopRecordingPopoverOpened(false)
  }, [metadata.isRecording])

  const totalNonHostParticipants = useMemo(() => {
    return participants
      ? participants.filter((p) => {
          const userMetadata = p?.metadata
            ? JSON.parse(p?.metadata as string)
            : {}

          return !userMetadata['isHost']
        }).length
      : 0
  }, [participants])

  const completionPercentage = useMemo(() => {
    if (
      !surveyCompletionArr ||
      surveyCompletionArr.length === 0 ||
      totalNonHostParticipants === 0
    ) {
      return 0
    }
    const completedCount = surveyCompletionArr.filter(Boolean).length
    return Math.round((completedCount / totalNonHostParticipants) * 100)
  }, [surveyCompletionArr, totalNonHostParticipants])

  const toggleAudio = async () => {
    if (!canPublish) return
    const newState = !localParticipant.isMicrophoneEnabled
    try {
      await localParticipant.setMicrophoneEnabled(newState)
      saveAudioInputEnabled(newState)
    } catch (error) {
      notification.error({
        message: t({
          id: 'stream.control_panel.error.audio',
          message: 'Failed to toggle audio',
        }),
      })
      console.error('Failed to toggle audio', error)
    }
  }

  const toggleVideo = async () => {
    if (!canPublish) return
    const newState = !localParticipant.isCameraEnabled
    try {
      await localParticipant.setCameraEnabled(newState, {
        processor: backgroundProcessor || undefined,
      })
      saveVideoInputEnabled(newState)
    } catch (error) {
      notification.error({
        message: t({
          id: 'stream.control_panel.error.video',
          message: 'Failed to toggle video',
        }),
      })
      console.error('Failed to toggle video', error)
    }
  }

  const toggleScreenShare = async () => {
    try {
      await (localParticipant.isScreenShareEnabled
        ? localParticipant.setScreenShareEnabled(false)
        : localParticipant.setScreenShareEnabled(true, {
            audio: true,
            systemAudio: 'include',
          }))
    } catch (error) {
      if (error instanceof Error && error.name === 'NotAllowedError') {
        return
      }
      notification.error({
        message: t({
          id: 'stream.control_panel.error.screen_share',
          message: 'Failed to toggle screen sharing',
        }),
      })
      console.error('Failed to toggle screen sharing', error)
    }
  }

  const isRemoteParticipantSharingScreen = useMemo(() => {
    return participants.some(
      (participant) =>
        participant !== localParticipant && participant.isScreenShareEnabled
    )
  }, [participants, localParticipant])

  const handleSurveyAction = useCallback(() => {
    emit('toggleSurvey', {
      call_id,
      is_survey_visible: !metadata.is_survey_visible,
    })

    if (metadata.is_survey_visible) {
      setSurveyStartTime(null)
    } else {
      const startTime = Date.now()
      setSurveyStartTime(startTime)
    }
  }, [metadata.is_survey_visible, emit, call_id])

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null

    if (surveyStartTime) {
      timer = setTimeout(() => {
        handleSurveyAction()
      }, surveyActiveTimer)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [surveyStartTime, handleSurveyAction])

  const startRecording = async () => {
    try {
      await startRecordingApiCall(call_id)
    } catch (error) {
      console.error('Failed to start recording', error)
    }
  }

  const stopRecording = async () => {
    try {
      await stopRecordingApiCall(call_id)
    } catch (error) {
      console.error('Failed to stop recording', error)
    }
  }

  const handleRecordingClick = () => {
    if (metadata.isRecording) {
      if (!metadata.isAutoRecording) {
        stopRecording()
      }
    } else {
      startRecording()
    }
  }

  const handleConfirmStopRecording = async () => {
    return await stopRecording()
      .then(() => {
        setStopRecordingPopoverOpened(false)
      })
      .catch(errorNotifierFn)
  }

  return (
    <ControlPanelContainer>
      <>
        {canPublish && (
          <>
            <ControlButton onClick={toggleAudio} disabled={!canPublish}>
              {getSourceIcon(
                Track.Source.Microphone,
                localParticipant.isMicrophoneEnabled
              )}
              <span>
                {localParticipant.isMicrophoneEnabled
                  ? t({
                      id: 'stream.control_panel.action.mute',
                      message: 'Geluid dempen',
                    })
                  : t({
                      id: 'stream.control_panel.action.unmute',
                      message: 'Geluid hervatten',
                    })}
              </span>
            </ControlButton>
            <ControlButton onClick={toggleVideo} disabled={!canPublish}>
              {getSourceIcon(
                Track.Source.Camera,
                localParticipant.isCameraEnabled
              )}
              <span>
                {localParticipant.isCameraEnabled
                  ? t({
                      id: 'stream.control_panel.action.stop_video',
                      message: 'Video stoppen',
                    })
                  : t({
                      id: 'stream.control_panel.action.start_video',
                      message: 'Video starten',
                    })}
              </span>
            </ControlButton>
            {!isMobile && (
              <Tooltip
                placement="top"
                title={
                  isRemoteParticipantSharingScreen
                    ? t({
                        id: 'stream.control_panel.action.only_one_screen_share',
                        message:
                          'Only one screen could be shared at the same time',
                      })
                    : ''
                }
              >
                <span>
                  <ControlButton
                    onClick={toggleScreenShare}
                    disabled={isRemoteParticipantSharingScreen}
                  >
                    {getSourceIcon(
                      Track.Source.ScreenShare,
                      localParticipant.isScreenShareEnabled
                    )}
                    <span>
                      {localParticipant.isScreenShareEnabled
                        ? t({
                            id: 'stream.control_panel.action.stop_sharing_screen',
                            message: 'Scherm delen stoppen',
                          })
                        : isRemoteParticipantSharingScreen
                        ? t({
                            id: 'stream.control_panel.action.screen_already_shared',
                            message: 'Scherm wordt al gedeeld',
                          })
                        : t({
                            id: 'stream.control_panel.action.share_screen',
                            message: 'Scherm delen',
                          })}
                    </span>
                  </ControlButton>
                </span>
              </Tooltip>
            )}
          </>
        )}
      </>
      {!canPublish && !isHost && (
        <ControlButton
          onClick={async () => {
            setOptimisticHandRaised(!isHandRaised)
            emit('raiseHand', {
              call_id,
              participantId: localParticipant.identity,
              isHandRaised: !isHandRaised,
            })
          }}
        >
          <BulbOutlined
            style={{ color: isHandRaised ? 'yellow' : 'inherit' }}
          />
          <span style={{ color: isHandRaised ? 'yellow' : 'inherit' }}>
            {isHandRaised
              ? t({
                  id: 'stream.control_panel.action.put_hand_down',
                  message: 'Put hand down',
                })
              : t({
                  id: 'stream.control_panel.action.raise_hand',
                  message: 'Raise hand',
                })}
          </span>
        </ControlButton>
      )}
      {!isMobile && (
        <ControlButton onClick={() => leaveRoom()}>
          <PoweroffOutlined style={{ color: 'red' }} />
          <span>
            {t({
              id: 'stream.control_panel.action.leave',
              message: 'Verlaten',
            })}
          </span>
        </ControlButton>
      )}
      {!isMobile && (
        <ControlButton onClick={onToggleChat}>
          <Badge dot={unreadChatCount > 0} offset={[-5, 5]}>
            <MessageOutlined
              style={{ color: isChatVisible ? '#1890ff' : 'white' }}
            />
          </Badge>
          <span>
            {isChatVisible
              ? t({
                  id: 'stream.control_panel.action.hide_chat',
                  message: 'Chat verbergen',
                })
              : t({
                  id: 'stream.control_panel.action.show_chat',
                  message: 'Chat tonen',
                })}
          </span>
        </ControlButton>
      )}
      {isHost && (
        <>
          <Popconfirm
            title={t({
              id: 'stream.control_panel.action.stop_recording.popconfirm',
              message:
                'Deze sessie werd ingesteld om automatisch opgenomen te worden. Ben je zeker dat je de opname wil stoppen?',
            })}
            okText={t({
              id: 'stream.control_panel.action.stop_recording.popconfirm.button',
              message: 'De opname stoppen',
            })}
            onConfirm={handleConfirmStopRecording}
            onCancel={() => setStopRecordingPopoverOpened(false)}
            placement="top"
            open={isStopRecordingPopoverOpened}
            onOpenChange={(open) =>
              setStopRecordingPopoverOpened(
                open && metadata.isAutoRecording && metadata.isRecording
              )
            }
            overlayStyle={{ maxWidth: '400px' }}
          >
            <ControlButton onClick={handleRecordingClick}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="#FFF"
                    strokeWidth="1.75"
                  />
                  <circle
                    cx="12"
                    cy="12"
                    r="4"
                    stroke={metadata.isRecording ? 'red' : '#FFF'}
                    fill={metadata.isRecording ? 'red' : 'inherit'}
                    strokeWidth="1.75"
                  />
                </svg>

                <span>
                  {metadata.isRecording
                    ? t({
                        id: 'stream.control_panel.action.stop_recording',
                        message: 'Opname stoppen',
                      })
                    : t({
                        id: 'stream.control_panel.action.record',
                        message: 'Opname starten',
                      })}
                </span>
              </div>
            </ControlButton>
          </Popconfirm>
          {!isMobile && !!survey?.length && (
            <>
              <ControlButton onClick={handleSurveyAction}>
                <QuestionOutlined
                  style={{
                    color: metadata.is_survey_visible ? 'red' : 'inherit',
                  }}
                />
                <span>
                  {metadata.is_survey_visible
                    ? t({
                        id: 'stream.control_panel.survey.stop',
                        message: 'Stop survey',
                      })
                    : t({
                        id: 'stream.control_panel.survey.start',
                        message: 'Start survey',
                      })}
                </span>
              </ControlButton>
              {metadata.is_survey_visible && (
                <Tooltip
                  style={{ backgroundColor: 'white' }}
                  title={
                    <LightProgress
                      type="dashboard"
                      percent={completionPercentage}
                      size="small"
                      format={() => (
                        <Statistic
                          valueStyle={{ textAlign: 'center' }}
                          value={surveyCompletionArr.length}
                          suffix={`/ ${totalNonHostParticipants}`}
                        />
                      )}
                    />
                  }
                  placement="top"
                >
                  <ControlButton>
                    <BarChartOutlined />
                    <span>
                      {t({
                        id: 'stream.control_panel.survey.title',
                        message: 'Survey status',
                      })}
                    </span>
                  </ControlButton>
                </Tooltip>
              )}
            </>
          )}
          {isVirtualBackgroundSupported() && (
            <>
              <Dropdown
                placement="top"
                menu={{
                  items: [
                    {
                      label: t({
                        id: 'stream.background-fx',
                        message: 'Achtergrondeffecten',
                      }),
                      key: 'virtual-background',
                      icon: <EyeOutlined />,
                      onClick: () => setVirtualBackgroundDrawerOpened(true),
                    },
                  ],
                }}
              >
                <ControlButton>
                  <MoreOutlined />
                  <span>
                    {t({
                      id: 'stream.control_panel.more',
                      message: 'Meer',
                    })}
                  </span>
                </ControlButton>
              </Dropdown>
              <BackgroundController
                virtualBackgroundDrawerOpened={virtualBackgroundDrawerOpened}
                setVirtualBackgroundDrawerOpened={
                  setVirtualBackgroundDrawerOpened
                }
                virtualBackground={virtualBackground}
                onVirtualBackgroundChange={onVirtualBackgroundChange}
              />
            </>
          )}
        </>
      )}
    </ControlPanelContainer>
  )
}
