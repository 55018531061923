import {
  BarChartOutlined,
  FileOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FileZipOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Avatar, List, Space } from 'antd'
import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ArticleSearchResult,
  CourseSearchResult,
  FileSearchResult,
  ReportingSearchResult,
  SearchResultUnion,
  UserSearchResult,
  VideoSearchResult,
} from '../../generated/graphql'

type SearchResultType =
  | ArticleSearchResult
  | CourseSearchResult
  | UserSearchResult
  | VideoSearchResult
  | ReportingSearchResult
  | FileSearchResult

const isArticleSearchType = (
  data: SearchResultType
): data is ArticleSearchResult => 'thumbnail' in data
const isCourseSearchType = (
  data: SearchResultType
): data is CourseSearchResult => 'image' in data
const isUserSearchType = (data: SearchResultType): data is UserSearchResult =>
  'picture' in data
const isVideoSearchType = (data: SearchResultType): data is VideoSearchResult =>
  'course_image' in data
const isReportingSearchType = (
  data: SearchResultType
): data is ReportingSearchResult => 'slug' in data
const isFileSearchType = (data: SearchResultType): data is FileSearchResult =>
  'path' in data

const iconMap: { [key: string]: JSX.Element } = {
  '.pdf': <FilePdfOutlined />,
  '.docx': <FileWordOutlined />,
  '.zip': <FileZipOutlined />,
}

const renderAvatar = (data: FileSearchResult) => {
  const ext = Object.keys(iconMap).find((ext) =>
    data.title.toLowerCase().includes(ext)
  )
  const icon = ext ? iconMap[ext] : <FileOutlined />

  return (
    <span>
      <Avatar style={{ backgroundColor: 'var(--header-color)' }} icon={icon} />
    </span>
  )
}

interface SearchListItemProps {
  onClick: () => void
  ariaLabel: string
  children: React.ReactNode
  selected?: boolean
}

const SearchListItem = ({
  onClick,
  ariaLabel,
  children,
  selected,
}: SearchListItemProps) => {
  const listItemRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (selected) {
      listItemRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [selected])
  return (
    <List.Item
      ref={listItemRef}
      className={selected ? 'selected' : ''}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      tabIndex={0}
      role="menuitem"
      aria-label={ariaLabel}
    >
      {children}
    </List.Item>
  )
}

export const SearchRow = ({
  data,
  closeModal,
  selected,
}: {
  data: SearchResultUnion
  closeModal: Dispatch<SetStateAction<boolean>>
  selected?: boolean
}) => {
  const navigate = useNavigate()
  const navigateAndCloseModal = (path: string) => {
    navigate(path)
    closeModal(false)
  }

  if (isArticleSearchType(data)) {
    return (
      <SearchListItem
        selected={selected}
        onClick={() => navigateAndCloseModal(`/articles/${data._id}`)}
        ariaLabel={data.title}
      >
        <List.Item.Meta
          avatar={<Avatar src={data.thumbnail} shape="square" />}
          title={data.title}
          description={data.author}
        />
      </SearchListItem>
    )
  }
  if (isCourseSearchType(data)) {
    return (
      <SearchListItem
        selected={selected}
        onClick={() => navigateAndCloseModal(`/courses/${data._id}`)}
        ariaLabel={data.name}
      >
        <List.Item.Meta
          className="search-list-row"
          avatar={<Avatar src={data.image?.url} shape="square" />}
          title={data.name}
          description={data.lecturer}
        />
      </SearchListItem>
    )
  }
  if (isUserSearchType(data)) {
    return (
      <SearchListItem
        selected={selected}
        onClick={() =>
          navigateAndCloseModal(`/branch/users?q=${data.name}&id=${data._id}`)
        }
        ariaLabel={data.name}
      >
        <List.Item.Meta
          className="search-list-row"
          avatar={<Avatar src={data.picture?.url} />}
          title={data.name}
          description={
            <Space>
              <small>
                <MailOutlined />
              </small>
              {data.email}
            </Space>
          }
        />
      </SearchListItem>
    )
  }
  if (isVideoSearchType(data)) {
    return (
      <SearchListItem
        selected={selected}
        onClick={() =>
          navigateAndCloseModal(
            `/courses/unit/${data.unit_id}?start=${data.start}`
          )
        }
        ariaLabel={data.unit_name}
      >
        <List.Item.Meta
          className="search-list-row"
          avatar={<Avatar src={data.course_image?.url} shape="square" />}
          title={data.unit_name}
          description={
            <>
              <Trans id="search.video.course">Opleiding</Trans>
              {': '}
              {data.course_name}
            </>
          }
        />
      </SearchListItem>
    )
  }
  if (isReportingSearchType(data)) {
    return (
      <SearchListItem
        selected={selected}
        onClick={() => navigateAndCloseModal(`/reports/${data.slug}`)}
        ariaLabel={data.name}
      >
        <List.Item.Meta
          className="search-list-row"
          avatar={
            <span>
              <Avatar
                style={{ backgroundColor: 'var(--header-color)' }}
                icon={<BarChartOutlined />}
              />
            </span>
          }
          title={data.name}
          description={
            data.slug?.startsWith('users') ? (
              <Trans id="search.reporting.users">Gebruikersrapport</Trans>
            ) : (
              <Trans id="search.reporting.courses">Opleidingsrapport</Trans>
            )
          }
        />
      </SearchListItem>
    )
  }
  if (isFileSearchType(data)) {
    return (
      <SearchListItem
        selected={selected}
        onClick={() => {
          closeModal(false)
          window.open(data.path, '_blank')?.focus()
        }}
        ariaLabel={data.title}
      >
        <List.Item.Meta
          className="search-list-row"
          avatar={renderAvatar(data)}
          title={data.title}
          description={
            <>
              <Trans id="search.video.course">Opleiding</Trans>
              {': '}
              {data.course_name}
            </>
          }
        />
      </SearchListItem>
    )
  }

  return null
}
