import { CheckOutlined, AlertOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Alert } from 'antd'
import { useContext, useMemo } from 'react'

import { EventLocationType } from '@lms-shared-patterns/enums/event.enums'
import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { LiveEventsQuery } from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../auth/components/Can'
import { useAuth } from '../../auth/hooks/use-auth'

import { useEventStatus } from './use-event-status.hook'

type EventRegistration = LiveEventsQuery['fetchLiveEvents']['results'][0]

type Registration = EventRegistration['my_registration']

type RegistrationStatus = {
  registrationCancelled: boolean
  registered: boolean
  waiting: boolean
  approved: boolean
  denied: boolean
  alert: React.ReactNode[] | null
  selfRegistered: boolean
  selfDenied: boolean

  // Available actions
  canRegister: boolean
  canAdminRegister: boolean
  canRegisterSubject: boolean
  canJoinCall: boolean
  canCancel: boolean
  canApprove: boolean
  canDeny: boolean
  canDelete: boolean
  canAdminCancelParticipant: boolean
}

// Helper functions to improve readability
const isUserMatch = (id1?: string | null, id2?: string | null): boolean => {
  return !!id1 && !!id2 && String(id1) === String(id2)
}

export const isRegistrationActive = (
  registration: Registration
): registration is NonNullable<Registration> => {
  return !!(
    registration?.created &&
    !registration.cancelled &&
    !registration.denied
  )
}

const getRegistrationAlerts = (
  isRegistered: boolean,
  isWaiting: boolean,
  isDenied: boolean
): React.ReactNode[] | null => {
  const alerts: React.ReactNode[] = []

  if (isRegistered) {
    alerts.push(
      <Alert
        key="registered"
        showIcon
        icon={<CheckOutlined />}
        banner
        type="success"
        message={
          <Trans id="events.card.registered">
            Je bent ingeschreven voor deze sessie
          </Trans>
        }
      />
    )
  }

  if (isWaiting) {
    alerts.push(
      <Alert
        key="waiting"
        showIcon
        icon={<AlertOutlined />}
        banner
        type="warning"
        message={
          <Trans id="events.card.in_review">
            Je inschrijving wacht op goedkeuring van een beheerder
          </Trans>
        }
      />
    )
  }

  if (isDenied) {
    alerts.push(
      <Alert
        key="denied"
        showIcon
        icon={<AlertOutlined />}
        banner
        type="error"
        message={t({
          id: 'events.card.denied',
          message: 'Je inschrijving voor deze sessie is afgewezen',
        })}
      />
    )
  }

  return alerts.length > 0 ? alerts : null
}

export const useUserEventRegistrationStatus = (
  event: EventRegistration,
  subject_id?: string
): RegistrationStatus => {
  const { user } = useAuth()
  const ability = useContext(AbilityContext)

  const isAdminWrite = ability.can(
    PermissionAction.UPDATE,
    PermissionObjectType.BRANCH_LIVE_EVENT_REGISTRATION
  )

  const { openForRegistration, registrationStop, cancelled, ended } =
    useEventStatus(event)

  const currentUserId = subject_id || user?._id

  return useMemo(() => {
    const {
      approval,
      my_registration,
      location_type,
      call_id,
      path,
      lecturers,
    } = event

    // Basic registration status
    const isActive = isRegistrationActive(my_registration)
    const isLecturer =
      lecturers?.some((l) => l.user_id === currentUserId) || false

    // Core status flags
    const registered = isActive && (!approval || my_registration.approved)
    const registrationCancelled = !!my_registration?.cancelled

    const waiting =
      isActive && !!approval && !my_registration.approved && !cancelled

    const denied = !!my_registration?.created && !!my_registration.denied

    const approved =
      !!my_registration?.created && !!my_registration?.approved && !cancelled

    // User relationship flags
    const selfRegistered =
      !!my_registration &&
      isUserMatch(my_registration.created_by?._id, currentUserId)

    const selfDenied =
      denied && isUserMatch(my_registration.denied_by?._id, currentUserId)

    // Available actions
    const canRegister =
      !isActive &&
      !registrationStop &&
      !denied &&
      openForRegistration &&
      !ended &&
      !cancelled &&
      !path

    const canAdminRegister =
      !registrationStop && isAdminWrite && !ended && !cancelled && !path

    const canRegisterSubject =
      !isActive &&
      !registrationStop &&
      !denied &&
      isAdminWrite &&
      !ended &&
      !cancelled &&
      !path

    const canCancel = isActive && !ended && !cancelled && !isLecturer

    const canApprove =
      (waiting || denied) &&
      (approval || denied) &&
      isAdminWrite &&
      !ended &&
      !cancelled &&
      !path

    const canDeny =
      isActive &&
      !denied &&
      !!approval &&
      isAdminWrite &&
      !ended &&
      !cancelled &&
      !path

    const canAdminCancelParticipant =
      !ended &&
      !cancelled &&
      isAdminWrite &&
      isActive &&
      !canDeny &&
      !approval &&
      !path

    const canDelete =
      !!my_registration && isAdminWrite && !ended && !cancelled && !path

    const canJoinCall =
      registered &&
      location_type.includes(EventLocationType.Online) &&
      !!call_id &&
      !ended &&
      !cancelled

    return {
      // Status flags
      registrationCancelled,
      registered,
      waiting,
      approved,
      denied,
      selfRegistered,
      selfDenied,

      // Available actions
      canRegister,
      canAdminRegister,
      canRegisterSubject,
      canJoinCall,
      canCancel,
      canApprove,
      canDeny,
      canDelete,
      canAdminCancelParticipant,

      // Alerts
      alert: getRegistrationAlerts(registered, waiting, denied),
    }
  }, [
    event,
    cancelled,
    currentUserId,
    registrationStop,
    openForRegistration,
    ended,
    isAdminWrite,
  ])
}

export type { RegistrationStatus, EventRegistration }
