import { Spin } from 'antd'
import styled from 'styled-components'

const CenterScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 10rem);
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const LoadScreen = () => {
  return (
    <CenterScreen>
      <Spin size="large" />
    </CenterScreen>
  )
}

export const LoadSection = ({
  size,
}: {
  size?: 'small' | 'large' | 'default'
}) => {
  return (
    <Center>
      <Spin size={size || 'large'} />
    </Center>
  )
}
