import { t } from '@lingui/macro'
import { Form, Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'

import { StreamActionEnum } from '@lms-shared-patterns/enums/event.enums'

import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { renderLogType } from '../EventUsersReport'

interface AddLogModalProps {
  open: boolean
  initialDate?: Date
  onCancel: () => void
  onSubmit: (values: { date: Date; type: StreamActionEnum }) => void
}

export const AddLogModal = ({
  open,
  initialDate,
  onCancel,
  onSubmit,
}: AddLogModalProps) => {
  const [form] = Form.useForm()

  const handleOk = async () => {
    const values = await form.validateFields()
    onSubmit({
      date: values.date.toDate(),
      type: values.type,
    })
    form.resetFields()
  }

  useEffect(() => {
    if (initialDate) form.setFieldsValue({ date: dayjs(initialDate) })
  }, [initialDate])

  return (
    <Modal
      title={t({
        id: 'reports.users_report.add_log_modal.title',
        message: 'Log toevoegen',
      })}
      open={open}
      onCancel={onCancel}
      onOk={handleOk}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="date"
          label={t({
            id: 'reports.users_report.add_log_modal.date',
            message: 'Datum en tijd',
          })}
          rules={[
            {
              required: true,
              message: t({
                id: 'reports.users_report.add_log_modal.date_required',
                message: 'Selecteer een datum en tijd',
              }),
            },
          ]}
          initialValue={dayjs(initialDate ?? undefined)}
        >
          <DatePicker
            showTime
            allowClear={false}
            showNow={false}
            format="DD/MM/YYYY HH:mm:ss"
            style={{ width: '100%' }}
            onSelect={(date) => date && form.setFieldsValue({ date })}
            inputReadOnly
          />
        </Form.Item>

        <Form.Item
          name="type"
          label={t({
            id: 'reports.users_report.add_log_modal.type',
            message: 'Type',
          })}
          rules={[
            {
              required: true,
              message: t({
                id: 'reports.users_report.add_log_modal.type_required',
                message: 'Selecteer een type',
              }),
            },
          ]}
          initialValue={StreamActionEnum.JOIN}
        >
          <Select>
            {Object.entries(StreamActionEnum).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {renderLogType(value)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
