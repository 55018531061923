import { FileOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { Button, List, notification, Progress, Space, Tooltip } from 'antd'
import axios, { AxiosError } from 'axios'
import download from 'js-file-download'
import { v4 as uuidv4 } from 'uuid'

import { LiveEventRecordingsQuery } from 'apps/lms-front/src/generated/graphql'

import { LoadSection } from '../../core/components/LoadScreen'
import { AttachmentName } from '../../courses/pages/course-viewer/CourseViewer'
import EVENT_RECORDINGS_QUERY from '../queries/live-event-recordings.graphql'

export const EventRecordings = ({ event_id }: { event_id: string }) => {
  const { data: recordings, loading } = useQuery<LiveEventRecordingsQuery>(
    EVENT_RECORDINGS_QUERY,
    {
      variables: {
        event_id,
      },
    }
  )

  if (loading) return <LoadSection />

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <List
        style={{
          margin: -16,
          border: 0,
        }}
        size="small"
        bordered
        locale={{
          emptyText: t({
            id: 'events.detail.recordings.empty',
            message: 'Geen opnames beschikbaar',
          }),
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dataSource={recordings?.fetchLiveEventRecordings || []}
        renderItem={(item) => (
          <List.Item>
            <Button
              onClick={async () => {
                let cancelled = false
                const key = uuidv4()
                const controller = new AbortController()
                await axios
                  .get(item.url, {
                    responseType: 'blob',
                    signal: controller.signal,
                    onDownloadProgress(progressEvent) {
                      const percentCompleted = progressEvent.total
                        ? Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                          )
                        : 0
                      if (percentCompleted < 100 && !cancelled) {
                        notification.open({
                          key,
                          message: t({
                            id: 'events.detail.recordings.download.title',
                            message: 'Opname wordt gedownload',
                          }),
                          description: <Progress percent={percentCompleted} />,
                          onClose: () => {
                            cancelled = true
                            controller.abort()
                          },
                          duration: 0,
                        })
                      }
                    },
                  })
                  .then((file) => {
                    if (file instanceof AxiosError) return
                    download(file.data, item.originalName, item.mimetype)
                  })
                  .finally(() => notification.close(key))
              }}
              type="text"
              style={{ padding: 0, height: 'auto' }}
              icon={
                <FileOutlined
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'sub',
                  }}
                />
              }
            >
              <Tooltip title={item.originalName} mouseEnterDelay={2}>
                <AttachmentName>{item.originalName}</AttachmentName>
              </Tooltip>
            </Button>
          </List.Item>
        )}
      />
    </Space>
  )
}
