import {
  AlertOutlined,
  CheckOutlined,
  CloseOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  notification,
  Space,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'

import {
  ApproveLearningPathParticipantMutation,
  DeleteLearningPathParticipantMutation,
  DenyLearningPathParticipantMutation,
  LearningPathParticipant,
  PathQuery,
} from 'apps/lms-front/src/generated/graphql'

import { useLearningPathStatus } from '../hooks/use-path-status.hook'
import { useUserPathStatus } from '../hooks/use-user-path-status.hook'
import APPROVE_LEARNING_PATH_PARTICIPANT_MUTATION from '../mutations/approve-path-participant.graphql'
import DELETE_LEARNING_PATH_PARTICIPANT_MUTATION from '../mutations/delete-path-participant.graphql'
import DENY_LEARNING_PATH_PARTICIPANT_MUTATION from '../mutations/deny-path-participant.graphql'
import PENDING_APPROVALS_QUERY from '../queries/pending-path-approvals.graphql'

import {
  LargeTag,
  ParticipantDetails,
  ParticipantListItemWrapper,
  ParticipantName,
  ParticipantRegistrationDetails,
} from './../../events/components/ParticipantListItem.style'

export const ParticipantListItem = ({
  participant,
  path,
  onParticipantStatusChange,
}: {
  participant: LearningPathParticipant
  path: PathQuery['fetchLearningPathById']
  onParticipantStatusChange?: () => void
}) => {
  const { hasReachedCapacity } = useLearningPathStatus(path)
  const { canApprove, canDeny, canDelete, waiting } = useUserPathStatus(
    path,
    participant.activity || undefined,
    participant.request || undefined,
    participant._id
  )

  const [denyForm] = Form.useForm()
  const [denyModalVisible, setDenyModalVisible] = useState<boolean>(false)

  const [approve] = useMutation<ApproveLearningPathParticipantMutation>(
    APPROVE_LEARNING_PATH_PARTICIPANT_MUTATION,
    {
      variables: {
        path_id: path._id,
        user_id: participant._id,
      },
      refetchQueries: [
        'path',
        {
          query: PENDING_APPROVALS_QUERY,
        },
      ],
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'paths.participants.confirmation.approved',
            message: 'Deelnemer goedgekeurd',
          }),
        })
        onParticipantStatusChange?.()
      },
    }
  )

  const [deny, { loading: denying }] =
    useMutation<DenyLearningPathParticipantMutation>(
      DENY_LEARNING_PATH_PARTICIPANT_MUTATION,
      {
        variables: {
          path_id: path._id,
          user_id: participant._id,
        },
        refetchQueries: [
          'path',
          {
            query: PENDING_APPROVALS_QUERY,
          },
        ],
        onCompleted: () => {
          setDenyModalVisible(false)
          denyForm.resetFields()
          notification.success({
            message: t({
              id: 'paths.participants.confirmation.denied',
              message: 'Deelnemer afgewezen',
            }),
          })
          onParticipantStatusChange?.()
        },
      }
    )

  const [remove, { loading: removing }] =
    useMutation<DeleteLearningPathParticipantMutation>(
      DELETE_LEARNING_PATH_PARTICIPANT_MUTATION,
      {
        variables: {
          path_id: path._id,
          user_id: participant._id,
        },
        refetchQueries: ['path'],
        onCompleted: () => {
          notification.success({
            message: t({
              id: 'paths.participants.confirmation.deleted',
              message: 'Deelnemer verwijderd',
            }),
          })
        },
      }
    )

  const dropDownMenuItems = [
    canApprove && {
      disabled: hasReachedCapacity,
      key: 'approve',
      icon: <CheckOutlined />,
      label: <Trans id="paths.participants.approve">Goedkeuren</Trans>,
      onClick: () => approve(),
    },
    canDeny && {
      key: 'deny',
      icon: <CloseOutlined />,
      label: <Trans id="paths.participants.deny">Afwijzen</Trans>,
      onClick: () => setDenyModalVisible(true),
    },
    canDelete && {
      key: 'delete',
      icon: <CloseOutlined />,
      label: <Trans id="paths.participants.delete">Verwijderen</Trans>,
      onClick: () => {
        Modal.confirm({
          title: t({
            id: 'paths.participants.delete.confirm',
            message: 'Ben je zeker dat je deze deelnemer wil verwijderen?',
          }),
          onOk: () => remove(),
          okText: t({
            id: 'action.delete',
            message: 'Verwijderen',
          }),
          okButtonProps: { loading: removing },
          cancelText: t({
            id: 'action.cancel',
            message: 'Annuleren',
          }),
        })
      },
    },
  ].filter(Boolean)

  return (
    <ParticipantListItemWrapper>
      <div>
        <Avatar
          src={participant.picture?.url}
          icon={participant.firstName.slice(0, 1).toUpperCase()}
        />
      </div>
      <ParticipantDetails>
        <div>
          <ParticipantName
            style={{
              textDecoration: participant.request?.denied
                ? 'line-through'
                : 'none',
            }}
          >
            <Space>
              <span>
                {participant.firstName} {participant.lastName}
              </span>
            </Space>
          </ParticipantName>
          <ParticipantRegistrationDetails>
            {participant.activity && (
              <Trans id="paths.participants.registration_details">
                Ingeschreven op
                {' ' +
                  `${dayjs(participant.activity?.created).format(
                    'DD/MM/YYYY'
                  )} `}
              </Trans>
            )}
            {participant.request?.denied ? (
              <>
                <Trans id="paths.participants.denied_details">
                  Afgewezen op
                </Trans>
                {' ' +
                  `${dayjs(participant.request?.denied).format('DD/MM/YYYY')} `}
              </>
            ) : null}
          </ParticipantRegistrationDetails>
        </div>
        <div style={{ display: 'flex' }}>
          <Space>
            {participant.activity && participant.request?.approved && (
              <LargeTag>
                <Trans id="paths.participants.approved">
                  <CheckOutlined />
                  Goedgekeurd
                </Trans>
              </LargeTag>
            )}
            {participant.request?.denied && (
              <Tooltip title={participant.request.denial_reason}>
                <LargeTag>
                  <Trans id="paths.participants.denied">
                    <CloseOutlined />
                    Afgewezen
                  </Trans>
                </LargeTag>
              </Tooltip>
            )}
            {waiting && (
              <Space>
                <LargeTag>
                  <Trans id="paths.participants.waiting">
                    <AlertOutlined />
                    Wacht op goedkeuring
                  </Trans>
                </LargeTag>
                {canApprove && (
                  <Tooltip
                    title={
                      <Trans id="paths.participants.approve">Goedkeuren</Trans>
                    }
                  >
                    <Button
                      shape="circle"
                      icon={<CheckOutlined />}
                      size="small"
                      disabled={hasReachedCapacity}
                      onClick={() => approve()}
                    />
                  </Tooltip>
                )}
                {canDeny && (
                  <Tooltip
                    title={<Trans id="paths.participants.deny">Afwijzen</Trans>}
                  >
                    <Button
                      shape="circle"
                      icon={<CloseOutlined />}
                      size="small"
                      onClick={() => setDenyModalVisible(true)}
                    />
                  </Tooltip>
                )}
              </Space>
            )}
            {dropDownMenuItems.length > 0 && (
              <Dropdown
                menu={{ items: dropDownMenuItems as MenuProps['items'] }}
              >
                <Button type={'text'} icon={<MoreOutlined />} size="small" />
              </Dropdown>
            )}
          </Space>
        </div>
      </ParticipantDetails>
      <Modal
        open={denyModalVisible}
        onCancel={() => setDenyModalVisible(false)}
        closable={false}
        maskClosable={false}
        centered={true}
        bodyStyle={{
          borderRadius: '5px',
          overflow: 'hidden',
        }}
        onOk={() => denyForm.submit()}
        okText={<Trans id="action.deny">Afwijzen</Trans>}
        okButtonProps={{ loading: denying }}
      >
        <Form
          form={denyForm}
          onFinish={({ reason }) =>
            deny({
              variables: { reason },
            })
          }
        >
          <Form.Item
            label={
              <Trans id={'paths.participants.deny_reason'}>
                Reden voor afkeuring
              </Trans>
            }
            name="reason"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </ParticipantListItemWrapper>
  )
}
