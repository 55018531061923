import { useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import '@livekit/components-styles'
import { Alert, Button, Col, Form, Row } from 'antd'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { LiveEventByCallIdQuery } from 'apps/lms-front/src/generated/graphql'

import { useAuth } from '../../../auth/hooks/use-auth'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { Header } from '../../../units/components/header'
import MEETING_DETAILS_QUERY from '../../queries/live-event-by-call-id.graphql'

import { PreJoin } from './components/prejoin/Prejoin'
import { StreamError } from './StreamError'

const NotParticipant = () => (
  <StreamError
    title={t({
      id: 'events.attendance.failed',
      message: 'Aanmelden mislukt',
    })}
    subTitle={t({
      id: 'stream.join.not_allowed',
      message:
        'Je hebt niet de juiste rechten om deel te nemen aan deze meeting.',
    })}
  />
)

const InvalidLinkLayout = () => (
  <Row justify="center" style={{ marginTop: '50px' }}>
    <Col span={8}>
      <div
        style={{
          textAlign: 'center',
          background: '#fff',
          padding: '24px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Trans id="stream.join.invalid_link">
          Dit is geen geldige meeting link
        </Trans>
      </div>
    </Col>
  </Row>
)

export const PreJoinStream = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { call_id } = useParams()

  const handleError = useCallback((error) => console.error(error), [])

  const { data, loading } = useQuery<LiveEventByCallIdQuery>(
    MEETING_DETAILS_QUERY,
    {
      variables: {
        call_id,
      },
      onError: (error) => {
        console.error('Failed to fetch meeting details:', error)
      },
    }
  )

  const isParticipant = useMemo(
    () =>
      data?.fetchLiveEventByCallId?.participants?.some(
        (participant) => participant._id === user?._id
      ),
    [data, user?._id]
  )
  const participantName = useMemo(
    () => `${user?.firstName} ${user?.lastName}`,
    [user]
  )

  const isMeetingHost = useMemo(() => {
    if (!user?._id) return false
    const lecturerIds =
      data?.fetchLiveEventByCallId?.lecturers?.map((l) => l.user_id) || []
    const hostIds = data?.fetchLiveEventByCallId?.stream_details?.host_ids || []
    const allHosts = new Set([...lecturerIds, ...hostIds])
    return allHosts.has(user._id)
  }, [data, user?._id])

  if (!call_id) return

  const getMainLayout = () => {
    if (loading) return <LoadScreen />
    if (!data) return <InvalidLinkLayout />

    return isParticipant ? (
      <Row justify="center" style={{ marginTop: '50px' }}>
        <Col sm={12} lg={8}>
          <div id="join">
            <Form
              layout="vertical"
              style={{
                background: '#fff',
                padding: '24px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {data.fetchLiveEventByCallId.stream_details?.is_auto_recorded && (
                <Alert
                  style={{ textAlign: 'center', marginBottom: 16 }}
                  message={
                    <Trans id="stream.join.auto_recorded">
                      Deze sessie wordt automatisch opgenomen.
                    </Trans>
                  }
                  type="warning"
                  showIcon
                />
              )}

              {isMeetingHost && (
                <PreJoin
                  micLabel={t({
                    id: 'stream.join.mic',
                    message: 'Microfoon',
                  })}
                  camLabel={t({ id: 'stream.join.cam', message: 'Camera' })}
                  onError={handleError}
                />
              )}

              {!isMeetingHost && (
                <>
                  <h2>
                    <Trans id="stream.join.welcome">
                      Welkom, {participantName}
                    </Trans>
                  </h2>
                  <p>
                    <Trans id="stream.join.info">
                      Klik op de knop hieronder om in de meeting te gaan.
                    </Trans>
                  </p>
                </>
              )}

              <Form.Item noStyle>
                <Button
                  type="primary"
                  onClick={() => {
                    navigate(`/meet/${call_id}`, {
                      state: { referrer: 'prejoin' },
                    })
                  }}
                  block
                >
                  <Trans id="stream.join.join">Nu deelnemen</Trans>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    ) : (
      <NotParticipant />
    )
  }

  return (
    <div className="app">
      <Header
        title={data ? data.fetchLiveEventByCallId.title : ''}
        onBack={() =>
          navigate(
            `/library/calendar?event=${
              data ? data.fetchLiveEventByCallId._id : ''
            }`
          )
        }
      />
      {getMainLayout()}
    </div>
  )
}
