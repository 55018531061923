import { FileOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  notification,
  PageHeader,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { useNavigate, useParams } from 'react-router-dom'

import {
  CreateExportJobMutation,
  ExportJobName,
  ListExportJobsQuery,
} from 'apps/lms-front/src/generated/graphql'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { Content } from '../../../shared/layout/Layout.style'

import LIST_EXPORT_JOBS_QUERY from './../../../settings/queries/list-export-jobs.graphql'
import CREATE_EXPORT_JOB_MUTATION from './../../mutations/create-export-job.graphql'

dayjs.extend(localeData)

export const ExportCertificationTypeReport = () => {
  const { certification_type } = useParams()
  const navigate = useNavigate()
  const [form] = useForm()
  const separateFilesForCourses = useWatch('separateFilesForCourses', form)
  const month = useWatch('month', form)
  const year = useWatch('year', form)

  const showWarning =
    month &&
    year &&
    dayjs().diff(dayjs().month(month).year(year).endOf('month'), 'day') < 7 &&
    dayjs().diff(dayjs().month(month).year(year).endOf('month'), 'day') > 0

  const { data, loading } = useQuery<ListExportJobsQuery>(
    LIST_EXPORT_JOBS_QUERY,
    {
      variables: {
        name: ExportJobName.CertificationTypeReport,
        certificationType: certification_type,
      },
      onCompleted: (data) => {
        if (data?.fetchCertificationType.name === 'ITAA') {
          form.setFieldValue('separateFilesForCourses', true)
        }
      },
    }
  )

  const [createJob, { loading: creatingJob }] =
    useMutation<CreateExportJobMutation>(CREATE_EXPORT_JOB_MUTATION, {
      variables: {
        job: ExportJobName.CertificationTypeReport,
        params: {
          certification_type,
        },
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        })
      },
      onCompleted: ({ createExportJob: { _id } }) =>
        navigate(`/export/job/${_id}`),
    })

  if (loading) return <LoadScreen />

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'export.certification_types.report.title',
          message: `${data?.fetchCertificationType?.name}-rapporten exporteren`,
        })}
      />
      <Content>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Card>
              <h2>
                <Trans id="export.certification_types.report.new">
                  Nieuwe export
                </Trans>
              </h2>
              <Form
                form={form}
                onFinish={async () => {
                  try {
                    const values = await form.validateFields()

                    createJob({
                      variables: {
                        job: ExportJobName.CertificationTypeReport,
                        params: {
                          ...values,
                          certification_type,
                        },
                      },
                    })
                  } catch (error) {
                    console.error(error)
                    notification.error({
                      message: t({
                        id: 'export.certification_types.report.error',
                        message:
                          'Er liep helaas iets fout. Probeer het later opnieuw.',
                      }),
                    })
                  }
                }}
              >
                {/* Month selector */}
                <Form.Item
                  name={'month'}
                  label={t({
                    id: 'export.certification_types.report.form.label.month',
                    message: 'Maand',
                  })}
                  initialValue={dayjs().subtract(1, 'month').month()}
                >
                  <Select
                    options={dayjs
                      .months()
                      .map((month, i) => ({ label: month, value: i }))}
                  />
                </Form.Item>
                <Form.Item
                  name={'year'}
                  label={t({
                    id: 'export.certification_types.report.form.label.year',
                    message: 'Jaar',
                  })}
                  initialValue={dayjs().subtract(1, 'month').year()}
                >
                  <Select
                    options={Array.from({ length: 5 }, (_, i) => ({
                      label: dayjs().subtract(i, 'year').year(),
                      value: dayjs().subtract(i, 'year').year(),
                    }))}
                  />
                </Form.Item>
                {!!showWarning && (
                  <Alert
                    type="warning"
                    showIcon={true}
                    message={t({
                      id: 'export.certification_types.report.form.warning',
                      message:
                        'Let op: het einde van de geselecteerde maand was minder dan 7 dagen geleden. De kans bestaat dus dat de afsluitende toetsen nog niet zijn ingevuld.',
                    })}
                  />
                )}
                <Form.Item
                  name="separateFilesForCourses"
                  label={t({
                    id: 'export.certification_types.report.form.label.separate_files',
                    message: 'Opsplitsen in bestanden per opleiding',
                  })}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren={<Trans id="action.yes">Ja</Trans>}
                    unCheckedChildren={<Trans id="action.no">Nee</Trans>}
                  />
                </Form.Item>
                {data?.fetchCertificationType.name === 'ITAA' &&
                  separateFilesForCourses && (
                    <Form.Item
                      style={{ marginTop: -16 }}
                      name="itaaFormat"
                      label={t({
                        id: 'export.certification_types.report.form.label.itaa_format',
                        message: 'Formatteren als CSV voor ITAA-portaal',
                      })}
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren={<Trans id="action.yes">Ja</Trans>}
                        unCheckedChildren={<Trans id="action.no">Nee</Trans>}
                      />
                    </Form.Item>
                  )}
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => form.submit()}
                    loading={creatingJob}
                  >
                    Exporteer
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          {!!data?.getExportJobs?.length && (
            <Col xs={24} sm={12}>
              <Card>
                <h2>
                  <Trans id="export.certification_types.report.previous">
                    Overzicht van recente exportbestanden
                  </Trans>
                </h2>
                <Table
                  size="small"
                  columns={[
                    {
                      title: t({
                        id: 'export.certification_types.report.table.file',
                        message: 'Bestand',
                      }),
                      dataIndex: ['file', 'originalName'],
                      key: 'file',
                      render: (originalName, record) => (
                        <Space style={{ width: '100%' }}>
                          <FileOutlined />
                          <a
                            key={originalName}
                            href={`${record.file?.url}?ts=${Date.now()}`}
                            download={originalName}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}
                          >
                            {originalName}
                          </a>
                        </Space>
                      ),
                    },
                    {
                      title: t({
                        id: 'export.certification_types.report.table.date',
                        message: 'Datum',
                      }),
                      dataIndex: 'completed',
                      key: 'completed',
                      render: (completed) =>
                        dayjs(completed).format('DD/MM/YYYY HH:mm'),
                    },
                  ]}
                  dataSource={data?.getExportJobs
                    .filter((job) => job.file?.url)
                    .sort(
                      (a, b) =>
                        dayjs(b.completed).unix() - dayjs(a.completed).unix()
                    )}
                  pagination={{
                    pageSize: 5,
                  }}
                />
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </>
  )
}
