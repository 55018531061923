import { Trans, t } from '@lingui/macro'
import { Input, Button, Row, Col, Card, Avatar, Space, Form } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { useEffect, useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import styled from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { AuthMethod } from '@lms-shared-patterns/models'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { LoadSection } from '../../../core/components/LoadScreen'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { breakpoints } from '../../../shared/layout/breakpoints'
import { MicrosoftLoginButton } from '../../components/login-buttons/MicrosoftLoginButton'
import { MobileLogin } from '../mobile-login/MobileLogin'

import { H1, AuthForm } from './Login.style'

export const AlignVertically = styled.div`
  min-height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const AdminLogin = (props: PageProps) => {
  const { user } = useAuth()

  useEffect(() => {
    if (user?.email) sessionStorage.setItem('aa_session_end', '/library')
  }, [user])

  return <Login {...props} isAdminLogin />
}

interface Props extends PageProps {
  isAdminLogin?: boolean
}

export const Login = ({ isAdminLogin = false }: Props) => {
  const branch = useBranch()
  const { user } = useAuth()
  const mobile = useMediaQuery(breakpoints.below_sm)

  const loginMethods = isAdminLogin
    ? [AuthMethod.EMAIL_PASS]
    : branch?.loginMethods

  const [loading, setLoading] = useState<boolean>(false)
  const [autoLogin, setAutoLogin] = useState<boolean>(false)
  const { login, loginWithMicrosoftAzureAd } = useAuth()
  const [email, setEmail] = useState<string | undefined | null>(
    localStorage.getItem('aa_email')
  )
  const [name] = useState<string | undefined | null>(
    localStorage.getItem('aa_first_name')
  )
  const [picture] = useState<string | undefined | null>(
    localStorage.getItem('aa_avatar')
  )

  /**
   * Auto-login if there is only one login method and it is Microsoft
   */
  useEffect(() => {
    let timeout
    if (
      !mobile &&
      !isAdminLogin &&
      branch?.loginMethods &&
      branch?.loginMethods.length === 1 &&
      branch?.loginMethods[0] === AuthMethod.MICROSOFT
    ) {
      timeout = setTimeout(() => {
        loginWithMicrosoftAzureAd()
        setTimeout(() => {
          setAutoLogin(true)
        }, 1000)
      }, 250)
    }
    return () => timeout && clearTimeout(timeout)
  }, [mobile, branch?.loginMethods, loginWithMicrosoftAzureAd, isAdminLogin])

  if (isAdminLogin && user?._id) return <Navigate to={'/'} />
  if (isAdminLogin && !branch?.adminLoginRole) return <Navigate to={'/login'} />

  if (mobile) return <MobileLogin />

  return (
    <AlignVertically>
      <div>
        <H1>
          {isAdminLogin ? (
            <Trans id="login.admin.heading">Inloggen als administrator</Trans>
          ) : (
            <Trans id="login.heading">Inloggen</Trans>
          )}
        </H1>
        <Row justify="center" align="middle" style={{ flex: 1 }}>
          <Col xs={24} lg={12}>
            <AuthForm>
              <Form
                hidden={
                  !(
                    loginMethods && loginMethods.includes(AuthMethod.EMAIL_PASS)
                  )
                }
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                autoComplete="off"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onFinish={(data: any) => {
                  setLoading(true)
                  return login({
                    email: isAdminLogin ? data.email : email || data.email,
                    password: data.password,
                    admin: isAdminLogin,
                  })
                    .catch(errorNotifierFn)
                    .finally(() => setLoading(false))
                }}
              >
                {email && !isAdminLogin && (
                  <Card style={{ marginBottom: 24 }}>
                    <Meta
                      avatar={<Avatar size={'large'} src={picture} />}
                      title={
                        name ? (
                          <Trans id="login.welcome_name">
                            Welkom terug, {name}!
                          </Trans>
                        ) : (
                          <Trans id="login.welcome">Welkom terug!</Trans>
                        )
                      }
                      description={
                        <>
                          {email}{' '}
                          <Button
                            type="text"
                            size={'small'}
                            style={{ padding: 0, fontSize: 14 }}
                            onClick={() => setEmail(undefined)}
                          >
                            (
                            <Trans id="login.other_account">
                              ander account?
                            </Trans>
                            )
                          </Button>
                        </>
                      }
                    />
                  </Card>
                )}
                <Form.Item
                  hidden={!!email && !isAdminLogin}
                  label={t({
                    id: 'login.form.label.email',
                    message: 'E-mailadres',
                  })}
                  labelAlign="left"
                  name="email"
                  normalize={(value) => value.trim()}
                  rules={[
                    {
                      type: 'email',
                      message: t({
                        id: 'login.form.validation.required.email',
                        message: 'Voer een geldig e-mailadres in',
                      }),
                    },
                    {
                      required: email && !isAdminLogin ? false : true,
                      message: t({
                        id: 'login.form.validation.email',
                        message: 'Gelieve je e-mailadres in te geven',
                      }),
                    },
                  ]}
                >
                  <Input disabled={loading} />
                </Form.Item>

                <Form.Item
                  label={t({
                    id: 'login.form.label.password',
                    message: 'Wachtwoord',
                  })}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'login.form.validation.password',
                        message: 'Gelieve je wachtwoord in te geven',
                      }),
                    },
                  ]}
                >
                  <Input.Password disabled={loading} />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{ margin: '0 auto', display: 'block' }}
                  >
                    <Trans id="login.action.login">Inloggen</Trans>
                  </Button>
                </Form.Item>
              </Form>

              {loginMethods &&
                loginMethods.length > 1 &&
                loginMethods.includes(AuthMethod.EMAIL_PASS) && (
                  <p
                    style={{
                      textAlign: 'center',
                      marginTop: -8,
                      marginBottom: 16,
                    }}
                  >
                    -{' '}
                    <Trans id="or" context="alternative">
                      of
                    </Trans>{' '}
                    -
                  </p>
                )}
              <Space
                direction={'vertical'}
                style={{ width: '100%', justifyContent: 'center' }}
              >
                {loginMethods?.includes(AuthMethod.MICROSOFT) &&
                  loginMethods.length !== 1 && (
                    <MicrosoftLoginButton
                      onClick={() => loginWithMicrosoftAzureAd()}
                      style={{
                        margin: '0 auto',
                        display: 'block',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                {loginMethods?.includes(AuthMethod.MICROSOFT) &&
                  loginMethods.length === 1 && (
                    <>
                      <LoadSection />
                      {autoLogin && (
                        <div
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: 24,
                          }}
                        >
                          <Button
                            type="link"
                            size="small"
                            style={{ fontSize: '14px' }}
                            onClick={() => loginWithMicrosoftAzureAd()}
                          >
                            (
                            <Trans id="login.action.redirect-failing">
                              Klik hier als je niet wordt omgeleid.
                            </Trans>
                            )
                          </Button>
                        </div>
                      )}
                    </>
                  )}
              </Space>
            </AuthForm>
            {branch?.enableRegistration && (
              <p
                style={{
                  marginTop: 16,
                  marginBottom: 0,
                  textAlign: 'center',
                }}
              >
                <Trans id="login.contact_for_account">
                  <strong>Nog geen account?</strong> Neem{' '}
                  <a
                    href="https://accountantsacademy.be/contact/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    contact
                  </a>{' '}
                  op.
                </Trans>
              </p>
            )}
            {loginMethods && loginMethods.includes(AuthMethod.EMAIL_PASS) && (
              <p
                style={{
                  marginTop: 16,
                  marginBottom: 0,
                  textAlign: 'center',
                }}
              >
                <Link to={'/reset-password'}>
                  <Trans id="login.forgot_password">Wachtwoord vergeten?</Trans>
                </Link>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </AlignVertically>
  )
}
