/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import { t } from '@lingui/macro'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { usePostHog } from 'posthog-js/react'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'

import { AbilityContext } from '../../../auth/components/Can'
import { CustomTabs } from '../../../settings/pages/general/General.styles'
import { AssignmentsReport } from '../assignments/Assignments'
import { BranchReport } from '../branch/Branch'
import { CertificatesReport } from '../certificates/Certificates'
import { CoursesReport } from '../courses/Courses'
import { LiveEventsReport } from '../events/LiveEvents'
import { GeneralReport } from '../general/General'
import { LearningPathsReport } from '../learning-paths/LearningPaths'
import { MatrixReport } from '../matrix/Matrix'
import { PersonalReport } from '../personal/Personal'
import { SurveysReport } from '../surveys/Surveys'
import { UsersReport } from '../users/Users'
import { WebhookDelivery } from '../webhook/WebhookDelivery'

extend(utc)
extend(timezone)
extend(relativeTime)

export const Reports = () => {
  const navigate = useNavigate()
  const { report } = useParams()
  const ability = useContext(AbilityContext)
  const client = usePostHog()
  const [calendarFeature, setCalendarFeature] = useState<boolean>(false)

  useEffect(() => {
    client.onFeatureFlags(function () {
      if (client.isFeatureEnabled('Calendar')) {
        setCalendarFeature(true)
      }
    })
  }, [client])

  useEffect(() => {
    if (
      !report &&
      ability.can(PermissionAction.READ, PermissionObjectType.OWN_REPORT)
    )
      navigate('/reports/personal')

    if (
      !report &&
      ability.can(PermissionAction.READ, PermissionObjectType.BRANCH_REPORT)
    )
      navigate('/reports/branch')

    if (
      !report &&
      ability.can(PermissionAction.READ, PermissionObjectType.REPORT)
    )
      navigate('/reports/general')
  }, [report, navigate, ability])

  return (
    <CustomTabs
      type="card"
      activeKey={report}
      onChange={(key) => navigate(`/reports/${key}`)}
      destroyInactiveTabPane={true}
    >
      {ability.can(PermissionAction.READ, PermissionObjectType.REPORT) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'reports.report.general.title',
            message: 'Algemeen',
          })}
          key="general"
          disabled={false}
        >
          <GeneralReport />
        </CustomTabs.TabPane>
      )}
      {ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_REPORT
      ) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'reports.report.branch.title',
            message: 'Afdeling',
          })}
          key="branch"
          disabled={false}
        >
          <BranchReport />
        </CustomTabs.TabPane>
      )}
      {ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_REPORT
      ) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'reports.report.courses.title',
            message: 'Opleidingen',
          })}
          key="courses"
          disabled={false}
        >
          <CoursesReport />
        </CustomTabs.TabPane>
      )}
      {ability.can(PermissionAction.READ, PermissionObjectType.BRANCH_REPORT) &&
        calendarFeature && (
          <CustomTabs.TabPane
            tab={t({
              id: 'reports.report.live-events.title',
              message: 'Evenementen',
            })}
            key="events"
            disabled={false}
          >
            <LiveEventsReport />
          </CustomTabs.TabPane>
        )}
      {ability.can(PermissionAction.READ, PermissionObjectType.BRANCH_REPORT) &&
        calendarFeature && (
          <CustomTabs.TabPane
            tab={t({
              id: 'reports.report.learning_paths.title',
              message: 'Leerpaden',
            })}
            key="learning_paths"
            disabled={false}
          >
            <LearningPathsReport />
          </CustomTabs.TabPane>
        )}
      {ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_REPORT
      ) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'reports.report.users.title',
            message: 'Gebruikers',
          })}
          key="users"
          disabled={false}
        >
          <UsersReport />
        </CustomTabs.TabPane>
      )}
      {ability.can(PermissionAction.READ, PermissionObjectType.OWN_REPORT) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'reports.report.personal.title',
            message: 'Persoonlijk rapport',
          })}
          key="personal"
          disabled={false}
        >
          <PersonalReport />
        </CustomTabs.TabPane>
      )}
      {ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_REPORT
      ) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'reports.report.matrix.title',
            message: 'Trainingsmatrix',
          })}
          key="matrix"
          disabled={false}
        >
          <MatrixReport />
        </CustomTabs.TabPane>
      )}
      {ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_REPORT
      ) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'reports.report.assignments.title',
            message: 'Toegewezen opleidingen',
          })}
          key="assignments"
          disabled={false}
        >
          <AssignmentsReport />
        </CustomTabs.TabPane>
      )}
      {ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_REPORT
      ) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'reports.report.certificates.title',
            message: 'Attesten',
          })}
          key="certificates"
        >
          <CertificatesReport />
        </CustomTabs.TabPane>
      )}
      {(ability.can(PermissionAction.READ, PermissionObjectType.REPORT) ||
        ability.can(
          PermissionAction.READ,
          PermissionObjectType.BRANCH_REPORT
        )) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'reports.report.surveys.title',
            message: 'Enquêtes',
          })}
          key="surveys"
          disabled={false}
        >
          <SurveysReport />
        </CustomTabs.TabPane>
      )}
      {ability.can(
        PermissionAction.READ,
        PermissionObjectType.WEBHOOK_DELIVERY
      ) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'reports.report.webhook.title',
            message: 'Webhooks',
          })}
          key="webhooks"
          disabled={false}
        >
          <WebhookDelivery />
        </CustomTabs.TabPane>
      )}
    </CustomTabs>
  )
}
