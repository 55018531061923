import { FileOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Alert, Button, Card, PageHeader, Progress, Space } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  CertificationTypesQuery,
  ExportJobName,
  ExportJobQuery,
} from 'apps/lms-front/src/generated/graphql'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { Content } from '../../../shared/layout/Layout.style'

import EXPORT_JOB_QUERY from './../../../reports/queries/export-job.graphql'
import CERTIFICATION_TYPES from './../../../settings/queries/certification-types.graphql'

export const renderJobName = (value: string) => {
  if (value === ExportJobName.CertificationTypeReport)
    return 'Rapport voor certificeringstype'
  return value
}

export const renderJobParams = (
  { job, params }: ExportJobQuery['getExportJob'],
  certificationTypes: CertificationTypesQuery['fetchCertificationTypes']
) => {
  if (job === ExportJobName.CertificationTypeReport) {
    return (
      <ul>
        <li>
          <Trans id="export.job.status.params.certification_type">
            Certificeringstype
          </Trans>
          :{' '}
          {
            certificationTypes?.find(
              (type) => type._id === params.certification_type
            )?.name
          }
        </li>
        <li>
          <Trans id="export.job.status.params.month">Maand</Trans>:{' '}
          {dayjs().month(params.month).format('MMMM')}
        </li>
        <li>
          <Trans id="export.job.status.params.year">Jaar</Trans>:{' '}
          {dayjs().year(params.year).format('YYYY')}
        </li>
      </ul>
    )
  }
  return (
    <pre>
      <code>{JSON.stringify(params, null, 2)}</code>
    </pre>
  )
}

export const ExportJobStatus = () => {
  const [polling, setPolling] = useState<boolean>(true)
  const navigate = useNavigate()
  const { job_id } = useParams()

  const { data, loading, error } = useQuery<ExportJobQuery>(EXPORT_JOB_QUERY, {
    variables: {
      id: job_id,
    },
    onCompleted: (data) => {
      if (data.getExportJob.completed || data.getExportJob.failed)
        setPolling(false)
    },
    pollInterval: polling ? 2000 : undefined,
  })

  const { data: certificationTypes } =
    useQuery<CertificationTypesQuery>(CERTIFICATION_TYPES)

  if (loading) return <LoadScreen />

  if (error) return <Alert message={error.message} type="error" />

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={
          data?.getExportJob.completed
            ? t({
                id: 'export.job.status.title.completed',
                message: 'Export voltooid',
              })
            : data?.getExportJob.failed
            ? t({
                id: 'export.job.status.title.failed',
                message: 'Export mislukt',
              })
            : t({
                id: 'export.job.status.title',
                message: 'Aan het exporteren...',
              })
        }
      />
      <Content>
        <Card>
          <h2>
            {data?.getExportJob.job && renderJobName(data?.getExportJob.job)}
          </h2>
          <h3>
            <Trans id="export.job.status.params">Parameters:</Trans>
          </h3>
          {data?.getExportJob &&
            renderJobParams(
              data?.getExportJob,
              certificationTypes?.fetchCertificationTypes || []
            )}
          {!data?.getExportJob.failed && (
            <>
              {!!data?.getExportJob.progress && (
                <h2>
                  <Trans id="export.job.status.progress">Vooruitgang</Trans>
                </h2>
              )}
              {data?.getExportJob.progress === 0 && (
                <h2>
                  <Trans id="export.job.status.queued">In de wachtrij...</Trans>
                </h2>
              )}
              <Progress percent={(data?.getExportJob.progress || 0) * 100} />
            </>
          )}
          {data?.getExportJob.failed && (
            <Alert
              type="error"
              message={
                <Trans id="export.job.status.failed">Export mislukt</Trans>
              }
              description={data?.getExportJob.error}
            />
          )}
          {data?.getExportJob?.file?.url && (
            <Space direction="vertical" style={{ marginTop: 24 }}>
              <Space>
                <FileOutlined />
                {data?.getExportJob?.file?.originalName}
              </Space>
              <Space>
                <a
                  href={`${data?.getExportJob?.file?.url}?ts=${Date.now()}`}
                  download
                >
                  <Button type="primary">
                    <Trans id="export.job.status.download">
                      Download export
                    </Trans>
                  </Button>
                </a>
                <Button
                  onClick={() =>
                    navigate(
                      `/export/${data.getExportJob.params.certification_type}/report`
                    )
                  }
                >
                  <Trans id="export.job.new">Nieuwe export</Trans>
                </Button>
              </Space>
            </Space>
          )}
        </Card>
      </Content>
    </>
  )
}
