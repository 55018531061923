import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
} from 'antd'
import { useState } from 'react'

export const EditQuiz = ({
  form,
  noOffset,
  embedded = false,
  namePrefix,
}: {
  form: FormInstance
  noOffset?: boolean
  embedded?: boolean
  namePrefix?: string
}) => {
  // If namePrefix is provided, watch the entire namePrefix object, otherwise watch 'quiz'
  const [showExplanationField, setShowExplanationField] = useState<number[]>([])

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={form.getFieldsValue()}
      autoComplete="off"
      style={{ marginTop: 24 }}
    >
      <Form.List
        name={namePrefix ?? 'quiz'}
        rules={[
          {
            validator: async (_, questions) => {
              if (!questions || questions.length === 0) return true
              const error = questions.some(
                (question: { answers: { correct: boolean }[] }) =>
                  (question.answers && question.answers.length < 2) ||
                  !question.answers
              )

              if (error)
                throw new Error(
                  t({
                    id: 'course.unit.form.validation.quiz.min_answers',
                    message: 'Gelieve minstens twee antwoorden op te geven',
                  })
                )
              else return true
            },
            message: t({
              id: 'course.unit.form.validation.quiz.min_answers',
              message: 'Gelieve minstens twee antwoorden op te geven',
            }),
          },
          {
            validator: async (_, questions) => {
              if (!questions || questions.length === 0) return true
              const error = questions.some(
                (question: { answers: { is_correct: boolean }[] }) =>
                  !question.answers.some((answer) => answer.is_correct)
              )
              if (error)
                throw new Error(
                  t({
                    id: 'course.unit.form.validation.quiz.correct_answer',
                    message:
                      'Gelieve minstens één correct antwoord op te geven',
                  })
                )
              else return true
            },
            message: t({
              id: 'course.unit.form.validation.quiz.correct_answer',
              message: 'Gelieve minstens één correct antwoord op te geven',
            }),
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {embedded && (
              <Row>
                <Divider />
                <Col span={8} offset={8}>
                  <h4>
                    {t({
                      id: 'events.form.label.quiz_details',
                      message: 'Toets details',
                    })}
                  </h4>
                  <Divider />
                </Col>
              </Row>
            )}
            <Row>
              <Col span={noOffset ? 24 : 16} offset={noOffset ? 0 : 8}>
                <Collapse ghost={fields.length === 0}>
                  {fields.map(({ key, name, ...restField }, i, arr) => (
                    <Collapse.Panel
                      key={key}
                      forceRender={true}
                      header={
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                          }}
                          onKeyDown={(e) => e.stopPropagation()}
                          onKeyUp={(e) => e.stopPropagation()}
                          onKeyPress={(e) => e.stopPropagation()}
                          onClick={(e) => e.stopPropagation()}
                          role={'menuitem'}
                          tabIndex={-1}
                        >
                          <Form.Item
                            {...restField}
                            style={{
                              flex: 1,
                              marginTop: -4,
                              marginBottom: -4,
                            }}
                            wrapperCol={{ span: 24 }}
                            name={[name, 'question']}
                            rules={[
                              {
                                required: true,
                                message: t({
                                  id: 'course.unit.form.validation.quiz.question',
                                  message: 'Gelieve een vraag in te vullen',
                                }),
                              },
                            ]}
                          >
                            <Input
                              placeholder={t({
                                id: 'course.unit.form.placeholder.quiz.question',
                                message: 'Vul hier de vraag in',
                              })}
                            />
                          </Form.Item>
                        </div>
                      }
                      style={{
                        marginBottom: i === arr.length - 1 ? 24 : 0,
                      }}
                      extra={
                        <MinusCircleOutlined
                          style={{
                            padding: 12,
                            margin: -12,
                            marginLeft: 0,
                          }}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            remove(name)
                          }}
                        />
                      }
                    >
                      <Form.List name={[name, 'answers']}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <div
                                key={key}
                                style={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                  marginBottom: -8,
                                  paddingLeft: 24,
                                }}
                              >
                                <MinusCircleOutlined
                                  style={{
                                    margin: -8,
                                    padding: 8,
                                    marginRight: 4,
                                  }}
                                  onClick={() => remove(name)}
                                />
                                <Form.Item
                                  {...restField}
                                  style={{ flex: 1 }}
                                  wrapperCol={{ span: 24 }}
                                  name={[name, 'answer']}
                                  rules={[
                                    {
                                      required: true,
                                      message: t({
                                        id: 'course.unit.form.validation.quiz.answer',
                                        message: 'Vul een antwoord in',
                                      }),
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  style={{ marginLeft: 24 }}
                                  name={[name, 'is_correct']}
                                  valuePropName="checked"
                                >
                                  <Checkbox>
                                    <Trans id="course.unit.form.label.quiz.correct">
                                      Correct
                                    </Trans>
                                  </Checkbox>
                                </Form.Item>
                              </div>
                            ))}
                            <Form.Item
                              wrapperCol={{ span: 24 }}
                              style={{ paddingLeft: 48 }}
                            >
                              <Button type="dashed" onClick={() => add()} block>
                                <Space
                                  style={{
                                    justifyContent: 'center',
                                  }}
                                >
                                  <PlusOutlined />
                                  <Trans id="course.unit.form.action.quiz.add_answer">
                                    Antwoord toevoegen
                                  </Trans>
                                </Space>
                              </Button>
                            </Form.Item>
                            <Divider />
                          </>
                        )}
                      </Form.List>
                      {!showExplanationField.includes(key) &&
                      !form.getFieldValue('questions')?.[key]?.explanation ? (
                        <Button
                          type="text"
                          onClick={() => {
                            const updated = [...showExplanationField, key]
                            setShowExplanationField(updated)
                          }}
                          block
                        >
                          <Space style={{ justifyContent: 'center' }}>
                            <PlusOutlined />
                            <Trans id="course.unit.form.action.quiz.add_explanation">
                              Voeg uitleg bij antwoord toe
                            </Trans>
                          </Space>
                        </Button>
                      ) : (
                        <div
                          key={key}
                          style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            marginBottom: -8,
                            paddingLeft: 24,
                          }}
                        >
                          <Form.Item
                            {...restField}
                            style={{
                              flex: 1,
                              marginTop: -4,
                            }}
                            wrapperCol={{ span: 24 }}
                            name={[name, 'explanation']}
                          >
                            <Input
                              placeholder={t({
                                id: 'course.unit.form.placeholder.quiz.explanation',
                                message: 'Vul hier de uitleg in',
                              })}
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            style={{
                              marginLeft: 8,
                            }}
                            onClick={() => {
                              // update the array of ids where the field should be shown
                              const updatedItems = showExplanationField.filter(
                                (i) => i !== key
                              )
                              setShowExplanationField(updatedItems)

                              // clear value of field
                              const updatedQuestions =
                                form.getFieldValue('questions')

                              updatedQuestions[name] = {
                                ...updatedQuestions[name],
                                explanation: undefined,
                              }

                              form.setFieldValue('questions', updatedQuestions)
                            }}
                          />
                        </div>
                      )}
                    </Collapse.Panel>
                  ))}
                </Collapse>

                <Form.Item wrapperCol={{ span: 24 }}>
                  <Button type="dashed" onClick={() => add()} block>
                    <Space style={{ justifyContent: 'center' }}>
                      <PlusOutlined />
                      <Trans id="course.unit.form.action.quiz.add_question">
                        Vraag toevoegen
                      </Trans>
                    </Space>
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </Form>
  )
}
