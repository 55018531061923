/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  CommentOutlined,
  SearchOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Input, Tabs, Empty, List, Badge, Space, InputRef, Spin } from 'antd'
import { debounce } from 'lodash-es'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { io } from 'socket.io-client'

import {
  ArticleSearchResult,
  FileSearchResult,
  SearchQuery,
  SearchResultType,
  SearchResultUnion,
  VideoSearchResult,
} from 'apps/lms-front/src/generated/graphql'

import { ModalContext } from '../auth/context/searchModal.context'
import { LoadSection } from '../core/components/LoadScreen'

import './index.scss'
import SEARCH_QUERY from './queries/search.graphql'
import { SearchRow } from './SearchRow'

import type { TabsProps } from 'rc-tabs'

const socket = io(import.meta.env.NX_SOCKET_HOST, {
  path: '/api/search/ws',
})

interface CommandBarDropdownProps {
  query: string
  data: SearchResultUnion[]
  activeTab: string
  setActiveTab: (key: string) => void
  tabs: Required<TabsProps['items']>
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

export const CommandBarDropdown = ({
  query: search,
  data,
  activeTab,
  setActiveTab,
  tabs,
  onKeyDown,
}: CommandBarDropdownProps) => {
  if (search.length < 3) return <></>

  if (data?.length === 0)
    return (
      <div className="search-dropdown empty">
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t({
            id: 'search.empty',
            message: 'No results found',
          })}
        />
      </div>
    )

  return (
    <div className="search-dropdown">
      <Tabs
        className="search-dropdown-tabs"
        activeKey={activeTab}
        tabPosition="top"
        defaultActiveKey="0"
        onChange={(key) => setActiveTab(key)}
        items={tabs}
        onKeyDown={onKeyDown}
      />
    </div>
  )
}

export const CommandBar = ({
  closeParentModal,
  placeholderText,
}: {
  closeParentModal?: () => void
  placeholderText: string
}) => {
  const [search, setSearch] = useState('')
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<InputRef>(null)

  const { isOpen: isModalOpened } = useContext(ModalContext)

  const [searchResults, setSearchResults] = useState<
    SearchResultUnion[] | null
  >()
  const [isAIResponseAvailable, setAIResponseStatus] = useState(false)
  const [AIResponse, setAIResponse] = useState('')
  const [previousSearch, setPreviousSearch] = useState('')
  const [activeTab, setActiveTab] = useState<string>('0')
  const [selectedSearchResult, setSelectedSearchResult] = useState<
    number | undefined
  >()

  const abortController = useRef<AbortController>(new AbortController())

  const [searchData] = useLazyQuery<SearchQuery>(SEARCH_QUERY, {
    variables: {
      query,
    },
    fetchPolicy: 'cache-and-network',
  })

  const translations = useMemo(
    () => ({
      [SearchResultType.User]: t({
        id: 'search.user',
        message: 'Gebruikers',
      }),
      [SearchResultType.Course]: t({
        id: 'search.course',
        message: 'Opleidingen',
      }),
      [SearchResultType.Video]: t({
        id: 'search.video',
        message: "Video's",
      }),
      [SearchResultType.Article]: t({
        id: 'search.article',
        message: 'Nieuws',
      }),
      [SearchResultType.Reporting]: t({
        id: 'search.reporting',
        message: 'Reporting',
      }),
      [SearchResultType.File]: t({
        id: 'search.file',
        message: 'Bestanden',
      }),
    }),
    []
  )

  const tabs = useMemo<string[]>(() => {
    const tabs: string[] = [
      t({ id: 'search.all', message: 'Alle resultaten' }),
      ...new Set(searchResults?.map((item) => translations[item.type]) || []),
    ]
    isAIResponseAvailable &&
      tabs.unshift(t({ id: 'search.ai.response', message: 'AI' }))

    return tabs
  }, [searchResults, isAIResponseAvailable])

  useEffect(() => {
    socket.connect()

    socket.on('response', (data) => {
      setAIResponse((prev) => prev + data)
    })

    socket.on('error', (error) => {
      console.error('WebSocket error:', error)
    })
    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (isModalOpened) {
      // Focus input on open
      setTimeout(() => inputRef.current?.focus(), 50)
    }

    // Reset dropdown data if search is empty
    if (!query || query.length < 3) {
      return setSearchResults(null)
    }

    // Redirect to first tab (AI or all results) if search changed
    if (query !== previousSearch) {
      setAIResponse('')
      // setActiveTab('0')
    }
  }, [query, isModalOpened])

  const handleAllResultLimit = () => {
    const typeCount = {}
    const maxPerType = 2

    return searchResults?.reduce(
      (unique: SearchResultUnion[], item: SearchResultUnion) => {
        typeCount[item.type] = typeCount[item.type] || 0

        if (typeCount[item.type] < maxPerType) {
          unique.push(item as SearchResultUnion)
          typeCount[item.type]++
        }
        return unique
      },
      []
    )
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSearchResult(undefined)
    setSearch(e.target.value)
    if (e.target.value.length < 3) setQuery('')
  }

  const tabItems: Required<TabsProps['items']> = useMemo(
    () =>
      tabs.map((tab, index) => {
        const label = (
          <Space>
            {tab === 'AI' && <CommentOutlined style={{ marginRight: '5px' }} />}
            {tab}
            {tab !== 'AI' &&
              tab === t({ id: 'search.all', message: 'Alle resultaten' }) && (
                <Badge status="default" count={searchResults?.length || 0} />
              )}
            {tab !== 'AI' &&
              tab !== t({ id: 'search.all', message: 'Alle resultaten' }) && (
                <Badge
                  count={
                    searchResults?.filter(
                      (item) => tab === translations[item.type]
                    ).length || 0
                  }
                />
              )}
          </Space>
        )

        const filteredSearchResults =
          tab === t({ id: 'search.all', message: 'Alle resultaten' })
            ? handleAllResultLimit()
            : searchResults?.filter((item) => tab === translations[item.type])

        return {
          key: index.toString(),
          label,
          children: (
            <div className="scrollable-list">
              {tab === 'AI' && isAIResponseAvailable ? (
                <div style={{ padding: 16 }}>
                  {AIResponse?.length ? (
                    <p className="ai-response">{AIResponse}</p>
                  ) : (
                    <LoadSection size={'small'} />
                  )}
                </div>
              ) : (
                <List
                  size="small"
                  dataSource={filteredSearchResults}
                  renderItem={(item, index) => (
                    <SearchRow
                      key={index}
                      selected={index === selectedSearchResult}
                      closeModal={() => closeParentModal?.()}
                      data={item as SearchResultUnion}
                    />
                  )}
                />
              )}
            </div>
          ),
        }
      }),
    [
      searchResults,
      isAIResponseAvailable,
      tabs,
      AIResponse,
      selectedSearchResult,
    ]
  )

  useEffect(() => {
    if (query.length < 3) return setPreviousSearch(query)

    if (
      tabs[activeTab] === t({ id: 'search.ai.response', message: 'AI' }) &&
      query !== previousSearch
    ) {
      setPreviousSearch(query)

      const transcriptions = searchResults
        ?.filter(
          (item) =>
            item.type === SearchResultType.Video ||
            item.type === SearchResultType.Article ||
            item.type === SearchResultType.File
        )
        .sort((a, b) => b.score - a.score)
        .map(
          (item) =>
            (item as VideoSearchResult | ArticleSearchResult | FileSearchResult)
              .transcription
        )
      socket.emit('generateAIResponse', {
        query,
        transcriptions:
          transcriptions && transcriptions?.length > 5
            ? transcriptions?.slice(0, 5)
            : transcriptions,
      })
    }
  }, [activeTab, tabs, searchResults])

  const debouncedSearch = useCallback(
    debounce(
      async (query: string) => {
        setLoading(true)

        // Cancel previous request
        abortController.current?.abort()

        // Create a new AbortController instance for the new request
        abortController.current = new AbortController()

        await searchData({
          variables: { query: query },
          context: {
            fetchOptions: {
              signal: abortController.current.signal,
            },
          },
        }).then(({ data }) => {
          if (
            data?.search.isQuestion &&
            data.search.result.some(
              (item) => item.type === SearchResultType.Video
            )
          ) {
            setAIResponseStatus(true)
          } else {
            setAIResponseStatus(false)
          }

          setSearchResults(data?.search.result as SearchResultUnion[])
          setActiveTab('0')
          setLoading(false)
        })
      },
      500,
      {
        leading: true,
      }
    ),
    [searchData]
  )

  const handleKeyDown = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    const inputFocused =
      e.currentTarget.tagName.localeCompare('input', undefined, {
        sensitivity: 'base',
      }) === 0
    const tab = tabs[activeTab]
    const filteredSearchResults =
      tab === t({ id: 'search.all', message: 'Alle resultaten' })
        ? handleAllResultLimit()
        : searchResults?.filter((item) => tab === translations[item.type])

    if (
      e.key === 'Enter' &&
      inputRef.current?.input?.value &&
      inputRef.current?.input?.value.length >= 3
    ) {
      if (!inputFocused && typeof selectedSearchResult === 'number') {
        document
          .querySelector(
            '.search-dropdown-tabs .ant-tabs-tabpane-active .ant-list-item.selected'
          )
          ?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
      } else if (inputFocused) {
        setQuery(inputRef.current?.input?.value)
        debouncedSearch(inputRef.current?.input?.value)
      }
    }

    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault()
      e.stopPropagation()
    }

    if (e.key === 'ArrowDown') {
      setSelectedSearchResult((prev) =>
        typeof prev === 'number'
          ? Math.min(prev + 1, (filteredSearchResults?.length || 1) - 1)
          : 0
      )
    }

    if (e.key === 'ArrowUp') {
      setSelectedSearchResult((prev) =>
        typeof prev === 'number' ? Math.max(prev - 1, 0) : 0
      )
    }

    if (
      e.key === 'Tab' ||
      (!inputFocused && (e.key === 'ArrowRight' || e.key === 'ArrowLeft'))
    ) {
      const currentTab = Number.parseInt(activeTab)
      const forward = e.shiftKey || e.key === 'ArrowLeft' ? -1 : 1
      const newTab = (currentTab + forward) % (tabs?.length || 1)
      if (newTab < 0 || newTab >= (tabs?.length || 0)) return
      setActiveTab(newTab.toString())
      setSelectedSearchResult(undefined)
    }

    if (e.key === 'Escape') {
      if (search !== '') e.stopPropagation()
      setSearch('')
      setSearchResults([])
      setAIResponse('')
      setActiveTab('0')
      setSelectedSearchResult(undefined)
      inputRef.current?.focus()
    }
  }

  const showDropdown = query?.length >= 3 && !!searchResults && !loading
  return (
    <>
      <Input
        ref={inputRef}
        addonBefore={<SearchOutlined />}
        className={`search-modal-input ${
          showDropdown && !loading ? 'open' : ''
        }`}
        size="large"
        placeholder={placeholderText}
        value={search}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => {
          setSelectedSearchResult(undefined)
        }}
      />
      {showDropdown && (
        <CommandBarDropdown
          query={search}
          data={searchResults || []}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabItems}
          onKeyDown={handleKeyDown}
        />
      )}
      {!showDropdown && (
        <div className="search-instructions">
          {loading && (
            <Space>
              <Spin size="small" indicator={<LoadingOutlined spin />} />
              {t({ id: 'search.action.loading', message: 'Aan het zoeken...' })}
            </Space>
          )}
          {!loading && (
            <Trans id="search.instructions">
              Druk op <strong>Enter</strong> om te zoeken
            </Trans>
          )}
        </div>
      )}
    </>
  )
}
