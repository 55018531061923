import { useRoomInfo } from '@livekit/components-react'
import { useMemo } from 'react'

export const useRoomMetadata = () => {
  const { metadata } = useRoomInfo()

  return useMemo<any>(() => {
    if (!metadata) return undefined
    try {
      return JSON.parse(metadata)
    } catch (error) {
      console.error('Failed to parse metadata:', error)
      return undefined
    }
  }, [metadata])
}
