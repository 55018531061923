import { InfoCircleOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Tree,
  Button,
  Dropdown,
  PageHeader,
  Space,
  Affix,
  Alert,
  Modal,
  Badge,
  notification,
  Tooltip,
} from 'antd'
import { useState, useEffect, useCallback, useContext } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  BranchCategoriesQuery,
  BranchCategoryQuery,
  DeleteBranchCategoryMutation,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { useLanguages } from '../../../shared/hooks/use-languages'
import { CategoryModal } from '../../components/categories/CategoryModal'
import {
  CategoryTreeNode,
  useBranchCategoriesTree,
} from '../../hooks/use-category-tree'
import DELETE_CATEGORY_MUTATION from '../../mutations/delete-branch-category.graphql'
import REORDER_CATEGORIES_MUTATION from '../../mutations/reorder-categories.graphql'
import CATEGORY_QUERY from '../../queries/branch-category.graphql'

interface CategoryNode extends CategoryTreeNode {
  hierarchy?: string
}

export const BranchCategories = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const ability = useContext(AbilityContext)
  const {
    data,
    loading: loadingCategories,
    refetch,
  } = useBranchCategoriesTree()
  const [treeData, setTreeData] = useState<CategoryTreeNode[]>([])
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const [movingMode, setMovingMode] = useState(false)
  const { languages } = useLanguages()

  const [updateSubject, setUpdateSubject] =
    useState<Partial<BranchCategoriesQuery['fetchBranchCategories'][0]>>()
  const [deleteSubject, setDeleteSubject] = useState<string | null>(null)

  const [deleteCategory] = useMutation<DeleteBranchCategoryMutation>(
    DELETE_CATEGORY_MUTATION,
    {
      refetchQueries: ['branchCategories'],
    }
  )

  const [fetchCategory] = useLazyQuery<BranchCategoryQuery>(CATEGORY_QUERY)

  useEffect(() => {
    if (data) {
      setTreeData(data)

      const getAllKeys = (nodes: CategoryTreeNode[]): string[] => {
        let keys: string[] = []
        nodes.forEach((node) => {
          keys.push(node.key)
          if (node.children && node.children.length > 0) {
            keys = [...keys, ...getAllKeys(node.children)]
          }
        })
        return keys
      }

      setExpandedKeys(getAllKeys(data))
    }
  }, [data])

  const onDrop = useCallback(
    (info) => {
      if (!treeData) return

      const dropKey = info.node.key as string
      const dragKey = info.dragNode.key as string
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos.at(-1))

      const loop = (
        data: Array<CategoryTreeNode>,
        key: string,
        callback: (
          item: CategoryTreeNode,
          index: number,
          arr: Array<CategoryTreeNode>
        ) => void
      ) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].key === key) {
            return callback(data[i], i, data)
          }
          if (data[i].children) {
            loop(data[i].children, key, callback)
          }
        }
      }

      const data: Array<CategoryTreeNode> = [...treeData]
      let dragObj: CategoryTreeNode | null = null

      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1)
        dragObj = item
      })

      if (!dragObj) return

      if (info.dropToGap) {
        let ar: Array<CategoryTreeNode> = []
        let i: number | undefined
        loop(data, dropKey, (_, index, arr) => {
          ar = arr
          i = index
        })
        if (dropPosition === -1) {
          ar.splice(i!, 0, dragObj)
        } else {
          ar.splice(i! + 1, 0, dragObj)
        }
      } else {
        loop(data, dropKey, (item) => {
          item.children = item.children || []
          if (item.children.length === 0 || dropPosition <= 0) {
            item.children.unshift(dragObj as CategoryTreeNode)
          } else {
            item.children.push(dragObj as CategoryTreeNode)
          }
        })
      }

      setTreeData(data)
      setMovingMode(true)
    },
    [treeData]
  )

  const extractHierarchy = useCallback(
    (items?: CategoryTreeNode[]): { _id: string; hierarchy: string }[] => {
      if (!items) return []

      const result: { _id: string; hierarchy: string }[] = []
      const processItem = (item: CategoryTreeNode, parentHierarchy = ',') => {
        const currentHierarchy = `${parentHierarchy}${item.key},`
        result.push({ _id: item.key, hierarchy: currentHierarchy })

        if (item.children) {
          item.children.forEach((child) => processItem(child, currentHierarchy))
        }
      }

      items.forEach((item) => processItem(item))
      return result
    },
    []
  )

  const [reorderCategories] = useMutation(REORDER_CATEGORIES_MUTATION, {
    refetchQueries: ['branchCategories'],
  })

  const handleSaveReorder = async () => {
    await reorderCategories({
      variables: {
        categories: extractHierarchy(treeData),
      },
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'branch.categories.reorder.success',
            message: 'Volgorde succesvol opgeslagen.',
          }),
        })
      },
      onError: () => {
        notification.error({
          message: t({
            id: 'branch.categories.reorder.error',
            message: 'Volgorde opslaan mislukt.',
          }),
        })
      },
      refetchQueries: ['branchCategories'],
    })

    setMovingMode(false)
  }

  const handleCancelReorder = () => {
    setTreeData(data)
    setMovingMode(false)
  }

  return (
    <div style={{ padding: 32, position: 'relative' }}>
      {movingMode && (
        <Alert
          style={{ position: 'absolute', inset: 0, bottom: 'auto', zIndex: 10 }}
          icon={<InfoCircleOutlined />}
          showIcon
          type="warning"
          message="Je bent de volgorde en indeling van de categorieën aan het aanpassen. Klik op 'Opslaan' om je wijzigingen door te voeren."
          banner
        />
      )}
      <PageHeader
        ghost={false}
        title="Categorieën"
        extra={
          <Space>
            <Button
              hidden={
                !ability.can(
                  PermissionAction.CREATE,
                  PermissionObjectType.BRANCH_COURSE_CATEGORY
                ) || movingMode
              }
              type="primary"
              onClick={() => {
                setUpdateSubject({
                  _id: '',
                  name: '',
                  hierarchy: '',
                  courses: [],
                })

                setModalVisible(true)
              }}
              loading={loadingCategories}
            >
              Nieuwe categorie
            </Button>
            <Affix offsetTop={50}>
              <Space hidden={!movingMode}>
                <Button onClick={handleCancelReorder}>
                  <Trans id="action.cancel">Annuleren</Trans>
                </Button>
                <Button type="primary" onClick={handleSaveReorder}>
                  <Trans id="action.save">Opslaan</Trans>
                </Button>
              </Space>
            </Affix>
          </Space>
        }
      />
      {treeData && (
        <Tree
          blockNode
          showLine
          virtual
          draggable={{
            icon: false,
            nodeDraggable: () =>
              !loadingCategories &&
              ability.can(
                PermissionAction.UPDATE,
                PermissionObjectType.BRANCH_COURSE_CATEGORY
              ),
          }}
          onDragStart={(info) => {
            info.event.dataTransfer.effectAllowed = 'move'
            const img = new Image()
            img.src =
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
            info.event.dataTransfer.setDragImage(img, 0, 0)
          }}
          onDragOver={(info) => {
            info.event.dataTransfer.dropEffect = 'move'
          }}
          onDrop={onDrop}
          selectable={false}
          onExpand={(key) => setExpandedKeys(key.map(String))}
          expandedKeys={expandedKeys}
          treeData={treeData}
          titleRender={(node) => {
            return (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'add',
                      label: t({
                        id: 'category.hierarchy.action.add',
                        message: 'Nieuwe categorie toevoegen',
                      }),
                      onClick: () => {
                        const nodeData = node as CategoryNode
                        setUpdateSubject({
                          _id: '',
                          name: '',
                          hierarchy: nodeData.hierarchy,
                          courses: [],
                        })

                        setModalVisible(true)
                      },
                    },
                    {
                      key: 'edit',
                      label: t({
                        id: 'category.hierarchy.action.edit',
                        message: 'Categorie bewerken',
                      }),
                      onClick: async () => {
                        const cat = await fetchCategory({
                          variables: { id: node.key },
                        }).then((result) => result.data?.fetchBranchCategory)
                        setUpdateSubject(cat)
                        setModalVisible(true)
                      },
                    },
                    ...(ability.can(
                      PermissionAction.TRANSLATE,
                      PermissionObjectType.BRANCH_COURSE_CATEGORY
                    )
                      ? [
                          {
                            type: 'divider' as const,
                          },
                          {
                            key: 'translate',
                            label: t({
                              id: 'settings.categories.action.translate',
                              message: 'Vertalen',
                            }),
                            children: languages
                              .filter((lang) => lang.code !== node.language)
                              .map((lang) => ({
                                key: `translate-${lang.code}`,
                                label: (
                                  <Space>
                                    {lang.name}
                                    {node.translations?.some(
                                      (t: { language: string }) =>
                                        t.language === lang.code
                                    ) && <Badge status="success" />}
                                  </Space>
                                ),
                                onClick: () => {
                                  window.location.href = `/settings/categories/translate/${node.key}/${lang.code}`
                                },
                              })),
                          },
                        ]
                      : []),
                    {
                      type: 'divider' as const,
                    },
                    {
                      key: 'delete',
                      label:
                        node.children?.length > 0 ? (
                          <Tooltip
                            title={t({
                              id: 'category.hierarchy.action.delete.disabled',
                              message:
                                'Deze categorie kan niet verwijderd worden zolang er subcategorieën onder hangen.',
                            })}
                            placement="bottom"
                          >
                            {t({
                              id: 'action.delete',
                              message: 'Verwijderen',
                            })}
                          </Tooltip>
                        ) : (
                          t({
                            id: 'action.delete',
                            message: 'Verwijderen',
                          })
                        ),
                      onClick: () => setDeleteSubject(node.key as string),
                      disabled: node.children?.length > 0,
                      danger: true,
                    },
                  ],
                }}
                trigger={['contextMenu']}
              >
                <span>{node.title}</span>
              </Dropdown>
            )
          }}
        />
      )}
      <CategoryModal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        subject={updateSubject}
        title={updateSubject?._id ? 'Categorie bewerken' : 'Nieuwe categorie'}
      />
      <Modal
        title={t({
          id: 'settings.categories.action.delete.title',
          message: 'Verwijderen',
        })}
        open={!!deleteSubject}
        onCancel={() => setDeleteSubject(null)}
        onOk={() =>
          deleteCategory({ variables: { id: deleteSubject } }).then(() => {
            refetch()
            setDeleteSubject(null)
          })
        }
        okText={t({
          id: 'action.delete',
          message: 'Verwijderen',
        })}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
      >
        <Trans id="branch.categories.delete.title">
          Ben je zeker dat je deze categorie wil verwijderen?
        </Trans>
      </Modal>
    </div>
  )
}
