import { SafetyCertificateOutlined, DownOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { Dropdown, Menu } from 'antd'
import { ObjectId } from 'bson'
import { useNavigate } from 'react-router-dom'

import {
  Event,
  LiveEvent,
  SetEventActivityCertificationTypeMutation,
} from 'apps/lms-front/src/generated/graphql'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { useAuth } from '../../auth/hooks/use-auth'
import { StartButton } from '../../courses/pages/course-viewer/CourseViewer.style'
import SET_EVENT_CERTIFICATION_TYPE_MUTATION from '../mutations/set-event-certification-type.graphql'

interface Props {
  event: Event & LiveEvent
}

export const EventLoginButton = ({ event }: Props) => {
  const { user } = useAuth()
  const branch = useBranch()
  const navigate = useNavigate()

  const certificationType = event.certificationType?.filter(
    (c) =>
      user?.certificationType?.find((ct) => new ObjectId(ct._id).equals(c._id))
  )

  const [setCertificationType] =
    useMutation<SetEventActivityCertificationTypeMutation>(
      SET_EVENT_CERTIFICATION_TYPE_MUTATION,
      {
        variables: {
          id: event._id,
        },
      }
    )

  const handleEventLogin = async (certificationType?: string) => {
    try {
      await setCertificationType({
        variables: {
          eventId: event._id,
          certificationId: certificationType,
        },
      })
      navigate(`/meet/${event.call_id}`)
    } catch (error) {
      console.error('Failed to attend event:', error)
    }
  }

  if (
    certificationType?.length &&
    certificationType?.length > 1 &&
    !branch?.certificationSettings.allowChoice
  ) {
    const menu = (
      <Menu
        onClick={(info) => handleEventLogin(info.key as string)}
        items={certificationType.map((type) => ({
          key: type._id,
          icon: <SafetyCertificateOutlined />,
          label: type.name,
          value: type._id,
        }))}
      />
    )
    return (
      <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
        <StartButton type="primary">
          <Trans id="events.card.join_event">
            Inloggen op de online sessie
          </Trans>
          <DownOutlined style={{ marginLeft: 4 }} />
        </StartButton>
      </Dropdown>
    )
  }

  // If single certification type, auto-login with that type
  if (certificationType?.length === 1) {
    return (
      <StartButton
        type="primary"
        onClick={() => handleEventLogin(certificationType[0]._id)}
      >
        <Trans id="events.card.join_event">Inloggen op de online sessie</Trans>
      </StartButton>
    )
  }

  // If no certification type, just login without certification
  return (
    <StartButton
      type="primary"
      onClick={() => navigate(`/meet/${event.call_id}`)}
    >
      <Trans id="events.card.join_event">Inloggen op de online sessie</Trans>
    </StartButton>
  )
}
