/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { QuestionCircleOutlined } from '@ant-design/icons'
import { ApolloQueryResult } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  MediaPlayerInstance,
  useMediaRemote,
  useMediaStore,
} from '@vidstack/react'
import { Button, Col, notification, Space, Tooltip } from 'antd'
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react'
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useParams } from 'react-router-dom'
import { NumberParam, useQueryParam } from 'use-query-params'

import { deflate, inflate } from '@lms-shared-patterns/utils'
import {
  PublicUnitQuery,
  UserQuizUnitActivity,
  UserSurveyUnitActivity,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { Player } from 'apps/lms-front/src/modules/shared/components/player/player'
import { QuestionInputModal } from 'apps/lms-front/src/modules/shared/components/question-input-modal/question-input-modal'
import { logActivity } from 'apps/lms-front/src/modules/shared/helpers/log-activity'
import { useInterval } from 'apps/lms-front/src/modules/shared/hooks/use-interval'
import { useSocket } from 'apps/lms-front/src/modules/shared/hooks/use-socket.hook'

import { transformTimestampsInVideoAnnotation } from '../../../helpers/transform-timestamps'
import SUBMIT_UNIT_QUESTION_MUTATION from '../../../mutations/submit-unit-question.graphql'

import { PageHeader } from './../UnitViewer.style'
import { IntermediaryQuestionsModal } from './modals/IntermediaryQuestionsModal'
import { RemoteIntermediaryQuestionsModal } from './modals/RemoteIntermediaryQuestionsModal'
import { VideoProgressModal } from './modals/VideoProgressModal'
import { ALink } from './VideoUnitViewer.style'

import type { DomElement } from 'htmlparser2'

export const VIDEO_UNIT_COMPLETE_PCT = 0.95

type VideoUnit = PublicUnitQuery['fetchUnitById'] & { __typename: 'VideoUnit' }

interface Props {
  unit: VideoUnit
  allowChangingPlaybackRate: boolean
  refetchUnit?: () => Promise<ApolloQueryResult<unknown>>
  handleUnitCompletion: () => void
}

function progressMapReducer(
  state: number[],
  {
    second,
    reset,
    replace,
  }: { second?: number; reset?: number; replace?: number[] }
) {
  if (replace) return replace
  if (reset) return Array.from<number>({ length: reset }).fill(0)
  if (typeof second === 'number' && second >= 0 && state.length > second) {
    const newMap = [...state]
    if (newMap[second]) newMap[second] += 1
    else newMap[second] = 1
    return newMap
  }
  return state
}

export const VideoUnitViewer = ({
  unit,
  allowChangingPlaybackRate,
  refetchUnit,
  handleUnitCompletion,
}: Props) => {
  const { event_id } = useParams()
  const [start] = useQueryParam('start', NumberParam)

  const posthog = usePostHog()
  const playFromBackups = useFeatureFlagEnabled('PlayFromCloudBackups')

  /**
   * Socket connection
   */
  const { token } = useAuth()
  const { connected: wsConnected, emit } = useSocket('/api/activity/ws', {
    token,
    transports: ['polling', 'websocket'],
  })

  /**
   * Progress modal
   */
  const [progressModalVisible, setProgressModalVisible] =
    useState<boolean>(false)

  /**
   * Quiz
   */
  const [
    intermediaryQuestionsModalVisible,
    setIntermediaryQuestionsModalVisible,
  ] = useState<boolean>(false)
  const [questionsAnswered, setQuestionsAnswered] = useState<boolean>(false)

  /**
   * Question modal
   */
  const [questionModalOpen, setQuestionModalOpen] = useState(false)

  /** Video-related */
  const [loading, setLoading] = useState(false)
  const playerRef = useRef<MediaPlayerInstance>(null)
  const { playing, duration: _duration } = useMediaStore(playerRef)
  const duration = useMemo(() => Math.floor(_duration), [_duration])

  const remote = useMediaRemote(playerRef)

  const questions: Array<
    | VideoUnit['videoUnitTranslation']['questions'][0]
    | VideoUnit['videoUnitTranslation']['survey_questions'][0]
  > = useMemo(() => {
    return [
      ...(unit.videoUnitTranslation.questions || []),
      ...(unit.videoUnitTranslation.survey_questions || []),
    ]
  }, [unit])

  const answers: Array<UserSurveyUnitActivity | UserQuizUnitActivity> =
    useMemo(() => {
      return [
        ...(unit.my_activity?.survey_answers || []),
        ...(unit.my_activity?.quiz_answers || []),
      ]
    }, [unit])

  const [position, setPosition] = useState(
    start
      ? start / 1000
      : unit.my_activity?.completed
      ? 0
      : unit.my_activity?.video_progress?.last_position || 0
  )
  const prevSecondRef = useRef<number | undefined>(
    unit.my_activity?.video_progress?.last_position
      ? Math.max(
          Math.ceil(unit.my_activity?.video_progress?.last_position) - 1,
          0
        )
      : undefined
  )
  const [watchedPercentage, setWatchedPercentage] = useState(
    unit.my_activity?.video_progress?.watched_pct || 0
  )

  const [progressMap, mutateProgressMap] = useReducer(progressMapReducer, [])

  useEffect(() => {
    // If the duration is no yet set, nothing needs to happen
    if (duration === 0 || duration === Number.POSITIVE_INFINITY) return

    // If the progress map is already (nearly) the correct length, nothing needs to happen
    if (
      progressMap.length === duration ||
      Math.abs(duration - progressMap.length) < 2
    )
      return

    // If the user has a progress map, inflate it and replace the current progress map
    if (unit.my_activity?.video_progress?.deflated_progress_map) {
      const inflatedProgressMap = inflate(
        unit.my_activity.video_progress.deflated_progress_map,
        duration
      )
      mutateProgressMap({
        replace: [...inflatedProgressMap],
      })
    } else {
      mutateProgressMap({ reset: duration })
    }
  }, [unit, duration, progressMap.length])

  const ping = useCallback(() => {
    if (progressMap.length === 0) return

    const watchedSeconds = progressMap.reduce(
      (prev, cur) => prev + (cur > 0 ? 1 : 0),
      0
    )
    const _watchedPercentage = watchedSeconds / (progressMap.length || 1)
    setWatchedPercentage(_watchedPercentage)
    const map = deflate(Uint8Array.from(progressMap))

    if (wsConnected)
      emit('activity', {
        event_id,
        unit_id: unit._id,
        video_progress: {
          watched_pct: watchedPercentage,
          last_position: position,
          deflated_progress_map: map,
        },
      })
    else
      logActivity({
        event_id,
        unit_id: unit._id,
        video_progress: {
          watched_pct: watchedPercentage,
          last_position: position,
          deflated_progress_map: map,
        },
      })
  }, [
    position,
    progressMap,
    watchedPercentage,
    unit._id,
    wsConnected,
    event_id,
  ])

  const handleProgressReset = () => {
    const length = progressMap.length
    mutateProgressMap({ reset: length })
    setWatchedPercentage(0)
    setPosition(0)
    remote.seek(0)
    setIntermediaryQuestionsModalVisible(false)
    remote.play()

    /** reset immediately */
    logActivity({
      event_id,
      unit_id: unit._id,
      video_progress: {
        watched_pct: 0,
        last_position: 0,
        deflated_progress_map: null,
      },
    })
  }

  const handleIntermediaryQuestions = () => {
    if (questions.length === 0) return
    const no_of_questions = questions.length

    if (
      answers?.filter((answer) => !!answer.submitted).length === no_of_questions
    )
      return

    if (questionsAnswered) return

    remote.pause()
    remote.exitFullscreen()
    remote.exitPictureInPicture()

    setIntermediaryQuestionsModalVisible(true)
  }

  const finishUpIntermediaryQuestions = () => {
    setQuestionsAnswered(true)
    setIntermediaryQuestionsModalVisible(false)

    if (playFromBackups) {
      goToUnwatchedPart()
    } else {
      remote.play()
    }
  }

  useEffect(() => {
    if (watchedPercentage >= unit.questions_trigger_pct) {
      handleIntermediaryQuestions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedPercentage])

  const handlePlayerReady = () => {
    posthog.capture('video_player_ready', {
      unit_id: unit._id,
      unit_name: unit.name,
      course_id: unit.course_id,
    })
  }

  const handleProgress = ({ playedSeconds }: { playedSeconds: number }) => {
    const _currentTime = playedSeconds
    const _secondViewed = Math.max(0, Math.ceil(_currentTime) - 1)
    if (_secondViewed !== prevSecondRef.current)
      mutateProgressMap({
        second: _secondViewed,
      })
    prevSecondRef.current = _secondViewed

    setPosition(_currentTime)
  }

  const handleEnded = (video: MediaPlayerInstance) => {
    posthog.capture('video_player_ended', {
      unit_id: unit._id,
      unit_name: unit.name,
      course_id: unit.course_id,
    })
    const _currentTime = video.currentTime
    if (_currentTime) {
      const _secondViewed = Math.max(0, Math.floor(_currentTime))
      if (_secondViewed !== prevSecondRef.current)
        mutateProgressMap({ second: _secondViewed })
      setPosition(_currentTime)
    }
  }

  useEffect(() => {
    if (playerRef.current && !playing && position > 0) ping()
  }, [playerRef, position, ping, playing])

  useInterval(() => ping(), playerRef.current && playing ? 3000 : null)

  const goToUnwatchedPart = () => {
    const unwatchedPart = progressMap.indexOf(0)
    remote.seek(unwatchedPart - 1)
  }

  return (
    <Col
      xs={24}
      style={{ textAlign: 'center', maxWidth: '100%', overflow: 'hidden' }}
    >
      <Space>
        <PageHeader
          title={unit.videoUnitTranslation.name}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        />
        <Tooltip
          placement="topLeft"
          title={t({
            id: 'unit.viewer.video.ask_question.tooltip',
            message: 'Een vraag stellen',
          })}
          arrowPointAtCenter
        >
          <QuestionCircleOutlined onClick={() => setQuestionModalOpen(true)} />
        </Tooltip>
      </Space>
      <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
        {(unit.cf_stream?.uid || unit.external_url) && (
          <Player
            key={`sequentiality-type-${allowChangingPlaybackRate}`}
            meta={{ cf_stream_id: unit.cf_stream?.uid || '' }}
            playerRef={playerRef}
            src={
              unit.cf_stream?.signedBucketURL && playFromBackups
                ? {
                    url: unit.cf_stream?.signedBucketURL,
                  }
                : unit.cf_stream?.uid
                ? {
                    hls: unit.cf_stream?.playback.hls,
                  }
                : {
                    url: unit.external_url || '',
                  }
            }
            castSrc={unit.cf_stream?.signedBucketURL || undefined}
            enablePlaybackRates={allowChangingPlaybackRate}
            onReady={handlePlayerReady}
            onProgress={handleProgress}
            onEnded={handleEnded}
            lastPosition={unit.my_activity?.video_progress?.last_position}
            thumbnails={unit.cf_stream?.thumbnailsURL || undefined}
            textTracks={unit.cf_stream?.textTracks || []}
          ></Player>
        )}
        {unit.annotation && (
          <article style={{ textAlign: 'left' }}>
            {
              ReactHtmlParser(unit.annotation, {
                // @ts-ignore
                transform: (node: DomElement, index: number) =>
                  transformTimestampsInVideoAnnotation(
                    node,
                    index,
                    (seconds) => {
                      if (playerRef.current) {
                        playerRef.current.currentTime = seconds
                      }
                    }
                  ),
              }) as ReactNode
            }
          </article>
        )}
        {watchedPercentage >= VIDEO_UNIT_COMPLETE_PCT ||
        unit.my_activity?.completed ? (
          <>
            {unit.next || !unit.course ? (
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  setLoading(true)

                  // Ensure final activity is logged and wait for completion
                  logActivity({
                    event_id,
                    unit_id: unit._id,
                    video_progress: {
                      watched_pct: watchedPercentage,
                      last_position: position >= 1 ? position : undefined,
                      deflated_progress_map: deflate(
                        Uint8Array.from(progressMap)
                      ),
                    },
                  })
                    .then(() => handleUnitCompletion())
                    .catch((error) => {
                      console.error('Failed to log final activity:', error)
                      notification.error({
                        message: t({
                          id: 'unit.viewer.error.failed_to_save_progress',
                          message:
                            'Failed to save your progress. Please try again.',
                        }),
                      })
                    })
                    .finally(() => setLoading(false))
                }}
              >
                <Trans id="unit.viewer.action.video_complete">
                  Afgerond. Laten we verder gaan.
                </Trans>
              </Button>
            ) : (
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  setLoading(true)

                  // Ensure final activity is logged and wait for completion
                  logActivity({
                    event_id,
                    unit_id: unit._id,
                    video_progress: {
                      watched_pct: watchedPercentage,
                      last_position: position,
                      deflated_progress_map: deflate(
                        Uint8Array.from(progressMap)
                      ),
                    },
                  })
                    .then(() => handleUnitCompletion())
                    .catch((error) => {
                      console.error('Failed to log final activity:', error)
                      notification.error({
                        message: t({
                          id: 'unit.viewer.error.failed_to_save_progress',
                          message:
                            'Failed to save your progress. Please try again.',
                        }),
                      })
                    })
                    .finally(() => setLoading(false))
                }}
              >
                <Trans id="unit.viewer.action.complete_course">
                  Opleiding voltooien
                </Trans>
              </Button>
            )}
          </>
        ) : (
          <>
            <Button type="primary" disabled>
              <Trans id="unit.viewer.action.go_to_next_unit">
                Naar het volgende deel
              </Trans>
            </Button>
            <ALink
              style={{ fontSize: 14, cursor: 'pointer', marginTop: -12 }}
              onClick={() => setProgressModalVisible(true)}
            >
              <QuestionCircleOutlined
                style={{ fontSize: 12, marginRight: 6 }}
              />
              <Trans id="unit.viewer.video.action.open_progress_modal">
                Niet duidelijk waarom je nog niet verder kan?
              </Trans>
            </ALink>
            <VideoProgressModal
              open={progressModalVisible}
              onClose={() => setProgressModalVisible(false)}
              allowChangingPlaybackRate={allowChangingPlaybackRate}
              seekToUnwatchedPart={goToUnwatchedPart}
              viewMap={progressMap}
            />
          </>
        )}
      </Space>
      {event_id && (
        <RemoteIntermediaryQuestionsModal
          event_id={event_id}
          open={intermediaryQuestionsModalVisible}
          unit={unit}
          onFinish={finishUpIntermediaryQuestions}
          questions={questions}
        />
      )}
      {!event_id && (
        <IntermediaryQuestionsModal
          open={intermediaryQuestionsModalVisible}
          unit={unit}
          refetchUnit={refetchUnit}
          onFinish={finishUpIntermediaryQuestions}
          onResetRequested={handleProgressReset}
          questions={questions}
        />
      )}

      <QuestionInputModal
        open={questionModalOpen}
        onClose={() => setQuestionModalOpen(false)}
        id={unit._id}
        mutation={SUBMIT_UNIT_QUESTION_MUTATION}
        title={t({
          id: 'unit.viewer.video.question_modal.title',
          message: 'Stel een vraag over dit onderdeel',
        })}
        placeholder={t({
          id: 'unit.viewer.question_modal.placeholder',
          message: 'Je vraag',
        })}
        successMessage={t({
          id: 'unit.viewer.question_modal.success',
          message: 'Je vraag is succesvol verzonden!',
        })}
        errorMessage={t({
          id: 'unit.viewer.question_modal.failed',
          message: 'Er ging iets mis. Probeer het later opnieuw.',
        })}
      />
    </Col>
  )
}
