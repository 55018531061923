import { useMutation, useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { Form, Input, Modal, notification, Transfer } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { test } from 'fuzzyjs'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  BranchCategoriesQuery,
  CreateBranchCategoryMutation,
  UpdateBranchCategoryMutation,
} from 'apps/lms-front/src/generated/graphql'

import CREATE_CATEGORY_MUTATION from '../../mutations/create-branch-category.graphql'
import UPDATE_CATEGORY_MUTATION from '../../mutations/update-branch-category.graphql'

import CATEGORIES_QUERY from './../../queries/branch-categories.graphql'

interface CategoryModalProps {
  open: boolean
  onClose: () => void
  subject?: Partial<BranchCategoriesQuery['fetchBranchCategories'][0]>
  title: string
}

export const CategoryModal = ({
  open,
  onClose,
  title,
  subject,
}: CategoryModalProps) => {
  const [form] = useForm()
  const [selectedCourses, setSelectedCourses] = useState<string[]>([])

  const { data } = useQuery<BranchCategoriesQuery>(CATEGORIES_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  /** Memoized courses to avoid unnecessary re-renders */
  const availableCourses = useMemo(
    () => data?.fetchBranchAccessibleCourses.results || [],
    [data]
  )

  const [createCategory] = useMutation<CreateBranchCategoryMutation>(
    CREATE_CATEGORY_MUTATION,
    {
      refetchQueries: ['branchCategories'],
    }
  )

  const [updateCategory] = useMutation<UpdateBranchCategoryMutation>(
    UPDATE_CATEGORY_MUTATION,
    {
      refetchQueries: ['branchCategories'],
    }
  )

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        name: subject?.name || '',
      })
      setSelectedCourses(subject?.courses || [])
    } else {
      form.resetFields()
    }
  }, [open, subject, form])

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()

      await (subject?._id
        ? updateCategory({
            variables: {
              id: subject._id,
              name: values.name,
              courses: selectedCourses,
              hierarchy: subject.hierarchy,
            },
            onCompleted: () => {
              notification.success({
                message: t({
                  id: 'branch.categories.update.success',
                  message: 'Categorie succesvol gewijzigd',
                }),
              })
            },
          })
        : createCategory({
            variables: {
              name: values.name,
              courses: selectedCourses,
              hierarchy: subject?.hierarchy || undefined,
            },
            onCompleted: () => {
              notification.success({
                message: t({
                  id: 'branch.categories.create.success',
                  message: 'Categorie succesvol aangemaakt',
                }),
              })
            },
          }))

      onClose()
    } catch (error) {
      console.error('Failed to submit:', error)
    }
  }

  const filterOption = useCallback(
    (inputValue: string, option: { _id: string; name: string }) =>
      test(inputValue, option.name, { caseSensitive: false }),
    []
  )

  return (
    <Modal
      width={640}
      title={title}
      open={open}
      onCancel={onClose}
      onOk={handleSubmit}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Naam"
          name="name"
          rules={[{ required: true, message: 'Naam is verplicht' }]}
        >
          <Input />
        </Form.Item>
        <Transfer
          operationStyle={{ animation: 'none' }}
          style={{ height: 300 }}
          listStyle={{ width: 275, height: 300 }}
          showSearch
          dataSource={availableCourses}
          filterOption={filterOption}
          targetKeys={selectedCourses}
          render={(record: (typeof availableCourses)[0]) => record.name}
          onChange={(courses) => setSelectedCourses(courses)}
          rowKey={(record: (typeof availableCourses)[0]) => record._id}
          titles={[
            t({
              id: 'branch.categories.form.transfer.course',
              message: 'Basisopleidingen',
            }),
            t({
              id: 'branch.categories.form.transfer.selected',
              message: 'Geselecteerd',
            }),
          ]}
          pagination={{
            pageSize: 100,
          }}
        />
      </Form>
    </Modal>
  )
}
