import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Modal,
  Form,
  Input,
  Switch,
  InputNumber,
  Popconfirm,
  Button,
  Tabs,
  Radio,
  Col,
  Row,
  Card,
  Tooltip,
  notification,
} from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'

import {
  AssignmentStep,
  CreatePathAssignmentStepInput,
  StepCategory,
  StepReferenceType,
  StepUnitKind,
} from 'apps/lms-front/src/generated/graphql'

import DatePicker from '../../shared/components/date-picker/DatePicker'
import { RichEditor } from '../../shared/components/rich-editor/RichEditor'
import {
  errorNotifierFn,
  validationErrorNotifierFn,
} from '../../shared/helpers/error-notifier'
import { LinkedUnitPicker } from '../components/LinkedUnitPicker'
import { StepEditQuizUnit } from '../components/StepEditQuizUnit'
import { StepEditSurveyUnit } from '../components/StepEditSurveyUnit'
import { usePath } from '../contexts/PathContext'
import DELETE_PATH_STEP from '../mutations/delete-path-step.graphql'
import UPDATE_PATH_ASSIGNMENT_STEP from '../mutations/update-path-assignment-step.graphql'

export const EditAssignmentStepModal = ({
  open,
  onClose,
  onSubmit,
  step,
}: {
  open: boolean
  onClose: () => void
  onSubmit?: (values: CreatePathAssignmentStepInput) => void
  step?: AssignmentStep
  moduleId?: string
}) => {
  const { isDateBeforeTodayOrStartDate, isDateWithinAvailableRange } = usePath()
  const [form] = Form.useForm()
  const unit_kind = Form.useWatch('unit_kind', form)
  const reference_type = Form.useWatch('reference_type', form)
  const optional = Form.useWatch('optional', form)

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        optional: false,
        category: StepCategory.AssignmentStep,
        ...step,
        deadline: step?.deadline ? dayjs(step.deadline) : undefined,
        available_from: step?.available_from
          ? dayjs(step.available_from)
          : undefined,
      })
    } else {
      form.resetFields()
    }
  }, [form, open, step])

  const [updateAssignmentStep] = useMutation(UPDATE_PATH_ASSIGNMENT_STEP, {
    refetchQueries: ['path'],
  })

  const [deleteStep] = useMutation(DELETE_PATH_STEP, {
    refetchQueries: ['path'],
  })

  const handleSubmit = async () => {
    const values = await form.validateFields().catch(validationErrorNotifierFn)

    if (!values) {
      return
    }

    const input = {
      ...values,
      category: StepCategory.AssignmentStep,
      deadline: values.optional ? null : values.deadline,
    }

    if (step?._id) {
      await updateAssignmentStep({
        variables: { step_id: step._id, input },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'step.assignment.edit.success',
              message: 'Opdracht succesvol bijgewerkt',
            }),
          })
          onClose()
        })
        .catch(errorNotifierFn)
    } else if (onSubmit) {
      onSubmit(input)
    }
  }

  const handleDelete = async () => {
    if (step?._id) {
      await deleteStep({
        variables: { step_id: step._id },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'step.assignment.delete.success',
              message: 'Opdracht succesvol verwijderd',
            }),
          })
          onClose()
        })
        .catch(errorNotifierFn)
    }
  }

  return (
    <Modal
      title={
        step ? (
          <Trans id="step.assignment.edit.title">Stap bewerken</Trans>
        ) : (
          <Trans id="step.assignment.add.title">Opdracht toevoegen</Trans>
        )
      }
      bodyStyle={{ paddingTop: 16 }}
      maskClosable={false}
      destroyOnClose={true}
      width={800}
      open={open}
      onCancel={() => {
        onClose()
      }}
      onOk={handleSubmit}
      footer={[
        step && (
          <Popconfirm
            key="delete"
            title={
              <Trans id="step.assignment.delete.confirm">
                Weet je zeker dat je deze opdracht wilt verwijderen?
              </Trans>
            }
            okText={<Trans id="action.yes">Ja</Trans>}
            cancelText={<Trans id="action.no">Nee</Trans>}
            onConfirm={handleDelete}
          >
            <Button danger>
              <Trans id="action.delete">Verwijderen</Trans>
            </Button>
          </Popconfirm>
        ),
        !step && (
          <Button key="cancel" onClick={onClose}>
            <Trans id="action.cancel">Annuleren</Trans>
          </Button>
        ),
        <Button key="submit" type="primary" onClick={handleSubmit}>
          <Trans id="action.save">Opslaan</Trans>
        </Button>,
      ].filter(Boolean)}
    >
      <Form form={form} layout="vertical">
        <Tabs
          type="card"
          destroyInactiveTabPane={false}
          items={[
            {
              key: 'details',
              label: <Trans id="step.assignment.details.title">Details</Trans>,
              forceRender: true,
              children: (
                <>
                  <Row gutter={16}>
                    <Col span={20}>
                      <Form.Item
                        name="name"
                        label={
                          <Trans id="step.assignment.field.name">Naam</Trans>
                        }
                        rules={[
                          {
                            required: true,
                            message: t({
                              id: 'step.assignment.field.validation.name',
                              message: 'Gelieve een naam in te vullen',
                            }),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name="optional"
                        valuePropName="checked"
                        label={
                          <Trans id="step.assignment.field.optional">
                            Optioneel
                          </Trans>
                        }
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="description"
                    hidden
                    label={
                      <Trans id="step.assignment.field.description">
                        Beschrijving
                      </Trans>
                    }
                  >
                    <RichEditor disableTextStyles />
                  </Form.Item>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name="duration"
                        label={
                          <Trans id="step.assignment.field.duration">
                            Duurtijd
                          </Trans>
                        }
                      >
                        <InputNumber
                          min={1}
                          style={{ width: '100%' }}
                          addonAfter={<Trans id="time.minutes">minuten</Trans>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="available_from"
                        label={
                          <Trans id="step.assignment.field.available_from">
                            Beschikbaar vanaf
                          </Trans>
                        }
                      >
                        <DatePicker
                          disabledDate={(currentDate) => {
                            if (!dayjs.isDayjs(currentDate)) return true

                            return !isDateWithinAvailableRange(
                              currentDate,
                              form.getFieldValue('deadline')
                            )
                          }}
                          showTime
                          showSecond={false}
                          format="D MMMM YYYY - HH:mm"
                          minuteStep={15}
                          secondStep={60}
                          style={{ width: '100%' }}
                          onSelect={(value) => {
                            form.setFieldValue('available_from', value)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        hidden={optional}
                        name="deadline"
                        label={
                          <Trans id="step.assignment.field.deadline">
                            Deadline
                          </Trans>
                        }
                      >
                        <DatePicker
                          disabledDate={isDateBeforeTodayOrStartDate}
                          showTime
                          showSecond={false}
                          format="D MMMM YYYY - HH:mm"
                          minuteStep={15}
                          secondStep={60}
                          style={{ width: '100%' }}
                          onSelect={(value) => {
                            form.setFieldValue('deadline', value)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              key: 'content',
              label: <Trans id="step.assignment.content.title">Inhoud</Trans>,
              forceRender: true,
              children: (
                <>
                  <Form.Item
                    hidden={
                      reference_type === StepReferenceType.CustomUnit &&
                      !!unit_kind
                    }
                    name="reference_type"
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'step.assignment.field.validation.reference_type',
                          message: 'Selecteer een type',
                        }),
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        form.setFieldValue('reference_type', e.target.value)
                        form.setFieldValue('unit_kind', undefined)
                        form.setFieldValue('linked_unit_id', undefined)
                        form.setFieldValue('linked_unit_duration', undefined)
                      }}
                    >
                      <Radio value={StepReferenceType.LinkedUnit}>
                        <Trans id="step.assignment.reference_type.linked">
                          Bestaand onderdeel koppelen
                        </Trans>
                      </Radio>
                      <Radio value={StepReferenceType.CustomUnit}>
                        <Trans id="step.assignment.reference_type.custom">
                          Nieuw onderdeel
                        </Trans>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    hidden={
                      reference_type !== StepReferenceType.CustomUnit ||
                      !!unit_kind
                    }
                    name="unit_kind"
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'step.assignment.field.validation.unit_kind',
                          message: 'Selecteer een type',
                        }),
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Radio.Group>
                      <Radio value={StepUnitKind.QuizUnit}>
                        <Trans id="step.assignment.unit_kind.quiz">Toets</Trans>
                        <Tooltip
                          title={t({
                            id: 'step.assignment.unit_kind.quiz.tooltip',
                            message:
                              'Een TOETS is een test met meerkeuzevragen die wordt gebruikt om de kennis van de gebruiker te beoordelen.',
                          })}
                        >
                          <QuestionCircleOutlined
                            style={{ fontSize: '12px', marginLeft: 4 }}
                          />
                        </Tooltip>
                      </Radio>
                      <Radio value={StepUnitKind.SurveyUnit}>
                        <Trans id="step.assignment.unit_kind.survey">
                          Enquête
                        </Trans>
                        <Tooltip
                          title={t({
                            id: 'step.assignment.unit_kind.survey.tooltip',
                            message:
                              'Een ENQUÊTE wordt ingezet om feedback van de gebruiker te verzamelen, via meerkeuzevragen of open vragen.',
                          })}
                        >
                          <QuestionCircleOutlined
                            style={{ fontSize: '12px', marginLeft: 4 }}
                          />
                        </Tooltip>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {reference_type === StepReferenceType.CustomUnit && (
                    <>
                      {unit_kind === StepUnitKind.QuizUnit &&
                        reference_type === StepReferenceType.CustomUnit && (
                          <Card
                            title={t({
                              id: 'step.assignment.unit_kind.quiz',
                              message: 'Toets',
                            })}
                            size="small"
                            extra={
                              <Tooltip
                                placement="left"
                                title={t({
                                  id: 'action.delete',
                                  message: 'Verwijderen',
                                })}
                              >
                                <Button
                                  type="text"
                                  icon={<DeleteOutlined />}
                                  shape="circle"
                                  onClick={() => {
                                    form.setFieldsValue({
                                      unit_kind: undefined,
                                      reference_type: undefined,
                                    })
                                  }}
                                />
                              </Tooltip>
                            }
                          >
                            <StepEditQuizUnit
                              form={form}
                              step={step as AssignmentStep}
                            />
                          </Card>
                        )}
                      {unit_kind === StepUnitKind.SurveyUnit &&
                        reference_type === StepReferenceType.CustomUnit && (
                          <Card
                            title={t({
                              id: 'step.assignment.unit_kind.survey',
                              message: 'Enquête',
                            })}
                            size="small"
                            extra={
                              <Tooltip
                                placement="left"
                                title={t({
                                  id: 'action.delete',
                                  message: 'Verwijderen',
                                })}
                              >
                                <Button
                                  type="text"
                                  icon={<DeleteOutlined />}
                                  shape="circle"
                                  onClick={() => {
                                    form.setFieldsValue({
                                      unit_kind: undefined,
                                      reference_type: undefined,
                                    })
                                  }}
                                />
                              </Tooltip>
                            }
                          >
                            <StepEditSurveyUnit
                              form={form}
                              step={step as AssignmentStep}
                            />
                          </Card>
                        )}
                    </>
                  )}
                  {reference_type === StepReferenceType.LinkedUnit && (
                    <LinkedUnitPicker
                      form={form}
                      step={step as AssignmentStep}
                    />
                  )}
                </>
              ),
            },
          ]}
        />
      </Form>
    </Modal>
  )
}
