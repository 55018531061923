import {
  AlertOutlined,
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  StopOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { subject } from '@casl/ability'
import { Plural, t, Trans } from '@lingui/macro'
import {
  Alert,
  Button,
  Divider,
  Empty,
  Form,
  List,
  notification,
  Modal,
  Popconfirm,
  Space,
  Tabs,
  TabsProps,
  Tooltip,
  Upload,
  Progress,
} from 'antd'
import axios, { AxiosError } from 'axios'
import dayjs from 'dayjs'
import Fuse from 'fuse.js'
import download from 'js-file-download'
import { cloneDeep } from 'lodash-es'
import { useContext, useMemo, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { EventLocationType } from '@lms-shared-patterns/enums/event.enums'
import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  CancelLiveEventMutation,
  DeleteEventAttachmentMutation,
  DeleteLiveEventMutation,
  LiveEventQuery,
  LiveEventsQuery,
  Participant,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../auth/components/Can'
import { LoadSection } from '../../core/components/LoadScreen'
import { AttachmentName } from '../../courses/pages/course-viewer/CourseViewer'
import { HoverMenu } from '../../courses/pages/course-viewer/CourseViewer.style'
import { purgeProperties } from '../../units/helpers/purge-properties'
import { useEventStatus } from '../hooks/use-event-status.hook'
import { useUserEventRegistrationStatus } from '../hooks/use-user-event-registration-status.hook'
import CANCEL_LIVE_EVENT_MUTATION from '../mutations/cancel-live-event.graphql'
import DELETE_EVENT_ATTACHMENT_MUTATION from '../mutations/delete-event-attachment.graphql'
import DELETE_LIVE_EVENT_MUTATION from '../mutations/delete-live-event.graphql'
import SEND_EMAIL_TO_USERS_WHEN_ATTACHMENT_ADDED_MUTATION from '../mutations/send-email-when-attachment-added.graphql'
import LIVE_EVENT_QUERY from '../queries/live-event.graphql'
import { sortParticipantList } from '../utils/sort-participant-list'

import { AddEventParticipantsModal } from './AddEventParticipantsModal'
import { DownloadEventAttendanceQr } from './DownloadEventAttendanceQr'
import { EventActions } from './EventActions'
import { EventModalWrapper } from './EventCard.style'
import { EventHeader } from './EventHeader'
import { EventRecordings } from './EventRecordings'
import { EventStatus } from './EventStatus'
import { ParticipantListItem } from './ParticipantListItem'

export const EventModal = ({
  event,
  onModalClose,
  modalOpen: modalOpened,
}: {
  event: LiveEventsQuery['fetchLiveEvents']['results'][0]
  onModalOpen?: (
    event: LiveEventsQuery['fetchLiveEvents']['results'][0]
  ) => void
  onModalClose?: () => void
  modalOpen?: boolean
}) => {
  const ability = useContext(AbilityContext)
  const navigate = useNavigate()
  const [addParticipantsModalVisible, setAddParticipantsModalVisible] =
    useState(false)

  const [deleteAttachment, { loading: deletingAttachment }] =
    useMutation<DeleteEventAttachmentMutation>(DELETE_EVENT_ATTACHMENT_MUTATION)

  const [cancelSession, { loading: cancelling }] =
    useMutation<CancelLiveEventMutation>(CANCEL_LIVE_EVENT_MUTATION, {
      variables: {
        id: event._id,
      },
      refetchQueries: ['liveEvents'],
      onCompleted: () => {
        notification.info({
          message: t({
            id: 'event.delete.cancelled',
            message: 'Sessie succesvol geannuleerd',
          }),
        })
      },
    })

  const [deleteSession, { loading: deleting }] =
    useMutation<DeleteLiveEventMutation>(DELETE_LIVE_EVENT_MUTATION, {
      variables: {
        id: event._id,
      },
      refetchQueries: ['liveEvents'],
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'events.delete.success',
            message: 'Sessie succesvol verwijderd',
          }),
        })
        onModalClose?.()
      },
    })

  const [sendEmailToUsersWhenAttachmentAdded] = useMutation(
    SEND_EMAIL_TO_USERS_WHEN_ATTACHMENT_ADDED_MUTATION
  )

  const {
    remaining,
    hasReachedCapacity,
    hasExceededCapacity,
    cancelled,
    ended,
  } = useEventStatus(event)
  const { canRegister, canAdminRegister, canCancel, canJoinCall } =
    useUserEventRegistrationStatus(event)

  const canReadEventRegistrations =
    ability.can(
      PermissionAction.READ,
      PermissionObjectType.BRANCH_LIVE_EVENT_REGISTRATION
    ) ||
    ability.can(
      PermissionAction.READ,
      subject(PermissionObjectType.APPOINTED_LIVE_EVENT_REGISTRATION, {
        ...event,
        lecturers: event.lecturers,
      })
    )

  const canReadPathRegistrations = ability.can(
    PermissionAction.READ,
    PermissionObjectType.BRANCH_LEARNING_PATH_PARTICIPANTS
  )

  const canReadRecordings = ability.can(
    PermissionAction.READ,
    PermissionObjectType.BRANCH_LIVE_EVENT_RECORDINGS
  )

  const canReadRegistrations = event.path
    ? canReadPathRegistrations
    : canReadEventRegistrations

  const canDuplicateEvent = ability.can(
    PermissionAction.CREATE,
    PermissionObjectType.LIVE_EVENT
  )

  const canUpdateEvent =
    ability.can(
      PermissionAction.UPDATE,
      subject(PermissionObjectType.LIVE_EVENT, { ...event })
    ) ||
    ability.can(
      PermissionAction.UPDATE,
      subject(PermissionObjectType.BRANCH_LIVE_EVENT, { ...event })
    )

  const canCancelLiveEvent =
    canUpdateEvent && !event.cancelled && !event.path && !ended

  const canUpdateLearningPath =
    ability.can(
      PermissionAction.UPDATE,
      subject(PermissionObjectType.BRANCH_LEARNING_PATH, { ...event.path })
    ) ||
    ability.can(PermissionAction.UPDATE, PermissionObjectType.LEARNING_PATH)

  const canUpdateAsAppointedLecturer = ability.can(
    PermissionAction.UPDATE,
    subject(PermissionObjectType.APPOINTED_LIVE_EVENT, {
      event,
      lecturers: event.lecturers || [],
    })
  )

  const canUpdate =
    !ended && (event.path ? canUpdateLearningPath : canUpdateEvent)

  const canDeleteEvent =
    ability.can(
      PermissionAction.DELETE,
      subject(PermissionObjectType.LIVE_EVENT, { ...event })
    ) ||
    ability.can(
      PermissionAction.DELETE,
      subject(PermissionObjectType.BRANCH_LIVE_EVENT, { ...event })
    )

  const canDelete = event.path ? false : canDeleteEvent

  const { data, loading, refetch } = useQuery<LiveEventQuery>(
    LIVE_EVENT_QUERY,
    {
      skip: !modalOpened,
      variables: {
        id: event._id,
      },
    }
  )

  const participants = useMemo(
    () =>
      [...(data?.fetchLiveEventById.participants || [])].sort((a, b) =>
        sortParticipantList(a as Participant, b as Participant)
      ),
    [data]
  )

  const [searchQuery, setSearchQuery] = useState('')
  const fuse = useMemo(
    () =>
      new Fuse(
        participants.map((p) => ({
          ...p,
          name: `${p.firstName} ${p.lastName} ${p.firstName}`,
        })),
        {
          keys: ['name'],
          threshold: 0.3,
          ignoreLocation: true,
        }
      ),
    [participants]
  )

  const handleSessionDelete = () => {
    if (ended) {
      Modal.confirm({
        title: t({
          id: 'event.detail.action.delete.past_event.title',
          message: 'Je staat op het punt een afgelopen sessie te verwijderen.',
        }),
        content: (
          <div>
            {t({
              id: 'event.detail.action.delete.past_event.content',
              message:
                'Deze actie verwijdert eveneens alle rapporten die zijn gegenereerd voor deze sessie.',
            })}
            <p style={{ marginTop: 20 }}>
              {t({
                id: 'event.detail.action.delete.past_event.confirm',
                message: 'Weet je zeker dat je wilt doorgaan?',
              })}
            </p>
          </div>
        ),
        okText: t({
          id: 'action.delete',
          message: 'Verwijderen',
        }),
        cancelText: t({
          id: 'action.cancel',
          message: 'Annuleren',
        }),
        okButtonProps: { loading: deleting },
        onOk: () => deleteSession(),
      })
    } else {
      deleteSession()
    }
  }

  const filteredParticipants = useMemo(() => {
    if (!searchQuery.trim()) return participants
    return fuse.search(searchQuery.trim()).map((result) => result.item)
  }, [searchQuery, participants, fuse])

  const sendNotificationEmail = async (key: string) => {
    try {
      const response = await sendEmailToUsersWhenAttachmentAdded({
        variables: { id: event._id },
      })
      if (response.data) {
        notification.close(key)
        notification.success({
          message: t({
            id: 'events.attachments.send_email_to_users_when_attachment_added.success',
            message: 'Notificatie verstuurd',
          }),
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const closeModal = () => {
    onModalClose?.()
  }

  const handleDuplicate = async () => {
    try {
      const duplicatedEvent = cloneDeep(data?.fetchLiveEventById)

      if (duplicatedEvent) {
        purgeProperties(duplicatedEvent, [
          'code',
          'created',
          'updated',
          'participants',
          'published',
        ])
        duplicatedEvent._id = undefined
        duplicatedEvent.title = `${duplicatedEvent.title} ${t({
          id: 'events.duplicate.suffix',
          message: '(kopie)',
        })}`

        navigate('/events/new', {
          state: {
            duplicatedEvent: {
              data: duplicatedEvent,
              date: data?.fetchLiveEventById.start,
              certificationType:
                data?.fetchLiveEventById.certificationType?.map(
                  (type) => type._id
                ) || [],
              category:
                data?.fetchLiveEventById.category?.map((cat) => cat._id) || [],
              registration_deadline:
                data?.fetchLiveEventById.registration_deadline,
            },
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const [api, contextHolder] = notification.useNotification()

  const openNotificationPopup = () => {
    const key = `open${Date.now()}`
    const btn = (
      <Space>
        <Button
          type="link"
          size="small"
          onClick={() => notification.close(key)}
        >
          {t({
            id: 'action.cancel',
            message: 'Annuleren',
          })}
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => sendNotificationEmail(key)}
        >
          {t({
            id: 'events.attachments.send_email_to_users_when_attachment_added.button',
            message: 'Verstuur notificatie',
          })}
        </Button>
      </Space>
    )
    api.open({
      message: t({
        id: 'events.attachments.send_email_to_users_when_attachment_added.title',
        message: 'Notificatie verzenden?',
      }),
      description: t({
        id: 'events.attachments.send_email_to_users_when_attachment_added.description',
        message:
          'U kan ervoor opteren de deelnemers op de hoogte te brengen dat er een nieuwe bijlage is toegevoegd aan deze sessie. Wenst u dat te doen?',
      }),
      btn,
      key,
      duration: 0,
    })
  }

  const AddParticipantsButton = ({
    onClick,
    centered = false,
  }: {
    onClick: () => void
    centered?: boolean
  }) => {
    const buttonContent = (
      <Button onClick={onClick}>
        <Space>
          <PlusOutlined />
          <Trans id="events.participants.add">Deelnemers toevoegen</Trans>
        </Space>
      </Button>
    )

    return centered ? (
      <Space
        direction="vertical"
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {buttonContent}
      </Space>
    ) : (
      buttonContent
    )
  }

  const showAttendanceOptions =
    (canUpdateEvent ||
      ability.can(
        PermissionAction.READ,
        subject(PermissionObjectType.APPOINTED_LIVE_EVENT_QR_CODE, {
          ...event,
          lecturers: event.lecturers,
        })
      )) &&
    event.published &&
    !cancelled &&
    !ended &&
    event.location_type.includes(EventLocationType.Physical)

  return (
    <>
      {contextHolder}
      <Modal
        destroyOnClose={true}
        open={modalOpened}
        onCancel={() => closeModal()}
        closable={false}
        maskClosable={true}
        width={720}
        centered={true}
        footer={null}
        bodyStyle={{
          padding: '0',
          borderRadius: '5px',
          overflow: 'hidden',
        }}
      >
        <EventStatus event={event} />
        {event.path && (
          <Alert
            banner
            type="info"
            message={
              <Trans id="events.alert.linked_to_path">
                Deze groepsessie is gelinkt aan het leerpad: {event.path?.title}
              </Trans>
            }
            action={
              <Button
                size="small"
                onClick={() => navigate(`/paths/${event.path?._id}`)}
              >
                <Trans id="events.alert.linked_to_path.go_to_path">
                  Ga naar leerpad
                </Trans>
              </Button>
            }
          />
        )}
        <EventModalWrapper>
          <Space direction="vertical" style={{ flex: 1, width: '100%' }}>
            <EventHeader event={event} />
            <Tabs
              type="card"
              size={'small'}
              style={{ marginTop: 8 }}
              items={
                [
                  {
                    key: 'description',
                    label: <Trans id="events.description">Beschrijving</Trans>,
                    children: (
                      <div className="event-description-wysiwyg">
                        {
                          ReactHtmlParser(
                            event.description || ''
                          ) as React.ReactNode
                        }
                      </div>
                    ),
                  },
                  (canReadRegistrations || canUpdateAsAppointedLecturer) && {
                    key: 'participants',
                    label: (
                      <>
                        <Trans id="events.participants">Deelnemers</Trans>{' '}
                        {!event.location_type.includes(
                          EventLocationType.Online
                        ) &&
                        (remaining || hasReachedCapacity) ? (
                          <>
                            ({event.physical_participant_count}/{event.capacity}
                            )
                          </>
                        ) : (
                          <>({event.participant_count})</>
                        )}
                      </>
                    ),
                    children: (
                      <Space direction="vertical" style={{ width: '100%' }}>
                        <div style={{ maxHeight: '45vh', overflow: 'auto' }}>
                          {!!remaining &&
                            !hasReachedCapacity &&
                            !event.cancelled && (
                              <Alert
                                type="info"
                                showIcon={true}
                                icon={<InfoCircleOutlined />}
                                message={
                                  <Plural
                                    id="events.participants.remaining"
                                    value={remaining}
                                    one="Er is nog # vrije plek beschikbaar voor de fysieke sessie."
                                    other="Er zijn nog # vrije plekken beschikbaar voor de fysieke sessie."
                                  />
                                }
                              />
                            )}
                          {hasReachedCapacity &&
                            !hasExceededCapacity &&
                            !event.cancelled && (
                              <Alert
                                type="info"
                                icon={<CheckOutlined />}
                                showIcon={true}
                                message={
                                  <Trans id="events.participants.full">
                                    Met {event.physical_participant_count}{' '}
                                    fysieke deelnemers zijn de plaatsen
                                    terplekke volzet.
                                  </Trans>
                                }
                              />
                            )}
                          {!!remaining &&
                            hasExceededCapacity &&
                            !event.cancelled && (
                              <Alert
                                type="error"
                                icon={<AlertOutlined />}
                                showIcon={true}
                                message={
                                  <Plural
                                    id="events.participants.exceeded"
                                    value={-remaining}
                                    one="Er is # plek teveel gevuld voor deze sessie."
                                    other="Er zijn # plekken teveel gevuld voor deze sessie."
                                  />
                                }
                              />
                            )}
                          {loading ? (
                            <LoadSection />
                          ) : participants.length === 0 ? (
                            <Empty
                              description={
                                <Space
                                  direction="vertical"
                                  style={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Trans id="events.participants.empty">
                                    Er zijn nog geen deelnemers ingeschreven.
                                  </Trans>
                                </Space>
                              }
                            />
                          ) : (
                            <List>
                              <div style={{ marginTop: 16, marginBottom: 16 }}>
                                <input
                                  type="text"
                                  placeholder={t({
                                    id: 'events.participants.search',
                                    message: 'Zoek deelnemers',
                                  })}
                                  value={searchQuery}
                                  onChange={(e) =>
                                    setSearchQuery(e.target.value)
                                  }
                                  style={{
                                    width: '100%',
                                    padding: '8px',
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '4px',
                                  }}
                                />
                              </div>
                              {filteredParticipants.length === 0 ? (
                                <Empty
                                  description={
                                    <Space
                                      direction="vertical"
                                      style={{
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Trans id="events.participants.no_results_found">
                                        Er zijn geen resultaten gevonden.
                                      </Trans>
                                    </Space>
                                  }
                                />
                              ) : (
                                <List>
                                  {filteredParticipants.map((participant) => (
                                    <List.Item key={participant._id}>
                                      <ParticipantListItem
                                        participant={participant as Participant}
                                        event={event}
                                      />
                                    </List.Item>
                                  ))}
                                </List>
                              )}
                            </List>
                          )}
                        </div>
                        {(canAdminRegister || showAttendanceOptions) && (
                          <Space
                            direction="horizontal"
                            style={{
                              width: '100%',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {canAdminRegister && (
                              <AddParticipantsButton
                                onClick={() =>
                                  setAddParticipantsModalVisible(true)
                                }
                                centered
                              />
                            )}
                            {showAttendanceOptions && (
                              <DownloadEventAttendanceQr event={event} />
                            )}
                          </Space>
                        )}
                      </Space>
                    ),
                  },
                  {
                    key: 'downloads',
                    label: <Trans id="events.attachments">Bijlagen</Trans>,
                    children: (
                      <Space direction="vertical" style={{ width: '100%' }}>
                        <List
                          style={{
                            margin: -16,
                            border: 0,
                          }}
                          size="small"
                          bordered
                          locale={{
                            emptyText: t({
                              id: 'events.detail.downloads.empty',
                              message: 'Geen bijlagen beschikbaar',
                            }),
                          }}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore
                          dataSource={data?.fetchLiveEventById.attachments}
                          renderItem={(item) => (
                            <List.Item
                              actions={[
                                <Popconfirm
                                  key={0}
                                  title={t({
                                    id: 'events.detail.downloads.delete.title',
                                    message:
                                      'Ben je zeker dat je deze bijlage wil verwijderen?',
                                  })}
                                  onConfirm={() =>
                                    deleteAttachment({
                                      variables: {
                                        event_id: event._id,
                                        attachment_id: item._id,
                                      },
                                    })
                                  }
                                  okText={t({
                                    id: 'action.delete',
                                    message: 'Verwijderen',
                                  })}
                                  okButtonProps={{
                                    loading: deletingAttachment,
                                  }}
                                  cancelText={t({
                                    id: 'action.cancel',
                                    message: 'Annuleren',
                                  })}
                                >
                                  <Button
                                    hidden={
                                      !canUpdate && !canUpdateLearningPath
                                    }
                                    type="text"
                                    icon={
                                      <DeleteOutlined
                                        style={{ fontSize: 14 }}
                                      />
                                    }
                                  ></Button>
                                </Popconfirm>,
                              ]}
                            >
                              <Button
                                onClick={async () => {
                                  let cancelled = false
                                  const key = uuidv4()
                                  const controller = new AbortController()
                                  await axios
                                    .get(item.url, {
                                      responseType: 'blob',
                                      signal: controller.signal,
                                      onDownloadProgress(progressEvent) {
                                        const percentCompleted =
                                          progressEvent.total
                                            ? Math.round(
                                                (progressEvent.loaded * 100) /
                                                  progressEvent.total
                                              )
                                            : 0
                                        if (
                                          percentCompleted < 100 &&
                                          !cancelled
                                        ) {
                                          notification.open({
                                            key,
                                            message: t({
                                              id: 'events.detail.downloads.download.title',
                                              message:
                                                'Bijlage wordt gedownload',
                                            }),
                                            description: (
                                              <Progress
                                                percent={percentCompleted}
                                              />
                                            ),
                                            onClose: () => {
                                              cancelled = true
                                              controller.abort()
                                            },
                                            duration: 0,
                                          })
                                        }
                                      },
                                    })
                                    .then((file) => {
                                      if (file instanceof AxiosError) return
                                      download(
                                        file.data,
                                        item.originalName,
                                        item.mimetype
                                      )
                                    })
                                    .finally(() => notification.close(key))
                                }}
                                type="text"
                                style={{ padding: 0, height: 'auto' }}
                                icon={
                                  <FileOutlined
                                    style={{
                                      display: 'inline-block',
                                      verticalAlign: 'sub',
                                    }}
                                  />
                                }
                              >
                                <Tooltip
                                  title={item.originalName}
                                  mouseEnterDelay={2}
                                >
                                  <AttachmentName>
                                    {item.originalName}
                                  </AttachmentName>
                                </Tooltip>
                              </Button>
                            </List.Item>
                          )}
                        />
                        {(canUpdate || canUpdateAsAppointedLecturer) && (
                          <Form.Item style={{ marginTop: 24, marginBottom: 0 }}>
                            <Upload
                              accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/epub+zip, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/rtf, application/zip"
                              multiple
                              type="drag"
                              action={`${
                                import.meta.env.NX_BACKEND_URL
                              }/api/files/uploadEventAttachment/${event._id}`}
                              headers={{
                                Authorization: `Bearer ${localStorage.getItem(
                                  'aa_lms_at'
                                )}`,
                                'x-academy-host': window.location.hostname,
                              }}
                              onChange={(info) => {
                                const { fileList } = info
                                const hasFailedUploads = fileList.some(
                                  (file) => file.status === 'error'
                                )
                                const hasSuccessfulUploads = fileList.some(
                                  (file) => file.status === 'done'
                                )
                                const allUploadsComplete = fileList.every(
                                  (file) =>
                                    ['done', 'error', 'removed'].includes(
                                      file.status as string
                                    )
                                )

                                if (!allUploadsComplete) return

                                if (hasFailedUploads) {
                                  notification.error({
                                    message: t({
                                      id: 'events.detail.downloads.upload.failed',
                                      message:
                                        'Er is een fout opgetreden bij het uploaden van de bijlage.',
                                    }),
                                  })
                                  return
                                }

                                if (hasSuccessfulUploads) {
                                  refetch()

                                  if (
                                    event.published &&
                                    event.participant_count > 0 &&
                                    ability.can(
                                      PermissionAction.UPDATE,
                                      PermissionObjectType.BRANCH_LIVE_EVENT
                                    )
                                  ) {
                                    openNotificationPopup()
                                  }
                                }
                              }}
                            >
                              <Button>
                                <Space>
                                  <UploadOutlined />
                                  <Trans id="event.attachments.select_file">
                                    Bestand selecteren
                                  </Trans>
                                </Space>
                              </Button>
                            </Upload>
                          </Form.Item>
                        )}
                      </Space>
                    ),
                  },
                  canReadRecordings &&
                    event.location_type.includes(EventLocationType.Online) &&
                    dayjs(event.start).isBefore(dayjs()) && {
                      key: 'recordings',
                      label: <Trans id="events.recordings">Opnames</Trans>,
                      children: <EventRecordings event_id={event._id} />,
                    },
                ].filter(Boolean) as TabsProps['items']
              }
            />

            {(canRegister || canCancel || canJoinCall) && (
              <>
                <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                <EventActions event={event} />
              </>
            )}
            <HoverMenu align="right" verticalalign="bottom">
              <Button
                type="text"
                onClick={() => navigate(`/events/${event._id}/edit`)}
                hidden={!canUpdate}
              >
                <Space>
                  <EditOutlined />
                  <Trans id="event.detail.action.edit">Bewerken</Trans>
                </Space>
              </Button>

              {canDuplicateEvent && (
                <>
                  {event.path ? (
                    <Popconfirm
                      title={
                        <Trans id="event.detail.action.duplicate_from_path.title">
                          Wil je deze sessie dupliceren als een nieuwe sessie
                          die niet gekoppeld is aan een leerpad? <br /> Als je
                          kiest voor &quot;Nee, koppel aan leerpad&quot;, wordt
                          je doorgestuurd naar het leerpad in kwestie.
                        </Trans>
                      }
                      onConfirm={() => handleDuplicate()}
                      onCancel={() => navigate(`/paths/${event.path?._id}`)}
                      okText={
                        <Trans id="action.duplicate_from_path.create_new_event">
                          Ja, kopieer als nieuwe sessie
                        </Trans>
                      }
                      cancelText={
                        <Trans id="action.duplicate_from_path.in_path">
                          Nee, koppel aan leerpad
                        </Trans>
                      }
                    >
                      <Button type="text">
                        <Space>
                          <CopyOutlined />
                          <Trans id="event.detail.action.duplicate">
                            Dupliceren
                          </Trans>
                        </Space>
                      </Button>
                    </Popconfirm>
                  ) : (
                    <Button type="text" onClick={() => handleDuplicate()}>
                      <Space>
                        <CopyOutlined />
                        <Trans id="event.detail.action.duplicate">
                          Dupliceren
                        </Trans>
                      </Space>
                    </Button>
                  )}
                </>
              )}
              {canCancelLiveEvent && (
                <Popconfirm
                  title={
                    <Trans id="event.detail.action.cancel.title">
                      Ben je zeker dat je dit event wil annuleren? Het event
                      blijft beschikbaar in de agenda voor deelnemers en zal
                      gemarkeerd worden als geannuleerd.
                    </Trans>
                  }
                  onConfirm={() => cancelSession()}
                  okText={
                    <Trans id="action.cancel.event">Annuleer event</Trans>
                  }
                  cancelText={<Trans id="action.cancel">Annuleren</Trans>}
                  okButtonProps={{ loading: cancelling }}
                >
                  <Button type="text">
                    <Space>
                      <StopOutlined />
                      <Trans id="event.detail.action.cancel">Annuleren</Trans>
                    </Space>
                  </Button>
                </Popconfirm>
              )}
              {ended ? (
                <Button
                  type="text"
                  hidden={!canDelete}
                  onClick={handleSessionDelete}
                >
                  <Space>
                    <DeleteOutlined />
                    <Trans id="event.detail.action.delete">Verwijderen</Trans>
                  </Space>
                </Button>
              ) : (
                <Popconfirm
                  title={
                    <Trans id="event.detail.action.delete.title">
                      Ben je zeker dat je deze sessie wilt verwijderen? De
                      sessie wordt definitief verwijderd.
                    </Trans>
                  }
                  onConfirm={handleSessionDelete}
                  okText={<Trans id="action.delete">Verwijderen</Trans>}
                  cancelText={<Trans id="action.cancel">Annuleren</Trans>}
                  okButtonProps={{ loading: deleting }}
                >
                  <Button type="text" hidden={!canDelete}>
                    <Space>
                      <DeleteOutlined />
                      <Trans id="event.detail.action.delete">Verwijderen</Trans>
                    </Space>
                  </Button>
                </Popconfirm>
              )}
            </HoverMenu>
          </Space>
          <AddEventParticipantsModal
            event={event}
            participants={participants
              .filter((p) => !p.registration.cancelled)
              .filter((p) => !event.approval || p.registration.approved)
              .map((p) => p._id)}
            open={addParticipantsModalVisible}
            onClose={() => setAddParticipantsModalVisible(false)}
          />
        </EventModalWrapper>
      </Modal>
    </>
  )
}
