import { Trans } from '@lingui/macro'
import { Modal, Button } from 'antd'
import React from 'react'

interface AttentionCheckModalProps {
  isVisible: boolean
  onConfirm: () => void
}

export const AttentionCheckModal: React.FC<AttentionCheckModalProps> = ({
  isVisible,
  onConfirm,
}) => {
  return (
    <Modal
      title={<Trans id="attention.check.title">Aandachtscontrole</Trans>}
      open={isVisible}
      closable={false}
      footer={null}
      maskClosable={false}
    >
      <p>
        <Trans id="attention.check.message">
          Bevestig je aanwezigheid met onderstaande knop.
        </Trans>
      </p>
      <Button type="primary" onClick={onConfirm} style={{ marginTop: 16 }}>
        <Trans id="attention.check.confirm">Ik ben er nog</Trans>
      </Button>
    </Modal>
  )
}
