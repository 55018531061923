import { UserOutlined } from '@ant-design/icons'
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Layout as AntLayout, Progress } from 'antd'
import { ObjectId } from 'bson'
import { usePostHog } from 'posthog-js/react'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { TitleContext } from '../../core/context/Title.context'
import { ActiveEventBar } from '../../events/components/ActiveEventBar'
import { Header, NavigationItem } from '../components/header/Header'
import { PageProps } from '../interfaces/page.interface'

import { Container } from './Layout.style'

const { Footer } = AntLayout

const ProgressBar = styled((props) => <Progress {...props} />)`
  display: block;
  * {
    display: block;
  }
`

export interface LayoutProps extends PageProps {
  anon?: boolean
  loading?: boolean
}

export const Layout: FC<LayoutProps> = ({
  route,
  loading = false,
}: LayoutProps) => {
  const client = usePostHog()
  const [calendarFeature, setCalendarFeature] = useState<boolean>(false)
  const [pageProgress, setPageProgress] = useState<number>(0)
  const {
    container = true,
    component: Component,
    footer = true,
    label,
    subnavigation,
  } = route
  const { user } = useAuth()
  const branch = useBranch()
  const { title: pageTitle, setTitle: setPageTitle } = useContext(TitleContext)
  const location = useLocation()
  const {
    i18n: { locale },
  } = useLingui()

  useEffect(() => {
    if (label) {
      setPageTitle(label)
    }
  }, [location, label, setPageTitle, locale])

  useEffect(() => {
    client.onFeatureFlags(function () {
      if (client.isFeatureEnabled('Calendar')) {
        setCalendarFeature(true)
      }
    })
  }, [client])

  const navigation = useMemo<NavigationItem[]>(() => {
    const items: NavigationItem[] = [
      {
        order: 5,
        label: t({
          id: 'header.navigation.library',
          message: 'Bibliotheek',
        }),
        route: '/library',
      },
      {
        order: 10,
        label: t({
          id: 'header.navigation.branch',
          message: 'Kantoor',
        }),
        route: '/branch',
        permission: {
          or: [
            {
              action: PermissionAction.UPDATE,
              subject: PermissionObjectType.BRANCH_USER,
            },
            {
              action: PermissionAction.UPDATE,
              subject: PermissionObjectType.BRANCH_USER_GROUP,
            },
            {
              action: PermissionAction.UPDATE,
              subject: PermissionObjectType.BRANCH_COURSE_CATEGORY,
            },
            {
              action: PermissionAction.UPDATE,
              subject: PermissionObjectType.BRANCH_ROLE,
            },
            {
              action: PermissionAction.UPDATE,
              subject: PermissionObjectType.BRANCH_CERTIFICATION_TYPE,
            },
            {
              action: PermissionAction.UPDATE,
              subject: PermissionObjectType.BRANCH_JOB_OPPORTUNITY,
            },
          ],
        },
      },
      {
        order: 15,
        label: t({
          id: 'header.navigation.reports',
          message: 'Rapporten',
        }),
        route: '/reports',
        permission: {
          or: [
            {
              action: PermissionAction.READ,
              subject: PermissionObjectType.OWN_REPORT,
            },
            {
              action: PermissionAction.READ,
              subject: PermissionObjectType.BRANCH_REPORT,
            },
            {
              action: PermissionAction.READ,
              subject: PermissionObjectType.REPORT,
            },
          ],
        },
      },
      {
        order: 20,
        label: t({
          id: 'header.navigation.articles',
          message: 'Nieuws',
        }),
        route: '/articles',
        permission: {
          action: PermissionAction.READ,
          subject: PermissionObjectType.ARTICLE,
        },
      },
      {
        order: 25,
        label: t({
          id: 'header.navigation.jobs',
          message: 'Vacatures',
        }),
        route: '/jobs',
        permission: {
          action: PermissionAction.READ,
          subject: PermissionObjectType.JOB_OPPORTUNITY,
        },
      },
    ]

    calendarFeature &&
      items.push({
        order: 7,
        label: t({
          id: 'header.navigation.calendar',
          message: 'Kalender',
        }),
        route: '/calendar',
        params: { segment: 'my_registered_events' },
        permission: {
          or: [
            {
              action: PermissionAction.READ,
              subject: PermissionObjectType.BRANCH_LIVE_EVENT,
            },
            {
              action: PermissionAction.READ,
              subject: PermissionObjectType.LIVE_EVENT,
            },
            {
              action: PermissionAction.READ,
              subject: PermissionObjectType.APPOINTED_LIVE_EVENT,
            },
          ],
        },
      })

    branch?.extensions
      ?.filter((extension) => {
        return (
          extension.roles.length === 0 ||
          extension.roles.some(
            (role_id) =>
              user?.roles.some((r) => new ObjectId(r.role_id).equals(role_id))
          )
        )
      })
      .forEach((extension) => {
        if (extension.__typename === 'NavItemExtension') {
          items.push({
            order: extension.order,
            label: extension.label,
            route: `/x/${extension.key}#/`,
          })
        }
      })

    return items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch?.extensions, user?.roles, locale, calendarFeature])

  const anonNavigation: NavigationItem[] = [
    {
      order: 5,
      label: t({
        id: 'header.navigation.login',
        message: 'Inloggen',
      }),
      icon: <UserOutlined />,
      route: '/login',
    },
    // {
    //   label: t({
    //     id: 'header.navigation.register',
    //     message: 'Registreren',
    //   }),
    //   route: '/register',
    // },
  ]

  return (
    <AntLayout className="layout" style={{ minHeight: '100vh' }}>
      <Helmet>
        <title>
          {pageTitle || ''}
          {branch ? ` | ${branch?.name}` : ''}
        </title>
      </Helmet>
      <Header
        route={route}
        navigation={{
          anon: anonNavigation.sort((a, b) => a.order - b.order),
          auth: navigation.sort((a, b) => a.order - b.order),
        }}
        loading={loading}
      />
      {subnavigation}
      <ProgressBar
        hidden={pageProgress === 0}
        strokeLinecap="square"
        percent={pageProgress * 100}
        showInfo={false}
      />
      {container ? (
        <Container style={{ marginTop: 36 }}>
          {Component && (
            <Component
              route={route}
              setPageProgressBar={setPageProgress}
              setPageTitle={(title) => {
                setPageTitle(title)
              }}
            />
          )}
        </Container>
      ) : (
        Component && (
          <Component
            route={route}
            setPageTitle={(title) => {
              setPageTitle(title)
            }}
          />
        )
      )}
      <ActiveEventBar />
      {!loading && footer && (
        <Footer style={{ textAlign: 'center' }}>
          &copy; {new Date().getFullYear()} {branch?.name} -{' '}
          <Trans id="footer.copyright">Alle rechten voorbehouden</Trans>
        </Footer>
      )}
    </AntLayout>
  )
}
