/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/prefer-spread */
import { createHttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import fetch from 'cross-fetch'

export const httpLink = createHttpLink({
  uri: `${import.meta.env.NX_BACKEND_URL || 'http://localhost:3333'}/graphql`,
  fetch,
})

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('aa_lms_at')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-academy-host': window.location.hostname,
    },
  }
})

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (networkError && operation.operationName === 'branch') {
      // doublecheck if token is not too long
      const token = localStorage.getItem('aa_lms_at')
      if (token && token.length > 1850) {
        localStorage.removeItem('aa_lms_at')
        document.dispatchEvent(new Event('token_expired'))
      }
    }

    graphQLErrors?.forEach(({ extensions }) => {
      if (
        extensions?.code === 'UNAUTHENTICATED' &&
        localStorage.getItem('aa_lms_at')
      ) {
        localStorage.removeItem('aa_lms_at')
        document.dispatchEvent(new Event('token_expired'))
      }
    })
  }
)

export const ApolloConfig = {
  link: from([authLink, errorLink, httpLink]),
}
