import { Trans } from '@lingui/macro'
import { Space, Card, Button, Modal, List, Empty } from 'antd'
import dayjs from 'dayjs'
import React from 'react'

import {
  FetchPendingEventApprovalsQuery,
  LiveEventsQuery,
  Participant,
} from 'apps/lms-front/src/generated/graphql'

import { ParticipantListItem } from './ParticipantListItem'

export const PendingEventApprovalsModal: React.FC<{
  data?: FetchPendingEventApprovalsQuery
  loading: boolean
  approvalModalVisible: boolean
  setIsApprovalModalVisible: (state: boolean) => void
}> = ({ data, approvalModalVisible, setIsApprovalModalVisible }) => {
  return (
    <Modal
      width={640}
      title={
        <Trans id="events.participants.pending_approvals">Goedkeuringen</Trans>
      }
      open={approvalModalVisible}
      onCancel={() => setIsApprovalModalVisible(false)}
      footer={[
        <Button key="cancel" onClick={() => setIsApprovalModalVisible(false)}>
          <Trans id="actions.close">Sluiten</Trans>
        </Button>,
      ]}
    >
      {data?.fetchPendingEventApprovals?.length === 0 && (
        <Empty
          description={
            <Trans id="events.participants.no_approvals_or_denials">
              Er zijn geen openstaande goedkeuringen of afwijzingen.
            </Trans>
          }
        />
      )}
      {data?.fetchPendingEventApprovals.map((group) => (
        <Card
          key={group.event._id}
          title={
            <Space direction="vertical" size={0} style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ flex: 1, textAlign: 'left' }}>
                  <b>{group.event.title}</b>
                </div>

                <div style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                  {dayjs(group.event.start).format('DD/MM/YYYY HH:mm')} -{' '}
                  {dayjs(group.event.end).format('HH:mm')}
                </div>
              </div>
            </Space>
          }
          size="small"
          style={{ marginBottom: 16 }}
        >
          <List>
            {group.participants.map((participant) => (
              <List.Item key={participant._id}>
                <ParticipantListItem
                  event={
                    group.event as LiveEventsQuery['fetchLiveEvents']['results'][0]
                  }
                  participant={participant as Participant}
                />
              </List.Item>
            ))}
          </List>
        </Card>
      ))}
    </Modal>
  )
}
