/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Pie from '@ant-design/plots/es/components/pie'
import { useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { PageHeader, Modal, Avatar, Space, List, Divider } from 'antd'
import dayjs from 'dayjs'
import { test } from 'fuzzyjs'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { UsersByIdsQuery } from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { useHierarchyTree } from '../../../branch/hooks/use-hierarchy-tree'
import { LoadSection } from '../../../core/components/LoadScreen'
import { setSessionStorageItem } from '../../../core/utils/session-storage'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { TreeSelect } from '../../../shared/components/tree-select/TreeSelect'
import { useAxios } from '../../../shared/hooks/use-axios'
import { Content } from '../../../shared/layout/Layout.style'
import { Table } from '../../components/Table'

import USER_BY_IDS from './../../queries/users-by-ids.graphql'

import type { Plot, PlotEvent } from '@ant-design/plots'

type Record = {
  _id: string
  name: string
  data: {
    unit: {
      _id: string
      name: string
      questions: {
        question: string
        order: number
        answer_type: string
        answers: Array<{
          type: string
          value: number
        }>
      }[]
    }
  }[]
}

export const AnswerRenderer = ({
  answers,
  rec,
  record,
}: {
  answers: Array<{
    type: string
    value: number
    user_id: string
  }>
  rec: {
    question: string
    order: number
    answer_type: string
    answers: Array<{
      type: string
      value: number
      user_id: string
    }>
  }
  record: unknown
}) => {
  const [modal, contextHolder] = Modal.useModal()

  const user_ids = answers
    .flatMap((answer) => answer.user_id?.split(',') || [])
    .filter(Boolean)

  const { data: users, refetch } = useQuery<UsersByIdsQuery>(USER_BY_IDS, {
    variables: { ids: user_ids },
  })

  const openUsersModal = useCallback(
    // @ts-ignore
    (data, users) => {
      modal.info({
        icon: null,
        okButtonProps: { hidden: true },
        closable: true,
        maskClosable: true,
        bodyStyle: {
          padding: 0,
        },
        content: (
          <Space direction="vertical" style={{ width: '100%' }}>
            <div style={{ padding: 16, paddingBottom: 0 }}>
              <strong>
                <Trans id="reports.surveys.answer">Antwoord</Trans>:
              </strong>
              <p style={{ margin: 0 }}>{data.data.type}</p>
            </div>
            <Divider style={{ margin: 0 }} />
            {users ? (
              <List
                size="small"
                dataSource={users.filter(
                  (user: UsersByIdsQuery['fetchUsers']['results'][0]) =>
                    data.data.user_id.split(',').includes(user._id)
                )}
                renderItem={(
                  item: UsersByIdsQuery['fetchUsers']['results'][0]
                ) => (
                  <List.Item>
                    <Space direction="horizontal">
                      <Avatar src={item.picture?.url}>
                        {item.firstName.charAt(0).toUpperCase()}
                      </Avatar>
                      <span>
                        {item.firstName} {item.lastName} ({item.email})
                      </span>
                    </Space>
                  </List.Item>
                )}
              />
            ) : (
              <LoadSection />
            )}
          </Space>
        ),
      })
    },
    [modal]
  )

  const handlePieReady = useCallback(
    (chart: Plot<never>) => {
      chart.on('element:click', (e: PlotEvent) => {
        const { data } = e
        if (data) {
          refetch().then(({ data: users }) => {
            openUsersModal(data, users.fetchUsers.results)
          })
        }
      })
    },
    [refetch, openUsersModal]
  )

  // @ts-ignore
  if (rec.answer_type === 'MultipleChoice') {
    const config = {
      appendPadding: 10,
      data: answers,
      angleField: 'value',
      colorField: 'type',
      radius: 0.9,
      label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }: { percent?: number }) =>
          percent ? `${(percent * 100).toFixed(0)}%` : '',
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      legend: {
        position: 'bottom',
        type: 'vertical',
        flipPage: false,
      },
      interactions: [
        {
          type: 'element-active',
        },
      ],
    }
    return (
      <div style={{ padding: 8 }}>
        {/* @ts-ignore */}
        <Pie
          // @ts-ignore
          onReady={handlePieReady}
          //@ts-ignore
          key={`${record.unit._id}-${rec.question}`}
          {...config}
          animation={false}
          width={320}
          pieStyle={{
            cursor: 'pointer',
          }}
        />
        {contextHolder}
      </div>
    )
  }
  return (
    <>
      <Table
        bordered
        size="small"
        dataSource={answers}
        pagination={false}
        // @ts-ignore
        rowKey={(record) => record.type}
        // @ts-ignore
        onRow={(record: (typeof answers)[0]) => {
          return {
            style: {
              cursor: 'pointer',
            },
            onClick: (e) => {
              e.preventDefault()
              modal.info({
                icon: null,
                okButtonProps: { hidden: true },
                closable: true,
                maskClosable: true,
                bodyStyle: {
                  padding: 0,
                },
                content: (
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <div style={{ padding: 16, paddingBottom: 0 }}>
                      <strong>Antwoord:</strong>
                      <p style={{ margin: 0 }}>{record.type}</p>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    {users ? (
                      <List
                        size="small"
                        dataSource={users.fetchUsers.results.filter((user) =>
                          record.user_id.split(',').includes(user._id)
                        )}
                        renderItem={(
                          item: UsersByIdsQuery['fetchUsers']['results'][0]
                        ) => (
                          <List.Item>
                            <Space direction="horizontal">
                              <Avatar src={item.picture?.url}>
                                {item.firstName.charAt(0).toUpperCase()}
                              </Avatar>
                              <span>
                                {item.firstName} {item.lastName} ({item.email})
                              </span>
                            </Space>
                          </List.Item>
                        )}
                      />
                    ) : (
                      <LoadSection />
                    )}
                  </Space>
                ),
              })
            },
          }
        }}
        columns={[
          {
            title: t({
              id: 'reports.surveys.table.answer',
              message: 'Antwoord',
            }),
            dataIndex: ['type'],
            key: 'type',
          },
          {
            title: t({
              id: 'reports.surveys.table.count',
              message: 'Aantal',
            }),
            dataIndex: ['value'],
            key: 'value',
            align: 'center',
          },
        ]}
      />
      {contextHolder}
    </>
  )
}

export const SurveysReport = () => {
  const [searchParams] = useSearchParams()
  const [query, setQuery] = useQueryParam('query', StringParam)
  const [start, setStart] = useQueryParam('start', StringParam)
  const [end, setEnd] = useQueryParam('end', StringParam)

  const ability = useContext(AbilityContext)

  const [hierarchyFilter, setHierarchyFilter] = useQueryParam(
    'section',
    StringParam
  )
  const { data: treeData, loading: treeLoading } = useHierarchyTree({
    filterByPermission: {
      action: PermissionAction.READ,
      object: PermissionObjectType.BRANCH_REPORT,
    },
  })

  const [{ data: rawData, loading }] = useAxios<Record[]>({
    url: `/api/activity/surveys`,
    params: {
      section_id: hierarchyFilter || sessionStorage.getItem('aa_report_filter'),
      start,
      end,
    },
  })

  const data = useMemo(() => {
    return rawData?.filter((record) =>
      query
        ? test(query, record.name, {
            caseSensitive: false,
          })
        : true
    )
  }, [rawData, query])

  useEffect(() => {
    if (
      !hierarchyFilter &&
      (ability.can(PermissionAction.READ, PermissionObjectType.REPORT) ||
        ability.can(PermissionAction.READ, PermissionObjectType.BRANCH_REPORT))
    )
      setHierarchyFilter(sessionStorage.getItem('aa_report_filter'))
  }, [])

  return (
    <Content style={{ backgroundColor: '#FFF' }}>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'reports.surveys.title',
          message: 'Enquêtes',
        })}
        extra={
          <Space style={{ marginBottom: 24 }}>
            {treeData.length > 0 && (
              <TreeSelect
                placeholder={t({
                  id: 'reports.filter.hierarchy',
                  message: 'Filter op afdeling',
                })}
                treeDefaultExpandAll={true}
                treeLine={true}
                showSearch
                treeDataSimpleMode
                style={{ width: 250 }}
                dropdownMatchSelectWidth={false}
                filterTreeNode={(input, option) =>
                  (option.title as string)
                    ?.toLowerCase()
                    .includes(input.toLowerCase())
                }
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: 'auto',
                }}
                treeData={treeData}
                loading={treeLoading}
                allowClear={true}
                treeNodeLabelProp="label"
                value={hierarchyFilter}
                onChange={(value) => {
                  setSessionStorageItem('aa_report_filter', value)
                  setHierarchyFilter(value)
                }}
              />
            )}
            <DatePicker.RangePicker
              ranges={{
                [t({
                  id: 'datepicker.preset.last_7_days',
                  message: 'Laatste 7 dagen',
                })]: [dayjs().add(-7, 'd'), dayjs()],
                [t({
                  id: 'datepicker.preset.last_30_days',
                  message: 'Laatste 30 dagen',
                })]: [dayjs().add(-30, 'd'), dayjs()],
                [t({
                  id: 'datepicker.preset.this_week',
                  message: 'Deze week',
                })]: [dayjs().startOf('week'), dayjs().endOf('week')],
                [t({
                  id: 'datepicker.preset.this_month',
                  message: 'Deze maand',
                })]: [dayjs().startOf('month'), dayjs().endOf('month')],
                [t({
                  id: 'datepicker.preset.this_quarter',
                  message: 'Dit kwartaal',
                })]: [dayjs().startOf('quarter'), dayjs().endOf('quarter')],
                [t({
                  id: 'datepicker.preset.this_year',
                  message: 'Dit jaar',
                })]: [dayjs().startOf('year'), dayjs().endOf('year')],
              }}
              allowClear={false}
              allowEmpty={[false, false]}
              defaultValue={[
                start ? dayjs(start) : dayjs().subtract(14, 'days'),
                end ? dayjs(end) : dayjs(),
              ]}
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day')
              }}
              onChange={(dates) => {
                if (dates) {
                  setStart(dates[0]?.toISOString())
                  setEnd(dates[1]?.toISOString())
                }
              }}
              format="DD/MM/YYYY"
            />
          </Space>
        }
      ></PageHeader>
      <Content>
        {!data && <LoadSection />}
        {data && (
          <Table
            loading={loading}
            expandable={{
              // @ts-ignore
              expandedRowRender: (record: Record) => {
                return (
                  <Table
                    key={record._id}
                    // @ts-ignore
                    rowKey={(record) => record.unit._id}
                    bordered
                    size="small"
                    dataSource={record.data}
                    pagination={false}
                    columns={[
                      {
                        title: t({
                          id: 'reports.surveys.table.unit',
                          message: 'Onderdeel',
                        }),
                        dataIndex: ['unit', 'name'],
                        key: 'unit_name',
                      },
                      {
                        title: t({
                          id: 'reports.surveys.table.count',
                          message: 'Aantal',
                        }),
                        align: 'center',
                        key: 'count',
                        children: [
                          {
                            title: t({
                              id: 'reports.surveys.table.questions',
                              message: 'Vragen',
                            }),
                            dataIndex: 'questions',
                            key: 'surveys',
                            align: 'center',
                            // @ts-ignore
                            render: (_, record) => record.unit.questions.length,
                            sortDirections: ['descend'],
                            sorter: (a, b) =>
                              // @ts-ignore
                              a.unit.questions.length -
                              // @ts-ignore
                              b.unit.questions.length,
                          },
                          {
                            title: t({
                              id: 'reports.surveys.table.answers',
                              message: 'Antwoorden',
                            }),
                            dataIndex: 'questions',
                            key: 'answers',
                            align: 'center',
                            // @ts-ignore
                            render: (_, record) =>
                              // @ts-ignore
                              record.unit.questions.reduce(
                                // @ts-ignore
                                (acc: number, cur) =>
                                  acc +
                                  // @ts-ignore
                                  cur.answers.reduce(
                                    // @ts-ignore
                                    (acc: number, cur) => acc + cur.value,
                                    0
                                  ),
                                0
                              ),
                            sortDirections: ['descend'],
                          },
                        ],
                      },
                    ]}
                    // @ts-ignore
                    expandedRowRender={(record) => (
                      <Table
                        // @ts-ignore
                        key={record._id}
                        // @ts-ignore
                        rowKey={(record) => record.question}
                        bordered
                        size="small"
                        // @ts-ignore
                        dataSource={record.unit.questions}
                        pagination={false}
                        columns={[
                          {
                            title: t({
                              id: 'reports.surveys.table.question',
                              message: 'Vraag',
                            }),
                            dataIndex: ['question'],
                            key: 'question',
                          },
                          {
                            title: t({
                              id: 'reports.surveys.table.answer',
                              message: 'Antwoord',
                            }),
                            dataIndex: ['answers'],
                            key: 'answers',
                            className: 'answers',
                            // @ts-ignore
                            render: (answers, rec) => (
                              <AnswerRenderer
                                answers={answers}
                                // @ts-ignore
                                rec={rec}
                                record={record}
                              />
                            ),
                          },
                        ]}
                      />
                    )}
                    expandable={{
                      defaultExpandAllRows: true,
                      expandRowByClick: true,
                      // @ts-ignore
                      rowExpandable: (record: Record['data'][0]) => {
                        return record.unit.questions.length > 0
                      },
                    }}
                  />
                )
              },
              expandRowByClick: true,
              // @ts-ignore
              rowExpandable: ({ data }) => data.length > 0,
              defaultExpandedRowKeys: searchParams.get('expand')
                ? [searchParams.get('expand') || '']
                : undefined,
            }}
            bordered
            size="small"
            dataSource={data}
            pagination={false}
            // @ts-ignore
            rowKey={(record) => record._id}
            columns={[
              {
                title: t({
                  id: 'reports.surveys.table.course',
                  message: 'Opleiding',
                }),
                key: 'course',
                sortOrder: 'ascend',
                // @ts-ignore
                sorter: (a, b) => a.name.localeCompare(b.name),
                children: [
                  {
                    title: (
                      <InputSearch
                        placeholder={t({
                          id: 'reports.surveys.table.search',
                          message: 'Zoeken op opleiding',
                        })}
                        defaultValue={query || ''}
                        onSearch={(query) => {
                          setQuery(query)
                        }}
                        onChange={(e) => {
                          if (e.target.value === '') {
                            setQuery(e.target.value)
                          }
                        }}
                      />
                    ),
                    dataIndex: 'name',
                    key: 'name',
                  },
                ],
              },
              {
                title: t({
                  id: 'reports.surveys.table.count',
                  message: 'Aantal',
                }),
                align: 'center',
                key: 'count',
                children: [
                  {
                    title: t({
                      id: 'reports.surveys.table.surveys',
                      message: 'Enquêtes',
                    }),
                    dataIndex: 'questions',
                    key: 'questions',
                    align: 'center',
                    // @ts-ignore
                    render: (_, { data }) => data.length,
                  },
                  {
                    title: t({
                      id: 'reports.surveys.table.questions',
                      message: 'Vragen',
                    }),
                    dataIndex: 'questions',
                    key: 'surveys',
                    align: 'center',
                    // @ts-ignore
                    render: (_, { data }) =>
                      data.reduce(
                        (
                          acc: number,
                          cur: { unit: { questions: unknown[] } }
                        ) => acc + cur.unit.questions.length,
                        0
                      ),
                  },
                  {
                    title: t({
                      id: 'reports.surveys.table.answers',
                      message: 'Antwoorden',
                    }),
                    dataIndex: 'questions',
                    key: 'answers',
                    align: 'center',
                    // @ts-ignore
                    render: (_, { data }) =>
                      data.reduce(
                        (
                          acc: number,
                          cur: { unit: { questions: unknown[] } }
                        ) =>
                          acc +
                          (cur.unit.questions.reduce(
                            // @ts-ignore
                            // eslint-disable-next-line sonarjs/no-identical-functions
                            (acc: number, cur) =>
                              acc +
                              // @ts-ignore
                              cur.answers.reduce(
                                // @ts-ignore
                                (acc: number, cur) => acc + cur.value,
                                0
                              ),
                            0
                          ) as number),
                        0
                      ),
                  },
                ],
              },
            ]}
          />
        )}
      </Content>
    </Content>
  )
}
