import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Button, Modal, Form, Input, Space, Tag, List } from 'antd'
import PropTypes from 'prop-types'
import { useState, useCallback, useMemo } from 'react'

import { UserByEmailQuery } from 'apps/lms-front/src/generated/graphql'
import { UserSelect } from 'apps/lms-front/src/modules/shared/form-fields/user-select/UserSelect'

import FETCH_USER_BY_EMAIL_QUERY from '../../../../user/queries/user-by-email.graphql'

// Types
interface ExternalLecturer {
  email: string
  firstName: string
  lastName: string
  external: true
}

interface InternalLecturer {
  user_id: string
  label: string
  external: false
}

type Lecturer = ExternalLecturer | InternalLecturer

interface LecturerSelectProps {
  value?: Lecturer[]
  onChange?: (lecturers: Lecturer[]) => void
}

// Component
export const LecturerSelect: React.FC<LecturerSelectProps> = ({
  value = [],
  onChange,
}) => {
  const [modalState, setModalState] = useState<{
    type: 'internal' | 'external' | null
  }>({
    type: null,
  })

  const [fetchUser] = useLazyQuery<UserByEmailQuery>(FETCH_USER_BY_EMAIL_QUERY)

  const handleRemoveLecturer = useCallback(
    (lecturerToRemove: Lecturer) => {
      onChange?.(
        value.filter((lecturer) =>
          lecturer.external
            ? lecturer.email !== (lecturerToRemove as ExternalLecturer).email
            : lecturer.user_id !==
              (lecturerToRemove as InternalLecturer).user_id
        )
      )
    },
    [value, onChange]
  )

  const selectedLecturers = useMemo(
    () => (
      <List
        size="small"
        bordered={true}
        locale={{
          emptyText: (
            <Trans id="events.form.no_lecturers">
              Nog geen docenten geselecteerd
            </Trans>
          ),
        }}
        dataSource={value}
        renderItem={(lecturer) => (
          <List.Item
            key={lecturer.external ? lecturer.email : lecturer.user_id}
            actions={[
              <Button
                key={0}
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveLecturer(lecturer)}
              />,
            ]}
          >
            <Space>
              {lecturer.external
                ? `${lecturer.firstName} ${lecturer.lastName} (${lecturer.email})`
                : `${lecturer.label}`}

              <Tag color={lecturer.external ? 'orange' : 'blue'}>
                {lecturer.external ? (
                  <Trans id={'events.form.tag.external_lecturer'}>Extern</Trans>
                ) : (
                  <Trans id={'events.form.tag.internal_lecturer'}>Intern</Trans>
                )}
              </Tag>
            </Space>
          </List.Item>
        )}
      />
    ),
    [value, handleRemoveLecturer]
  )

  const handleInternalSelect = (user_id: string, label: string) => {
    const newLecturer: InternalLecturer = {
      user_id,
      label: label.includes('(')
        ? label.split('(').slice(0, -1).join('(')
        : label,
      external: false,
    }
    onChange?.([...value, newLecturer])
    setModalState({ type: null })
  }

  const handleExternalSubmit = async (formData: ExternalLecturer) => {
    const { data } = await fetchUser({
      variables: { email: formData.email },
    })

    if (data?.fetchUserByEmail && !data.fetchUserByEmail.disabled) {
      const user = data.fetchUserByEmail
      Modal.confirm({
        title: t({
          id: 'events.form.modal.user_exists.title',
          message: 'Gebruiker bestaat al',
        }),
        content: t({
          id: 'events.form.modal.user_exists.content',
          message:
            'Deze gebruiker bestaat al als interne gebruiker. Je kan bijgevolg deze gebruiker niet toevoegen als externe gebruiker. Wens je deze gebruiker als interne docent toe te voegen? ',
        }),
        okText: t({
          id: 'action.yes',
          message: 'Ja',
        }),
        cancelText: t({
          id: 'action.no',
          message: 'Nee',
        }),
        onOk: () => {
          handleInternalSelect(user._id, `${user.firstName} ${user.lastName}`)
        },
      })
    } else {
      onChange?.([...value, { ...formData, external: true }])
      setModalState({ type: null })
    }
  }

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        {selectedLecturers}

        {/* Action buttons */}
        <Space>
          <Button
            icon={<UserAddOutlined style={{ marginRight: 6 }} />}
            onClick={() => setModalState({ type: 'internal' })}
          >
            <Trans id="events.form.button.add_internal_lecturer">
              Interne docent toevoegen
            </Trans>
          </Button>
          <Button
            icon={<UserAddOutlined style={{ marginRight: 6 }} />}
            onClick={() => setModalState({ type: 'external' })}
          >
            <Trans id="events.form.button.add_external_lecturer">
              Externe docent toevoegen
            </Trans>
          </Button>
        </Space>
      </Space>

      {/* Internal Lecturer Modal */}
      <Modal
        destroyOnClose={true}
        title={
          <Trans id="events.form.modal.add_internal_lecturer.title">
            Interne docent selecteren
          </Trans>
        }
        open={modalState.type === 'internal'}
        onCancel={() => setModalState({ type: null })}
        footer={null}
      >
        <UserSelect
          style={{ width: '100%' }}
          showSearch={true}
          type="branch"
          placeholder={
            <Trans id="events.form.modal.add_internal_lecturer.placeholder">
              Selecteer een interne docent
            </Trans>
          }
          onChange={(userId: string, option) => {
            const { label } = option as { label: string }
            handleInternalSelect(userId, label)
          }}
        />
      </Modal>

      {/* External Lecturer Modal */}
      <Modal
        title={
          <Trans id="events.form.modal.add_external_lecturer.title">
            Externe docent toevoegen
          </Trans>
        }
        open={modalState.type === 'external'}
        onCancel={() => setModalState({ type: null })}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleExternalSubmit}>
          <Form.Item
            name="firstName"
            label={
              <Trans id="events.form.modal.add_external_lecturer.firstName">
                Voornaam
              </Trans>
            }
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={
              <Trans id="events.form.modal.add_external_lecturer.lastName">
                Achternaam
              </Trans>
            }
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={
              <Trans id="events.form.modal.add_external_lecturer.email">
                E-mail
              </Trans>
            }
            rules={[{ required: true, type: 'email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              <Trans id="events.form.button.add">Toevoegen</Trans>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

LecturerSelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}
