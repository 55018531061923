import { Trans } from '@lingui/macro'
import { Space, Card, Button, Modal, List, Empty } from 'antd'
import React from 'react'

import {
  FetchPendingPathApprovalsQuery,
  LearningPathParticipant,
  PathQuery,
} from 'apps/lms-front/src/generated/graphql'

import { ParticipantListItem } from './ParticipantListItem'

export const PendingPathsApprovalsModal: React.FC<{
  data?: FetchPendingPathApprovalsQuery
  loading: boolean
  approvalModalVisible: boolean
  setIsApprovalModalVisible: (state: boolean) => void
}> = ({ data, approvalModalVisible, setIsApprovalModalVisible }) => {
  return (
    <Modal
      width={640}
      title={
        <Trans id="events.participants.pending_approvals">Goedkeuringen</Trans>
      }
      open={approvalModalVisible}
      onCancel={() => setIsApprovalModalVisible(false)}
      footer={[
        <Button key="cancel" onClick={() => setIsApprovalModalVisible(false)}>
          <Trans id="actions.close">Sluiten</Trans>
        </Button>,
      ]}
    >
      {data?.fetchPendingPathApprovals?.length === 0 && (
        <Empty
          description={
            <Trans id="events.participants.no_approvals_or_denials">
              Er zijn geen openstaande goedkeuringen of afwijzingen.
            </Trans>
          }
        />
      )}
      {data?.fetchPendingPathApprovals.map((group) => (
        <Card
          key={group.path._id}
          title={
            <Space direction="vertical" size={0} style={{ width: '100%' }}>
              <b>{group.path.title}</b>
            </Space>
          }
          size="small"
          style={{ marginBottom: 16 }}
        >
          <List>
            {group.participants.map((participant) => (
              <List.Item key={participant._id}>
                <ParticipantListItem
                  path={group.path as PathQuery['fetchLearningPathById']}
                  participant={participant as LearningPathParticipant}
                />
              </List.Item>
            ))}
          </List>
        </Card>
      ))}
    </Modal>
  )
}
