/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/display-name */
import { useQuery, ApolloQueryResult } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Button, Checkbox, Form, notification, Radio, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import {
  LiveEventQuizQuery,
  QuizQuestion,
} from 'apps/lms-front/src/generated/graphql'
import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { logEventActivity } from 'apps/lms-front/src/modules/shared/helpers/log-activity'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { Question } from '../../../units/pages/unit-viewer/quiz-unit-viewer/QuizUnitViewer.styles'
import { PageHeader } from '../../../units/pages/unit-viewer/UnitViewer.style'
import LIVE_EVENT_QUIZ_QUERY from '../../queries/live-event-quiz.graphql'

export const LiveEventQuiz = () => {
  const { event_id } = useParams()
  const navigate = useNavigate()

  const {
    data: event,
    loading: loadingEvent,
    refetch,
  } = useQuery<LiveEventQuizQuery>(LIVE_EVENT_QUIZ_QUERY, {
    variables: { id: event_id },
    fetchPolicy: 'network-only',
    skip: !event_id,
  })

  const quizRef = useRef<QuizViewerRef>(null)
  const [current, setCurrent] = useState(0)
  const [loading, setLoading] = useState(false)

  const questions = useMemo(
    () => (event?.fetchLiveEventById.post_event_quiz as QuizQuestion[]) || [],
    [event?.fetchLiveEventById.post_event_quiz]
  )

  if (loadingEvent) return <LoadScreen />

  /** Either take end of event or time of sending out the quiz */
  const quizStartDate =
    event?.fetchLiveEventById?.my_activity?.post_event_quiz_sent ||
    event?.fetchLiveEventById?.end

  if (
    !event_id ||
    dayjs().isBefore(dayjs(quizStartDate)) || // Before the quiz start date
    dayjs().isAfter(dayjs(quizStartDate).add(7, 'day')) // After 7 days from the quiz start date
  ) {
    notification.error({
      message: t({
        id: 'events.quiz.not_available',
        message: 'Deze afsluitende toets is niet (meer) beschikbaar.',
      }),
    })
    return (
      <Navigate to={event_id ? `/calendar?event=${event_id}` : '/calendar'} />
    )
  }

  return (
    <>
      <PageHeader
        title={
          <small>
            {event?.fetchLiveEventById.my_activity?.quiz_completed ? (
              <Trans id="unit.viewer.quiz_results.title">Jouw resultaten</Trans>
            ) : (
              <Trans id="unit.viewer.quiz.title">
                Vraag {current + 1} van {questions.length}
              </Trans>
            )}
          </small>
        }
        style={{ paddingLeft: 0, paddingRight: 0 }}
      />
      <QuizViewer
        ref={quizRef}
        event_id={event_id}
        questionIndex={current}
        onQuestionIndexChange={setCurrent}
        questions={questions}
        activity={event?.fetchLiveEventById.my_activity}
        my_score={event?.fetchLiveEventById.my_score ?? 0}
        refetch={refetch}
        onLoadingStateChange={(loading) => setLoading(loading)}
        finishButton={
          event?.fetchLiveEventById.my_activity?.quiz_completed ? (
            <Button
              type="primary"
              onClick={() => navigate(`/calendar?event=${event_id}`)}
            >
              <Trans id="event.quiz_viewer.action.back_to_calendar">
                Terug naar kalender
              </Trans>
            </Button>
          ) : (
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                quizRef.current?.submit()
              }}
            >
              <Trans id="unit.viewer.quiz.submit">Antwoord indienen</Trans>
            </Button>
          )
        }
      />
    </>
  )
}

export type QuizViewerRef = {
  submit: () => void
}

type QuizViewerProps = {
  event_id: string
  questions: QuizQuestion[]
  activity: LiveEventQuizQuery['fetchLiveEventById']['my_activity']
  my_score?: number
  refetch: (() => Promise<ApolloQueryResult<unknown>>) | undefined
  questionIndex: number
  onQuestionIndexChange: (index: number) => void
  onLoadingStateChange?: (loading: boolean) => void
  finishButton?: React.ReactNode
}

const QuizViewer = forwardRef<QuizViewerRef, QuizViewerProps>(
  (
    {
      event_id,
      questions,
      activity,
      my_score,
      refetch,
      onLoadingStateChange,
      questionIndex,
      onQuestionIndexChange,
      finishButton,
    }: QuizViewerProps,
    ref
  ) => {
    const question = questions[questionIndex]

    const [form] = useForm()

    const goToNextQuestion = () => {
      onQuestionIndexChange(questionIndex + 1)
    }

    useImperativeHandle(ref, () => ({
      submit: form.submit,
    }))

    if (activity?.quiz_completed)
      return (
        <article className="quiz-unit">
          <p style={{ textAlign: 'center' }}>
            <Trans id="unit.viewer.video_quiz_results.description">
              Je diende deze toets in en behaalde een score van{' '}
              <strong>{Math.round((my_score ?? 0) * 100)}%</strong>. Hieronder
              een overzicht van je antwoorden.
            </Trans>
          </p>
          <div className="review-results" style={{ padding: '1.5rem' }}>
            <Space direction="vertical" size="large">
              {questions.map((question) => {
                const quiz_answer = activity?.quiz_answers?.find((answer) =>
                  new ObjectId(answer.question_id).equals(question._id)
                )
                if (!quiz_answer) return

                return (
                  <div key={'question:' + question._id}>
                    <h3 style={{ marginBottom: 24 }}>{question.question}</h3>
                    <p style={{ marginBottom: 16 }}>
                      <strong>
                        <Trans id="unit.viewer.quiz_results.your_answer">
                          Jouw antwoord
                        </Trans>
                        :
                      </strong>{' '}
                      {quiz_answer.user_answers
                        .map((answer) => {
                          const selected_answer = question.answers.find(
                            (a) => a.answer === answer
                          )
                          return (
                            selected_answer?.translation?.answer ??
                            selected_answer?.answer
                          )
                        })
                        .join(', ')}
                      {quiz_answer.correct ? (
                        <span> ✅</span>
                      ) : (
                        <span> ❌</span>
                      )}
                    </p>
                    {!quiz_answer.correct && (
                      <p>
                        <strong>
                          <Trans id="unit.viewer.quiz_results.correct_answer">
                            Correct antwoord
                          </Trans>
                          :
                        </strong>{' '}
                        {quiz_answer.correct_answers
                          .map((answer) => {
                            const correct_answer = question.answers.find(
                              (a) => a.answer === answer
                            )
                            return (
                              correct_answer?.translation?.answer ??
                              correct_answer?.answer
                            )
                          })
                          .join(', ')}
                      </p>
                    )}
                    {'explanation' in question && question.explanation && (
                      <p>
                        <strong>
                          <Trans id="unit.viewer.quiz_results.explanation">
                            Uitleg
                          </Trans>
                          :
                        </strong>{' '}
                        <span>{question.explanation}</span>
                      </p>
                    )}
                  </div>
                )
              })}
              {finishButton}
            </Space>
          </div>
        </article>
      )

    const handleFinish = async (result) => {
      onLoadingStateChange?.(true)
      try {
        const given_answer = questions
          .map((question, i) => {
            const user_answer = result[`userAnswers[${i}]`]
            return {
              event_id: event_id,
              quiz_progress: {
                question_id: question._id,
                user_answers: Array.isArray(user_answer)
                  ? user_answer
                  : user_answer
                  ? [user_answer]
                  : null,
              },
            }
          })
          .find((i) => i.quiz_progress.user_answers)

        await logEventActivity({ ...given_answer })

        if (questionIndex === questions.length - 1) {
          await logEventActivity({ event_id, completed: true })
          await refetch?.()
        }
        goToNextQuestion()
      } catch (error) {
        if (error instanceof Error) {
          errorNotifierFn(error)
        }
      } finally {
        onLoadingStateChange?.(false)
      }
    }

    return (
      <article className="quiz-unit">
        {questionIndex < questions.length && (
          <div className="steps-content" style={{ padding: '1.5rem' }}>
            <Question>{question.question}</Question>
            <Form form={form} onFinish={handleFinish}>
              {question.__typename === 'QuizQuestion' && (
                <Form.Item
                  name={`userAnswers[${questionIndex}]`}
                  required
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'unit.viewer.video.quiz.validation.answer',
                        message: 'Gelieve een antwoord te selecteren',
                      }),
                    },
                  ]}
                >
                  {question.multiple_answers ? (
                    <Checkbox.Group style={{ textAlign: 'left', fontSize: 18 }}>
                      <Space direction="vertical">
                        {question.answers.map((answer) => (
                          <Checkbox
                            key={questionIndex + answer.answer}
                            value={answer.answer}
                          >
                            {answer.translation?.answer ?? answer.answer}
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  ) : (
                    <Radio.Group style={{ textAlign: 'left', fontSize: 18 }}>
                      <Space direction="vertical">
                        {question.answers.map((answer) => (
                          <Radio
                            key={questionIndex + answer.answer}
                            value={answer.answer}
                          >
                            {answer.translation?.answer ?? answer.answer}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  )}
                </Form.Item>
              )}

              <div
                className="steps-action"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {finishButton}
              </div>
            </Form>
          </div>
        )}
      </article>
    )
  }
)
